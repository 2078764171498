import React, { useState, useEffect, useContext } from 'react';

// contexto
import { AssociarContexto } from './../AssociarContexto';

// componentes
import { Card, Row, Col, Form, Badge, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';

// componente
export default function FormularioPix(){

    // estados
    const { dados, alterarDados, dadosCartao, alterarDadosCartao, alterarEtapa, dadosComprador, alterarDadosComprador, erros, errosPagamento } = useContext(AssociarContexto);

    let telefoneRefInput = React.createRef();
    let cpfInputRef = React.createRef();

    return <>

        <div>
            <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control 
                    value={dadosComprador.nome}
                    onChange={e => {
                        dadosComprador.nome = e.target.value;
                        alterarDadosComprador({...dadosComprador});
                    }}
                    isInvalid={errosPagamento.nome ? true : false}
                />
                { errosPagamento.nome &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.nome}</Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <Form.Label title='Telefone'>Telefone</Form.Label>
                <MaskedInput 
                    className={[
                        "form-control",
                        (errosPagamento.telefone) ? 'is-invalid' : ''
                    ].join(' ')}
                    mask="(11) 11111-1111"
                    value={dadosComprador.telefone}
                    ref={telefoneRefInput}
                    onChange={(e) => {
                        alterarDadosComprador({...dadosComprador, telefone: telefoneRefInput.current.mask.getRawValue().split('_').join('')});
                    }}
                />
                { errosPagamento.telefone &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.telefone}</Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <Form.Label>CPF*</Form.Label>
                <MaskedInput
                    className={[
                        "form-control",
                        (errosPagamento.cpfCnpjCartao) ? 'is-invalid' : ''
                    ].join(' ')}
                    mask="111.111.111-11"
                    ref={cpfInputRef}
                    value={dadosComprador.cpfCnpj}
                    onChange={(e) => {
                        dadosComprador.cpfCnpj = cpfInputRef.current.mask.getRawValue().split('_').join('');
                        alterarDadosCartao({...dadosComprador});
                    }}
                    
                />
                { errosPagamento.cpfCnpjCartao &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.cpfCnpjCartao}</Form.Control.Feedback>
                }

            </Form.Group>
        </div>
        <p className={'d-flex justify-content-between mt-3'}>
            <Button
                variant={'primary'}
                onClick={() => {
                    alterarEtapa('metodo');
                }}
            >
                Voltar
            </Button>
            <Button
                variant={'success'}
                onClick={() => {
                    alterarEtapa('finalizar')
                }}
            >
                Finalizar
            </Button>
        </p>
    
    </>

}