import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Toast, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {Link, useParams, Redirect, BrowserRouter as Router, useHistory} from 'react-router-dom';
import logo from './../../assets/midiaLocalLogo.png';
import logoIcone from './../../icones/svg/logo2.svg'
import './RedefinirSenha.scss';
import Carregador from './../Componentes/Carregador';

// componentes
import {MudarSenhaComponent} from './components';

export default function RedefinirSenha(){

    //pega o token pela URL
    const { token } = useParams();

    let history = useHistory();

    async function novaSenha() {
        alterarCarregando(true);

        //dados para o backend
        const dadosSenha = {
            'token': token,
            'senha': senha,
            'confirmarSenha': confirmarSenha
        }

        try {

            //enviar informacoes ao backend
            await axios.post(`/redefinirSenha`, { dados: dadosSenha });

            //chamar o modal
            alterarModalSenhaMudada(true);

        //se der erro    
        } catch({response}){
            if(response){
                if(response.status === 400){
                    let dados = response.data;

                    //pegar os valores dos objetos, os erros do backend
                    let arrayChaves = Object.values(dados);

                    //passar por cada valor e fazer um toast com ele
                    arrayChaves.forEach((mensagem, indice) => {
                       setTimeout(() => {
                            toast(({ closeToast }) => <>
                                <div className="toast-header bg-danger text-white">
                                <strong className="mr-auto">Opa! Um erro aconteceu</strong>
                                <button 
                                    onClick={closeToast} 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div className="toast-body  bg-danger text-white">
                                    {mensagem}
                                </div>
                                </>
                            )     
                       }, 500 + (indice / 2 * 350))
                    });
                }
            }
        } finally {
            alterarCarregando(false);
        }
    }

    //modal redirecionando o usuario para a tela de login
    function montarModalSenhaRedefinida(){
        return <> 
            <Modal 
                show={modalSenhaMudada} 
                onHide={() => {
                    history.push('/login');
                }}
                size="md"
                className={['modal-personalizado'].join(' ')}
                aria-labelledby="contained-modal-title-center"
                centered
                backdrop={true}    
            >
                <Modal.Header closeButton>
                    <Modal.Title>Senha alterada com sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sua senha foi redefinida, agora você pode logar com ela!
                </Modal.Body>
                <Modal.Footer>

                    <Link to="/login">
                        <Button variant="primary">
                            OK
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        
        </>
    }

    const [senha, alterarSenha] = useState(null);
    const [confirmarSenha, alterarConfirmarSenha] = useState(null);
    const [mostraAlertaSenha, alterarMostraAlertaSenha] = useState(false);
    const [mostraAlertaConfirmacao, alterarMostraAlertaConfirmacao] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [modalSenhaMudada, alterarModalSenhaMudada] = useState(false);

    return <MudarSenhaComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
        >
            <div md={3} className="logo">
                <img src={logoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                                <h3 className="login-heading mb-4">Redefinir Senha</h3>
                                    <div className="form-label-group">
                                        <input 
                                            type="password" 
                                            id="inputSenha"
                                            className={["form-control"].join(' ')} 
                                            placeholder="Digite sua nova senha" 
                                            required autoFocus
                                            value={senha || ''}
                                            // pegar o valor digitado pelo usuario
                                            onChange={(e) => {
                                                alterarSenha(e.target.value)
                                            }}
                                            onKeyDown={e => {
                                            // quando clicar no enter verifica se a senha esta nula ou vazia
                                            // se estiver mostra o alerta, se nao chama a funcao de mandar pro backend    
                                            if(e.keyCode === 13) 
                                                senha == null || senha == '' ? alterarMostraAlertaSenha(true)  : novaSenha()
                                            }}
                                        />
                                        <label htmlFor="inputSenha">Digite sua nova senha</label>
                                        {/* Alerta da senha */}
                                        {mostraAlertaSenha == true && 
                                        <Alert className="alerta-erro" onClose={()=> alterarMostraAlertaSenha(false)} dismissible variant="danger"><p>Insira uma senha</p></Alert>
                                        }
                                    </div>
                                    <div className="form-label-group">
                                        <input 
                                            type="password" 
                                            id="inputConfirmarSenha"
                                            className={["form-control"].join(' ')} 
                                            placeholder="Digite a senha novamente para confirmá-la" 
                                            required autoFocus
                                            value={confirmarSenha || ''}
                                            // pegar o valor digitado pelo usuario
                                            onChange={(e) => {
                                                alterarConfirmarSenha(e.target.value)
                                            }}
                                            onKeyDown={e => {
                                            // quando clicar no enter verifica se a senha esta nula ou vazia
                                            // se estiver mostra o alerta, se nao chama a funcao de mandar pro backend    
                                            if(e.keyCode === 13) 
                                                confirmarSenha == null || confirmarSenha == '' ? alterarMostraAlertaConfirmacao(true)  : novaSenha()
                                            }}
                                        />
                                        <label htmlFor="inputConfirmarSenha">Digite a senha novamente</label>
                                        {/* Alerta de confirmacao da senha */}
                                        {mostraAlertaConfirmacao == true && 
                                        <Alert className="alerta-erro" onClose={()=> alterarMostraAlertaConfirmacao(false)} dismissible variant="danger"><p>A confirmação de senha não confere</p></Alert>
                                        }
                                    </div>

                                    <div className="d-flex align-items-center justify-content-around px-1">
                                    
                                        <Link to="/login" className={['col-5 p-0'].join('')}>
                                            <Button
                                                type="button"
                                                className={['rounded-pill botao-associado'].join(' ')}
                                            >
                                                <span>Cancelar</span>
                                            </Button>
                                        </Link>
                                        <Button
                                            type="submit"
                                            className={['rounded-pill botao-cadastrar col-5'].join(' ')}
                                            onClick={() => {
                                                //da o alerta quando a senha estiver nula ou vazia
                                                alterarMostraAlertaSenha(senha == null || senha == '');

                                                //da o alerta quando a confirmacao de senha estiver nula ou vazia
                                                alterarMostraAlertaConfirmacao(confirmarSenha == null || confirmarSenha == '');

                                                //chama a funcao de enviar os dados ao back
                                                novaSenha();
                                            }}
                                        >
                                            <span>Confirmar</span>
                                        </Button>
                                    </div>
                                    {/* Funcao de carregamento, quando estiver true, ela ativa */}
                                    {carregando && 
                                         <Carregador className="bg-transparent text-dark" />
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="desenvolvido" ><p>Desenvolvido por</p> <img src={logo} /></div>

        {/* se modalSenhaMudada for true, chama o modal */}
        {modalSenhaMudada &&  montarModalSenhaRedefinida()}   

    </MudarSenhaComponent>

}

