import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card } from 'react-bootstrap';


// comopnente
export default function ConfiguracaoLivre(){

    return <>
        <Card
            className='border-0 shadow-sm mb-4'
        >
            <Card.Body>
                <p className={styles.cabecalho}>
                    Configurações básicas
                </p>
                
                {/* <OpcaoBoleana 
                    descricao={`Permite alterar as permissões avançadas do usuário`}
                    codigo={'permitirAlterarConfiguracaoUsuario'}
                /> */}

                {/* <ValorAssociar /> */}
                <p className="text-center">Olá</p>
            </Card.Body>
        </Card>
    </>
}