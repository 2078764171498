import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

// importa reducers e actions
import LoginReducer from './Sistema/Login/LoginReducer';
import ConfiguracaoReducer from './Sistema/Painel/Configuracao/ConfiguracaoReducer';

// unifica reducers
let rootReducer  = combineReducers({
    loginReducer: LoginReducer,
    configuracaoReducer: ConfiguracaoReducer
});

// retorna reducer global
export default withReduxStateSync(rootReducer);