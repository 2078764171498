import styled, { css } from "styled-components";

export const CarteiraComponent = styled.div.attrs({
	className: ""
})`
	position: relative;
	flex-grow: 1;
    padding: 20px;
    background: white;
    border-radius: 5px;
	/* margin: auto; */
	
	label{
		font-size: 0.8em;
		color: #595959;
    	margin: 0;
	}

	.foto{
		display: flex;
		align-items: center;
		justify-content: center;

		/* desktop */
		position: absolute;
		top: 0;
		right: 0;
		margin: 10px 15px;
    	margin-top: -1rem;
		margin-right: -1rem;

		/* mobile */
		@media only screen and (max-width : 600px) {
			position: relative;
			margin: 0;
		}

		${
			(props) => props.leitura && `
				position: relative;
				margin: 0;
			`
		}

		img{
			height: 110px;
			width: 110px;
			object-fit: cover;
			box-shadow: 0 0 2px grey;
			border-radius: 50%;
		}

		svg{
			height: 80px;
    		width: 80px;
			box-shadow: 0 0 2px grey;
			border-radius: 50%;
		}
	}
`;
