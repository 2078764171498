import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Card, Row, Col, Form, Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import { intersectObject, converterReal } from './../../../Funcoes';
import InputDataCombo from './../../../Componentes/InputDataCombo';
import Arquivos from './Arquivos';

import moment from 'moment';

// retorna conteudo
export default function ModalCadastroCurso({
    aoFechar=null,
    aoSalvar=null
}){
    
    let { path, url } = useRouteMatch();
    const { loginReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    const {id} = useParams(); // parametro url

    const [erros, alterarErros] = useState({});
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const dadosPadrao = {
        id: id || null,
        titulo: null,
        dataExpedicao: null
    };
    const [dadosIniciais, alterarDadosIniciais] = useState(Object.assign({}, dadosPadrao));
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [mostrar, alterarMostrar] = useState(true);
    const [arquivos, alterarArquivos] = useState([]);
    const [arquivosUpload, alterarArquivosUpload] = useState([]); // [{arquivo: files[0], progresso: 0, erro: null, dados: null}]
    const [arquivosRemover, alterarArquivosRemover] = useState([]);

    // se for aberto na visualização, é somente leitura 
    const [leitura, alterarLeitura] = useState(path === `/painel/cursos/visualizar/:id` ? true : false); 

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(id){
            alterarModo('edicao');
            consultarId();
        }

    }, [id]);

    // consulta os dados do cliente
    async function consultarId(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/cursos/${dados.id}`);
            
            alterarDadosIniciais({...data.dados});
            alterarDados({...data.dados});
            alterarArquivos(data.arquivos);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            alterarCarregando(false);
        }

    }

    // salva edição
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/cursos`, {
                dados: intersectObject(dadosPadrao, dados),
                arquivosRemover: arquivosRemover
            });
            alterarModo('edicao');

            // faz upload dos arquivos
            if(arquivosUpload.length > 0){
                await salvarArquivos(data.id);
            }

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Curso salvo!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Dados do curso foram salvos.
                </div>
            </>);

            // executa callback
            if(aoSalvar){
                aoSalvar();
            }else{
                alterarMostrar(false);
            }

        }catch({response}){

            // erro
            if(response.status === 400){
                alterarErros(response.data);
            }

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
            
        }
    }

    // salva arquivos
    async function salvarArquivos(idCurso){

        // registra cada upload e não retorna até que todos finalizem
        let uploads = [];

        // inicia o upload de cada arquivo
        arquivosUpload.filter(arquivo => arquivo.progresso !== 100).forEach(async arquivo => {

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('imagem', arquivo.arquivo);
            formData.append('idCurso', idCurso);

            // verifica o progresso da imagem do card
            function progressoArquivo(progressEvent){
                arquivo.progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                alterarArquivosUpload([...arquivosUpload]);
            }

            // ajusta dados
            let requisicao = axios.post(`/cursos/arquivos`, formData, {
                onUploadProgress: progressoArquivo,
                headers: {'Content-type': 'multipart/form-data'},
            })
            .then(({data}) => {
                arquivo.dados = data;
                alterarArquivosUpload([...arquivosUpload]);
            })
            .catch(({response}) => {
                if(response){
                    arquivo.erro = true;
                    alterarArquivosUpload([...arquivosUpload]);

                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Não Conseguimos salvar seu arquivo :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Não foi possível salvar o arquivo <b>{arquivo.arquivo.name}</b>!
                            </div>
                        </div>
                    </>);
                }
            })

            // incrementa
            uploads.push(requisicao);

        });

        // aguarda finalizar todos
        await Promise.all(uploads);

        // finalizado
        return true;
    }

    return <>
        <Modal
            className={['modal-personalizado'].join(' ')}
            // {...propriedades}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrar}
            onHide={() => {
                alterarMostrar(false);
            }}
            onExited={() => {
                if(aoFechar){
                    aoFechar();
                }else{
                    history.push(`/painel/cursos`, {refresh: true});
                }
            }}

            backdrop={!leitura ? 'static' : true}

        >
            <Modal.Header 
                closeButton 
                onHide={() => {
                    alterarMostrar(false);
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                    {dadosIniciais.titulo || 'Adicionar novo curso'}
                </Modal.Title>
                {
                    (leitura && dados.idUsuario === loginReducer.dadosUsuario.id) &&
                    <Button 
                        variant="light" 
                        onClick={() => {

                            alterarLeitura(false);

                            // vai para a edição
                            history.push(`/painel/cursos/editar/${dados.id}`);

                        }}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} style={{marginRight: '5px'}}/>
                        Editar
                    </Button>
                }
            </Modal.Header>
            <Modal.Body>
                {
                    carregando ?
                    <Col align="center">
                        <Spinner animation="border" />
                    </Col>
                    :
                    <>
                        <Form.Group className={['formulario'].join(' ')}>
                            <Form.Row>
                                <Col sm={12} md={8} className={'mb-2'}>
                                    {/* <Form.Label>Título</Form.Label> */}
                                    <Form.Control 
                                        readOnly={leitura}
                                        className={[styles.input].join(' ')}
                                        placeholder={"Título"}
                                        value={dados.titulo || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, titulo: e.target.value});
                                        }}
                                    />
                                    {(erros.titulo) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.titulo}</p></Alert>
                                    }
                                </Col>
                                <Col className={'mb-2'}>
                                    {/* <Form.Label>Expedido em</Form.Label> */}
                                    <InputDataCombo
                                        disabled={leitura}
                                        className={[styles.input].join(' ')}
                                        data={dados.dataExpedicao}
                                        onChange={(data) => {
                                            alterarDados({...dados, dataExpedicao: data});
                                        }}
                                        placeholder={`Expedido em`}
                                    />
                                    {(erros.dataExpedicao) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.dataExpedicao}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Arquivos
                            leitura={leitura}
                            arquivosRemover={arquivosRemover}
                            arquivosUpload={arquivosUpload}
                            arquivos={arquivos}
                            incluiuArquivos={novosArquivos => {
                                let novosArquivosArray = [];
                                Array.from(novosArquivos).forEach(arquivo => {
                                    console.log(arquivo);
                                    novosArquivosArray.push({arquivo, progresso: 0, erro: false});
                                });
                                alterarArquivosUpload([...arquivosUpload, ...novosArquivosArray]);
                            }}
                        />
                    </>
                }
            </Modal.Body>

            { !leitura &&
                <Modal.Footer>
                    <SalvarFormulario 
                        salvando={salvando}
                        modo={modo}
                        aoFechar={() => {
                            alterarMostrar(false);
                        }}
                        onClick={() => salvar(dados)}
                    />
                </Modal.Footer>
            }

            { (leitura && isMobile) &&
                <Modal.Footer>
                    <Button 
                        variant="danger" 
                        onClick={() => { alterarMostrar(false); }}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            }

        </Modal>
    </>
}

function SalvarFormulario({
    salvando,
    aoFechar,
    modo,
    onClick
}){
    return <Form.Group 
        className={[styles.linhaSalvar, 'd-flex justify-content-around my-2'].join(' ')}
    >
        <Button 
            variant="danger" 
            onClick={aoFechar}
        >
            { modo === 'edicao' ? 'Fechar' : 'Cancelar'}
        </Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}