import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck, FormLabel, Badge, Modal, ModalBody } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros, 
    mostrarFiltros,
    alterarMostrarFiltros
}){

    return <div className="parametros">
        <div className="parametros-inicial" style={{
            alignItems: 'unset'
        }}>
            {/* <Link to='/painel/movimentos/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    { isBrowser && <span>Novo</span>}
                </Button>
            </Link> */}
            <div className={[styles.campoPesquisa, 'd-flex flex-grow-1 align-items-center'].join(' ')}>
                <div className={[styles.icone].join(' ')}>
                    <FontAwesomeIcon icon={["fas", "search"]} />
                </div>
                <DebounceInput 
                    placeholder="Nome, CPF, e-mail, data de nascimento"
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.texto}
                    onChange={(e) =>{
                        alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                    }}
                />
            </div>
            <Button 
                style={{
                    fontSize: 12
                }}
                variant="padrao"
                onClick={(e) => {
                    alterarMostrarFiltros(!mostrarFiltros)
                }}
            >
                <FontAwesomeIcon className="icone" icon={mostrarFiltros ? faSearchMinus : faSearchPlus} />
            </Button>
        </div>

        {mostrarFiltros && 
            <Row className='mt-3'>
                <Col sm='12' md='8'>
                    <Form.Row>
                        <Col>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label className={'mb-0'}>Vencimento</Form.Label>
                                {/* <Row> */}
                                    <Col className="pr-1">
                                        <InputDataCombo 
                                            placeholder="A partir de"
                                            data={parametros.dataVencimentoInicio}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataVencimentoInicio: novaData, pagina: 1})
                                            }}
                                        />
                                    </Col>
                                    <Col className="px-1">
                                        <InputDataCombo 
                                            placeholder="Até"
                                            data={parametros.dataVencimentoFim}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataVencimentoFim: novaData, pagina: 1})
                                            }}
                                        />
                                    </Col>
                                {/* </Row> */}
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
                <Col md='4'>
                    <Form.Row className="my-2">
                        <Col md="5">
                            <Form.Label>Ativo</Form.Label>
                        </Col>
                        <Col>
                            <Form.Check
                                type="switch"
                                id="switch-usuario-ativo-sim"
                                label="sim"
                                checked={(parametros.cadastroAtivo.includes('S'))}
                                onChange={e => {
                                    alterarParametros({...parametros, cadastroAtivo: (e.target.checked) ? [...parametros.cadastroAtivo, 'S'] : parametros.cadastroAtivo.filter((e) => e !== 'S'), pagina: 1});
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type="switch"
                                id="switch-usuario-ativo-nao"
                                label="não"
                                checked={(parametros.cadastroAtivo.includes('N'))}
                                onChange={e => {
                                    alterarParametros({...parametros, cadastroAtivo: (e.target.checked) ? [...parametros.cadastroAtivo, 'N'] : parametros.cadastroAtivo.filter((e) => e !== 'N'), pagina: 1});
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Col>
            </Row>
        }
    </div>
}