import styled, { css } from "styled-components";

export const ConsultarQrCodeComponent = styled.div.attrs({
	className: ""
})`
    align-self: center;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;

    ${(props) => !props.status && `
        section{
            display: none;
        }
    `}

    .icone{
        --cor: ${(props) => props.status ? 'green' : '#f70404'};
        color: var(--cor);
        border-radius: 50%;
        padding: 1rem;
        background: ${(props) => props.status ? '#d4eada' : '#f9d4d4'};
        border: 3px solid var(--cor);
        font-size: 3.8em;

        ${(props) => !props.status && `
                padding: 0.4rem;
                width: 0.7em !important;
                height: 0.7em !important;
            `
        }
    }

    .container-qr{
        width: 100%;
        padding-top: 100%;
        position: relative;
        transition: ease 0.1s;

        ${(props) => !props.status && `
            border: 3px solid #f70404;

            p{
                color: #f70404;
            }
        `}

        p{
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &:hover{
            .container-refazer-consulta{
                display: block;
            }
        }
    }
    
    .info{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 10px;
        margin: 0;
        font-size: 16px;

        &.refazer-consulta{
            font-size: 7rem;
            background: #00000082;
            color: #afafaf9e;
            background: #0000002b;
            color: #6f6f6f9e;
        }
    }

    .container-refazer-consulta{
        display: none;
    }


    .footer{
        button{
            font-size: 0.8em;
        }
    }
`;
