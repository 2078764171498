import * as React from "react";

const SvgQrCodeIcone = (props) => {
  return (
    <svg
      id="qr-code-icone_svg__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".qr-code-icone_svg__st2{fill-rule:evenodd;clip-rule:evenodd}"}
      </style>
      <switch>
        <g>
          <path d="M53.5 58h-24c-1.9 0-3.5-1.6-3.5-3.5v-24c0-1.9 1.6-3.5 3.5-3.5h24c1.9 0 3.5 1.6 3.5 3.5v24c0 1.9-1.6 3.5-3.5 3.5zm-24-28c-.3 0-.5.2-.5.5v24c0 .3.2.5.5.5h24c.3 0 .5-.2.5-.5v-24c0-.3-.2-.5-.5-.5h-24zM53.5 100h-24c-1.9 0-3.5-1.6-3.5-3.5v-24c0-1.9 1.6-3.5 3.5-3.5h24c1.9 0 3.5 1.6 3.5 3.5v24c0 1.9-1.6 3.5-3.5 3.5zm-24-28c-.3 0-.5.2-.5.5v24c0 .3.2.5.5.5h24c.3 0 .5-.2.5-.5v-24c0-.3-.2-.5-.5-.5h-24zM42 119H16c-1.9 0-3.5-1.6-3.5-3.5v-26c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v26c0 .3.2.5.5.5h26c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM112 119H86c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h26c.3 0 .5-.2.5-.5v-26c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v26c0 1.9-1.6 3.5-3.5 3.5zM114 40c-.8 0-1.5-.7-1.5-1.5v-26c0-.3-.2-.5-.5-.5H86c-.8 0-1.5-.7-1.5-1.5S85.2 9 86 9h26c1.9 0 3.5 1.6 3.5 3.5v26c0 .8-.7 1.5-1.5 1.5z" />
          <path d="M14 40c-.8 0-1.5-.7-1.5-1.5v-26c0-1.9 1.6-3.5 3.5-3.5h26c.8 0 1.5.7 1.5 1.5S42.8 12 42 12H16c-.3 0-.5.2-.5.5v26c0 .8-.7 1.5-1.5 1.5zM98.5 58h-24c-1.9 0-3.5-1.6-3.5-3.5v-24c0-1.9 1.6-3.5 3.5-3.5h24c1.9 0 3.5 1.6 3.5 3.5v24c0 1.9-1.6 3.5-3.5 3.5zm-24-28c-.3 0-.5.2-.5.5v24c0 .3.2.5.5.5h24c.3 0 .5-.2.5-.5v-24c0-.3-.2-.5-.5-.5h-24z" />
          <circle
            className="qr-code-icone_svg__st2"
            cx={41.5}
            cy={42.5}
            r={2}
          />
          <circle
            className="qr-code-icone_svg__st2"
            cx={86.5}
            cy={42.5}
            r={2}
          />
          <circle
            className="qr-code-icone_svg__st2"
            cx={41.5}
            cy={84.5}
            r={2}
          />
          <g>
            <path d="M100 101H87c-1.4 0-2.5-1.1-2.5-2.5v-13c0-1.4 1.1-2.5 2.5-2.5h13c1.4 0 2.5 1.1 2.5 2.5v13c0 1.4-1.1 2.5-2.5 2.5zm-12.5-3h12V86h-12v12z" />
          </g>
          <g>
            <path d="M85 86H73c-1.4 0-2.5-1.1-2.5-2.5v-12c0-1.4 1.1-2.5 2.5-2.5h12c1.4 0 2.5 1.1 2.5 2.5v12c0 1.4-1.1 2.5-2.5 2.5zm-11.5-3h11V72h-11v11z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default SvgQrCodeIcone;
