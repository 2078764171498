import React, { useState, useEffect } from 'react';

// components
import { Button } from 'react-bootstrap';

function Tags({
    tipo='1'
}){

    let [tagsDisponiveis, alterarTagsDisponiveis] = useState([]);
    let tagsClientes = [
        {descricao: 'Nome', id: '[CL_NOME]'},
        {descricao: 'Primeiro nome', id: '[CL_PRIMEIRO_NOME]'},
        {descricao: 'Profissão', id: '[CL_PROFISSAO]'},
        {descricao: 'CPF', id: '[CL_CPF]'},
        {descricao: 'RG', id: '[CL_RG]'},
        {descricao: 'Data de Nascimento', id: '[CL_DATA_NASCIMENTO]'},
        {descricao: 'Telefone', id: '[CL_TELEFONE]'},
        {descricao: 'Telefone Comercial', id: '[CL_TELEFONE_COMERCIAL]'},
        {descricao: 'Telefone Celular', id: '[CL_CELULAR]'},
        {descricao: 'Email', id: '[CL_EMAIL]'},
        {descricao: 'CEP', id: '[CL_CEP]'},
        {descricao: 'Cidade', id: '[CL_CIDADE]'},
        {descricao: 'Estado', id: '[CL_ESTADO]'},
        {descricao: 'Endereço', id: '[CL_ENDERECO]'}
    ];
    let tagsParcelaEmissao = [
        {descricao: 'Valor da parcela', id: '[PE_VALOR]'},
        {descricao: 'Valor dos juros da parcela', id: '[PE_VALOR_JUROS]'},
        {descricao: 'valor da parcela + juros', id: '[PE_VALOR_TOTAL]'},
        {descricao: 'Vencimento', id: '[PE_VENCIMENTO]'},
        {descricao: 'Razao Social Seguro', id: '[S_CL_NOME]'},
        {descricao: 'Nome Fantasia Seguro', id: '[S_CL_NOME_FANTASIA]'},
        {descricao: 'Forma de pagamento', id: '[FE_FORMA_PAGAMENTO]'},
        {descricao: 'Número da apólice', id: '[FE_NUMERO_APOLICE]'},
        {descricao: 'Data emitido', id: '[FE_DATA_EMITIDO]'},
    ];

    return <>
        {(['1', '2'].includes(tipo) && 
            <>
                {tagsClientes.map(opcao => 
                    <BotaoTag 
                        key={opcao.id} 
                        value={opcao.descricao}
                        onMouseDown={e => {
                            document.execCommand('insertText', false, opcao.id);
                        }}
                    />    
                )}
            </>
        )}

        {(['2'].includes(tipo) && 
            <>
                <hr />
                {tagsParcelaEmissao.map(opcao => 
                    <BotaoTag 
                        key={opcao.id} 
                        value={opcao.descricao}
                        onMouseDown={e => {
                            document.execCommand('insertText', false, opcao.id);
                        }}
                    />    
                )}
            </>
        )}

    </>

}

// componete de botao para tag
function BotaoTag({
    value,
    onMouseDown
}){

    return <Button
        variant='padrao'
        size='sm'
        className='mr-2 mb-2'
        style={{
            fontSize: '0.8rem',
            fontWeight: 'normal'
        }}
        onMouseDown={e => {
            e.preventDefault();
            onMouseDown(e);
        }}
    >
        {value}
    </Button>
}

export default Tags;