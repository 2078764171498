import styled from 'styled-components'

export const ContainerArquivo = styled.div.attrs({
    className: ``
})`

    .imagem {
        height: 60px;
        width: 100px;
        object-fit: contain;
    }

    &.is-mobile {
        /* display: flex;
        align-items: center; */


        .conteudo-arquivo {
            display: flex;

            .area-imagem {
                margin-right: 2px;
                
                .imagem {
                    height: 40px;
                    width: 40px;
                }
            }

            .area-texto {
                flex-grow: 1;
                width: 100%;
                min-width: 0;

                .opcoes {
                }
            }

        }
    }

    .descricao {
        font-size: 0.7rem;
    }

    .opcoes {
        
        button {
            font-size: 0.75rem;

            span {
                font-size: 0.75rem;
            }
        }
    }


`;