import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import InputValorReal from './../../../../Componentes/InputValorReal';
import ComboModeloEmail from './../../../../Componentes/ComboModeloEmail';

// contexto
import { ConfiguracaoContexto } from './../../ConfiguracaoContexto';

// modelo de email para bem vindo
export default function ModeloEmailBemVindo(){


    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const { configuracoes, idUsuario } = useContext(ConfiguracaoContexto);
    const [salvando, alterarSalvando] = useState(false);
    const [valorNovo, alterarValorNovo] = useState(configuracoes['emailBemVindo']);


    // salva código de configuracao
    async function salvar(valorAlterado){

        // altera se foi modificado
        if(valorAlterado === valorNovo){
            return false;
        }

        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/configuracaoUsuarios/${idUsuario}/emailBemVindo`, {valor: valorAlterado});


        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    return <>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Cadastro concluído
            </p>
            <div
                className='ml-2 d-flex align-items-center w-50'
            >
                <div
                    className='mr-2 w-100'
                >

                    <ComboModeloEmail 
                        id={valorNovo}
                        alterou={idNovo => {
                            salvar(idNovo);
                        }}
                    />
                </div>

                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
    </>


}