import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { Image, Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

// styled components
import { ContainerArquivo } from './styles';

// icones
import docIcone from './icones/doc.png';

// componente
export default function Arquivos({
    leitura=false,
    arquivosRemover,
    arquivosUpload,
    arquivos,
    incluiuArquivos
}){
    
    // referencias
    let inputRef = React.createRef();


    let Arquivo = ({
        progresso=null,
        nome,
        erro=false,
        link=null
    }) => {
        return <ContainerArquivo
            className={[`p-2`, isMobile ? 'is-mobile w-50' : 'w-25'].join(' ')}
            isMobile={isMobile}
        >
            <div className={[`bg-light rounded conteudo-arquivo`, isMobile ? 'p-1' : 'p-2'].join(' ')} title={nome}>
                <div className='text-center area-imagem'>
                    { ['jpg'].includes(`teste.jpg`.split('.').pop()) ?
                        <Image className={`imagem`} src={docIcone} />
                        :
                        <Image className={`imagem`} src={docIcone} />
                    }
                    
                </div>
                <div className='area-texto'>
                    <div className='descricao'><div className='text-truncate'>{nome}</div></div>
                    {(progresso !== null) &&
                        <div className='mt-2'>
                            <ProgressBar 
                                variant={erro ? 'danger' : 'success'}
                                animated
                                now={progresso}
                                label={`${progresso}%`}
                            />
                        </div>
                    }
                    { link !== null &&
                        <div className='mt-1 d-flex opcoes'>
                            <Button
                                variant={'padrao'}
                                size='sm'
                                className={['p-0 flex-grow-1', leitura ? '' : 'mr-1'].join(' ')}
                            >
                                <FontAwesomeIcon className='icone' icon={faDownload} />
                                <span>Baixar</span>
                            </Button>
                            {!leitura &&
                                <Button
                                    variant={'padrao'}
                                    size='sm'
                                    className={'p-0 px-1'}
                                >
                                    <FontAwesomeIcon className='icone' icon={faTrash} />
                                </Button>
                            }
                        </div>
                    }
                </div>
            </div>
        </ContainerArquivo>
    }

    return <>
        <div
            className={'d-flex flex-wrap'}
        >
            
            { arquivos.map(arquivo => 
                <Arquivo 
                    key={arquivo.id}
                    nome={arquivo.nome}
                    link={arquivo.arquivo}
                />
            )}

            { arquivosUpload.map(arquivo => 
                <Arquivo 
                    key={arquivo.arquivo.name}
                    progresso={arquivo.dados ? null : arquivo.progresso}
                    nome={arquivo.arquivo.name}
                    erro={arquivo.erro}
                    link={arquivo.dados ? arquivo.dados.arquivo : null}
                />
            )}
            

        </div>
        {!leitura &&
            <div
                className='text-right'
            >
                <input 
                    hidden 
                    type="file" 
                    ref={inputRef} 
                    onChange={() => {
                        if(inputRef.current.files.length > 0){
                            incluiuArquivos(inputRef.current.files);
                        }
                    }} 
                    multiple
                />
                <Button
                    variant='padrao'
                    onClick={() => {
                        inputRef.current.click();
                    }}
                    size='sm'
                >
                    <span>Anexar arquivos</span>
                </Button>
            </div>
        }
    </>
}