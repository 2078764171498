import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Modal } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import ListaArquivos from "./../../../../Componentes/ListaArquivos";
import { useSwipeable } from "react-swipeable";
import { ContainerImagemComponente, BotaoAlterarImagem, OpcoesModalComponente, ImagemComponente } from './components';

// retorna conteudo
export default function PainelImagens({
    imagens,
    imagemAberta, 
    alterarImagemAberta,
    aoDeletarImagem,
    abrirImagem,
    editavel,
    mostrarProgresso
}){
   
    // cria referencia
    let ref = React.createRef();

    useEffect(() => {
        
        if(ref.current){
            // foca
            ref.current.focus();
        }

    }, [ref])

    const voltarImagem = () => {
        let anteriorImagem = (imagemAberta === 0) ? imagens.length - 1 : imagemAberta - 1;
        alterarImagemAberta(anteriorImagem)
    }

    const avancarImagem = () => {
        let proximaImagem = (imagens.length === (imagemAberta + 1)) ? 0 : imagemAberta + 1;
        alterarImagemAberta(proximaImagem)
    }

    // configuração para alterar a imagem do mobile (ao arrastar)
    const configSwipe = useSwipeable({
        onSwipedLeft: () => avancarImagem(),
        onSwipedRight: () => voltarImagem(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
      
    return <>
        <Modal 
            className={['modal-imagem', "modal-personalizado"].join(' ')}
            show={ imagemAberta !== null ? true : false } 
            onHide={ () => alterarImagemAberta(null) }
            centered
            size={'lg'}
        >
            {imagemAberta !== null &&
                <Modal.Body 
                    ref={ref}
                    className="" 
                    onKeyDown={(e) => {

                        // verifica se clicou em >
                        if(e.keyCode === 39){
                            // avançar
                            avancarImagem()
                        }

                        // verifica se clicou em <
                        if(e.keyCode === 37){
                            // voltar
                            voltarImagem()
                        }
                        
                    }}
                    tabIndex={-1}
                    style={{
                        outline: 'none'
                    }}
                >
                    <OpcoesModalComponente>
                        <span onClick={ () => alterarImagemAberta(null) }><FontAwesomeIcon icon={faTimes} fontSize={18} /></span>
                    </OpcoesModalComponente>
                    <ContainerImagemComponente {...(isMobile) && configSwipe}>
                        <BotaoAlterarImagem
                            hidden={isMobile || imagens.length === 1} 
                            className={['mr-1'].join(' ')} 
                            onClick={voltarImagem}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
                        </BotaoAlterarImagem>
                        <ImagemComponente>
                            <img
                                src={imagens[imagemAberta].preview} 
                            />
                        </ImagemComponente>
                        <BotaoAlterarImagem 
                            hidden={isMobile || imagens.length === 1} 
                            className={['ml-1'].join(' ')} 
                            onClick={avancarImagem}
                        >
                            <FontAwesomeIcon icon={faArrowRight} fontSize={18} />
                        </BotaoAlterarImagem>
                    </ContainerImagemComponente>
                    <Row>
                        <Col>
                            <ListaArquivos 
                                arquivos={imagens} 
                                aoDeletar={aoDeletarImagem} 
                                aoClicar={abrirImagem}
                                mostrarInfo={false}
                                mostrarProgresso={mostrarProgresso}
                                editavel={editavel}
                                arquivoSelecionado={imagens[imagemAberta].key}
                                styleImagens={{
                                    height: '80px',
                                    width: '80px',
                                }}
                                style={{
                                    inline: true
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            }
        </Modal>
    </>
}