import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Row, Col, Card } from 'react-bootstrap';
import { useSwipeable } from "react-swipeable";
import axios from 'axios';
import './PainelPagina.scss';

// componentes
import PainelSidebar from './PainelSidebar';
import PainelNavbar from './PainelNavbar';

import Inicio from './Inicio';
import Ficha from './Usuario/Ficha';
import Projetos from './Projetos';
import Usuario from './Usuario'
import Cursos from './Cursos';
import Carteira from './Carteira';
import CarteiraAssociar from './Carteira/Associar';
// import UsuarioEscreverEmail from './Usuario/EscreverEmail';
// import UsuarioEmailsMassa from './Usuario/EmailsMassa';
// import CaixaSaidaConsultar from './CaixaSaida/Consultar';
import ModelosEmailConsultar from './ModelosEmail/Consultar';
import ModelosEmailCadastrar from './ModelosEmail/Cadastrar';

// cria contexto
import { PainelContexto } from './PainelContexto';
import { MeusDadosIcone, QrCodeIcone, UsuariosIcone, ProjetosIcone, CursosIcone } from './../../icones/svg';
import Configuracao from './Configuracao';
import ConsultarQrCode from './ConsultarQrCode';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import background from './../Login/background.svg';

// painel base
export default function Painel(){

    // estados
    const { loginReducer, configuracaoReducer } = useSelector(state => state);

    const dispatch = useDispatch();
    let { path, url } = useRouteMatch();
    let [liberado, alterarLiberado] = useState(false);
    let [menuEncolhido, encolherMenu] = useState(isMobile ? true : false);

    // configuração para abrir/fechar menu ao arrastar
    const configSwipe = useSwipeable({
        onSwipedLeft: () => !menuEncolhido && encolherMenu(true),
        onSwipedRight: () => menuEncolhido && encolherMenu(false),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // inicializa
    useEffect(() => {
        
        // altera token de usuário para as requisições
        axios.defaults.headers.common['tokenusuario'] = loginReducer.dadosUsuario.token;

        // atualiza info usuário logado
        atualizarDadosusuario();

        // permissões
        consultarConfiguracoes();

    }, []);

    // mantém as informações do usuário atualizadas
    async function atualizarDadosusuario(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuario/${loginReducer.dadosUsuario.id}`);
            dispatch({
                type: `loginReducer/ATUALIZAR_DADOS`,
                dadosUsuario: data.dados,
                qrcode: data.qrcode
            });

        }catch({response}){

        }finally{

        }
    }


    // consultar permissões do usuário
    async function consultarConfiguracoes(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoUsuarios/${loginReducer.dadosUsuario.id}`);
            
            // altera a configuração global
            dispatch({
                type: 'configuracaoReducer/ATUALIZAR',
                dados: data
            });
        
            // libera
            alterarLiberado(true);

        }catch({response}){

        }
    }

    // ajustando axios
    if(!liberado){
        return <></>
    }

    return <PainelContexto.Provider value={{}}>


        <div 
            className={['painel-pagina', (menuEncolhido)? 'menu-encolher' : '', isMobile ? 'menu-mobile' : ''].join(' ')}
            {...(isMobile) && configSwipe}
            style={{
                background: `linear-gradient(rgb(255 255 255 / 15%),rgb(255 255 255 / 15%)), url(${background})`,
                backgroundSize: 'cover'
            }}
        >
            
            <PainelSidebar 
                logo={(menuEncolhido)? 'A' : 'ACEM'}
                className={(menuEncolhido)? 'encolher' : ''} 
                menuEncolhido={menuEncolhido}
                encolherMenu={() => encolherMenu(!menuEncolhido)} 
                links={[
                    {svg: UsuariosIcone, habilitar: configuracaoReducer.habilitarConsultaUsuarios === 'S', color: '#4287fd', descricao: 'Usuários', url: '/painel/usuarios'},
                    {svg: MeusDadosIcone, icone: ['fas', 'id-card'], color: '#029c02', descricao: 'Meus dados', url: '/painel/usuario'},
                    {svg: CursosIcone, icone: ['fas', 'file-medical-alt'], color: '#f55959', descricao: 'Cursos', url: '/painel/cursos'},
                    {svg: ProjetosIcone, icone: ['fas', 'trophy'], color: '#f4d042', descricao: 'Projetos', url: '/painel/projetos'},
                    {svg: QrCodeIcone, habilitar: configuracaoReducer.habilitarConsultaQrcode === 'S' , icone: ['fas', 'qrcode'], color: '#ef6206', descricao: 'Verificar código', url: '/painel/consultar'},
                    // {habilitar: configuracaoReducer.habilitarConsultaUsuarios === 'S', icone: faEnvelopeOpenText, color: '#d2d2d2', descricao: 'Caixa Saída', url: '/painel/caixa-saida'},
                    //Pedir um icone ao natan para a carteira
                    {icone: ['fas', 'wallet'], color: '#ccc', descricao: 'Carteira', url: '/painel/carteira'}
                ]}
            /> 
            <div className={["painel-pagina-corpo", isMobile ? 'painel-mobile' : ''].join(' ')}>

                <PainelNavbar 
                    menuEncolhido={menuEncolhido}
                    encolherMenu={encolherMenu}
                />
                <div className={["content", isMobile ? 'p-0 pt-2' : ''].join(' ')}>
                    <div className="container-fluid">

                        <Route exact path={`${path}/`}>
                            <Inicio />
                        </Route>

                        <Route exact path={[`/painel/usuario`]}>
                            <Ficha />
                        </Route>

                        {/* somente com permissão */}
                        { configuracaoReducer.habilitarConsultaUsuarios === 'S' &&
                            <>
                                <Route exact path={`/painel/usuarios`}>
                                    <Usuario />
                                </Route>
                                <Route exact path={[`/painel/usuario/:id`]}>
                                    <Ficha />
                                </Route>
                            </>
                        }

                        <Route exact path={`/painel/carteira`}>
                            <Carteira />
                        </Route>

                        <Route path={`/painel/projetos`}>
                            <Projetos />
                        </Route>

                        <Route path={`/painel/cursos`}>
                            <Cursos />
                        </Route>

                        { configuracaoReducer.habilitarConsultaQrcode === 'S' &&
                            <Route path={`/painel/consultar`}>
                                <ConsultarQrCode />
                            </Route>
                        }

                        {/* CONFIGURAÇÕES */}
                        <Route exact path={[`/painel/configuracao`]}>
                            <Configuracao />
                        </Route>
                        { configuracaoReducer.permitirAlterarConfiguracaoUsuario === 'S' &&
                            <Route exact path={[`/painel/configuracao/:id`]}>
                                <Configuracao />
                            </Route>
                        }

                        {/*  ASSOCIAR */}
                        <Route exact path={[`/painel/carteira/associar`]}>
                            <CarteiraAssociar />
                        </Route>

                        {/* <Route exact path={[`/painel/usuarios/enviar-email/:idUsuario`]}>
                            <UsuarioEscreverEmail />
                        </Route> */}
                        {/* <Route exact path={`/painel/usuarios/emails-massa`}>
                            <UsuarioEmailsMassa />
                        </Route> */}
                    
                        {/* EMAILS */}
                        {/* <Route exact path={`/painel/caixa-saida`}>
                            <CaixaSaidaConsultar />
                        </Route> */}
                        {/* <Route exact path={`/painel/usuarios/abrir-email/:id`}>
                            <UsuarioEscreverEmail />
                        </Route> */}

                        {/* MODELOS DE EMAIL */}
                        { configuracaoReducer.habilitarConsultaModelosEmail === 'S' &&
                            <>
                                <Route exact path={`/painel/modelos-email`}>
                                    <ModelosEmailConsultar />
                                </Route>
                                <Route exact path={[`/painel/modelos-email/cadastrar`, `/painel/modelos-email/alterar/:id`]}>
                                    <ModelosEmailCadastrar />
                                </Route>
                            </>
                        }

                    </div>
                </div>
            </div>

        </div>
    
    </PainelContexto.Provider>
}
