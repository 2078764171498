import React, { useState, useEffect, useContext } from 'react';
import './../LoginPagina.scss';
import { LoginComponent } from './../components';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Link, useHistory, useLocation } from 'react-router-dom';
import useIsMounted from 'ismounted';
import { Container } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';
import { intersectObject } from '../../Funcoes';
import backGroundImage from './../placeimg_640_480_tech.jpg';

// contextos
import { PainelLogarContexto } from './../PainelLogarContexto';

import LogoIcone from './../../../icones/svg/logo2.svg';

export default function AtivarLogin({
    
}){

    // estados
    let painelLogarContextoDados = useContext(PainelLogarContexto);
    let { dados, alterarDados, fazerLogin, erros, alterarErros } = painelLogarContextoDados;

    // estados
    let history = useHistory();
    let location = useLocation();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    let [carregando, alterarCarregando] = useState(false);
    const dadosPadrao = {
        id: null,
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: ''
    }
    const [dadosSalvos, alterarDadosSalvos] = useState(null);
    const [ativar, alterarAtivar] = useState(false);
    const [ativo, alterarAtivo] = useState(false);

    useEffect(() => {
        if(location.ativar){
            // envia direto para preencher
            clicouProsseguir()
        }
    }, [])

    // faz a consulta
    async function clicouProsseguir(){
        alterarCarregando(true);
        alterarErros({}); // limpa erros

        try{

            // faz a requisição para confirmar usuário para ativar
            let {data} = await axios.get('/usuario/ativar', {
                params: {
                    email: dados.email
                }
            })

            if (isMounted.current) {
                alterarCarregando(false);
            }

            // se encontrado
            if(data.dados){
                alterarDados({...intersectObject(dadosPadrao, data.dados), senha: null})
                alterarDadosSalvos(data.dados);

                // verifica se cadastro já está ativo
                if(data.dados.cadastroAtivo === 'S'){
                    alterarCarregando(true);
                    alterarAtivo(true);

                    // informa
                    alterarErros({
                        email: 'Conta ativa, redirecionando para o login.'
                    })

                    setTimeout(() => {
                        alterarErros({}); // limpa erros
                        history.push('/login')
                    }, 600);
                    
                }else{
                    alterarAtivar(true);
                }

            }else{
                alterarErros({
                    email: 'Não encontrado cadastro vinculado'
                })
            }

        }catch({response}){

            alterarErros(response.data);

            if (isMounted.current) {
                alterarCarregando(false);
            }
        }finally{
        }

    }

    // faz a ativação
    async function clicouAtivar(){
        alterarCarregando(true);

        try{

            // faz a requisição para confirmar usuário para ativar
            let {data} = await axios.post('/usuario', {
                dados: {...intersectObject(dadosPadrao, dados), cadastroAtivo: 'S'}
            })

            console.log({...data, loginAtivacao: true});
            fazerLogin({...data}, {loginAtivacao: true});

        }catch({response}){

            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // retorna componente
    return <LoginComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
            // style={{
            //     background: `url(${backGroundImage})`,
            //     backgroundSize: `cover`
            // }}
        >
            <div md={3} className="logo">
                <img src={LogoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Ativar pré-cadastro</h3>
                                {
                                    ativar &&
                                    <div className="form-label-group">
                                        <input 
                                            type="text" id="inputNome"
                                            className={["form-control", (erros.nome) ? 'input-erro' : ''].join(' ')} 
                                            placeholder="Nome" 
                                            required autoFocus
                                            value={dados.nome}
                                            onChange={(e) => {
                                                dados.nome = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                            onKeyDown={e => {
                                                if(e.keyCode === 13) !ativar ? clicouProsseguir(e) : clicouAtivar(e);
                                            }}
                                        />
                                        <label htmlFor="inputNome">Nome</label>
                                        { erros.nome && 
                                            <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                        }
                                        
                                    </div>
                                }

                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", (erros.email) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Endereço de email" 
                                        required autoFocus
                                        value={dados.email}
                                        onChange={(e) => {
                                            dados.email = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) !ativar ? clicouProsseguir(e) : clicouAtivar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Endereço de email</label>
                                    { erros.email && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                    
                                </div>

                                {
                                    ativar &&
                                    <>

                                        <div className="form-label-group">
                                            <input 
                                                id="inputConfirmPassword"
                                                className={["form-control", (erros.senha) ? 'input-erro' : ''].join(' ')}
                                                placeholder="Senha" required 
                                                value={dados.senha || ''}
                                                onChange={(e) => {
                                                    dados.senha = e.target.value;
                                                    alterarDados({...dados});
                                                }}
                                                onKeyDown={e => {
                                                    if(e.keyCode === 13) !ativar ? clicouProsseguir(e) : clicouAtivar(e);
                                                }}
                                            />
                                            <label htmlFor="inputConfirmPassword">Senha</label>
                                            {(erros.senha) && 
                                                <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                            }
                                        </div>
                                        {
                                            ativar &&
                                            <div className="form-label-group">
                                                <input 
                                                    id="inputRepetirSenha"
                                                    className={["form-control", (erros.confirmarSenha) ? 'input-erro' : ''].join(' ')}
                                                    placeholder="Senha" required 
                                                    value={dados.confirmarSenha || ''}
                                                    onChange={(e) => {
                                                        dados.confirmarSenha = e.target.value;
                                                        alterarDados({...dados});
                                                    }}
                                                    onKeyDown={e => {
                                                        if(e.keyCode === 13) !ativar ? clicouProsseguir(e) : clicouAtivar(e);
                                                    }}
                                                />
                                                <label htmlFor="inputRepetirSenha">Repetir a senha</label>
                                                {(erros.confirmarSenha) && 
                                                    <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.confirmarSenha}</p></Alert>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                                
                                <button 
                                    onClick={!ativar ? clicouProsseguir : clicouAtivar} 
                                    className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2 botao-cadastrar" 
                                    type="submit"
                                    disabled={carregando}
                                >
                                    {carregando && 
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    {ativo ? 'Redirecionando' : (carregando ? ' Carregando' : (!ativar ? 'Encontrar' : 'Ativar')) }
                                </button>
                                <div className="text-right">
                                    <Link className="small" to="/login">Voltar para o login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LoginComponent>;

}