import styled, { css } from "styled-components";

export const ContainerCentro = styled.div`
    align-self: center;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    height: 90vh;
    display: flex;
    flex-direction: column;
`;
