import React, { useState, useEffect, useContext } from 'react';

// contexto
import { AssociarContexto } from './../AssociarContexto';

// componentes
import { converterReal } from './../../../../Funcoes';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Form, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// finaliza
export default function FinalizarPagamento(){
    
    // estados
    const { dados, alterarEtapa, valor, desconto, salvar, salvando } = useContext(AssociarContexto);
    const [pix, setPix] = useState('');
   
    async function geraPix() {
        const { data } = await axios.get('/pix');
        setPix(data);       
    }

    useEffect(() => {
        if(dados.metodoPagamento === 4){
            geraPix()
        }
    }, [])

    return <>
    
        <div className="text-center">
            <small >Confirme se as informações estão correta e confirme o pagamento!</small>
        </div>
        <hr />

        <p className="p-3">
            Você escolheu pagar no 
            <Badge className="mx-1" variant={'secondary'}>{dados.metodoPagamento === 1 && 'cartão de crédito'} {dados.metodoPagamento === 2 && 'boleto'} {dados.metodoPagamento === 3 && 'débito online'} {dados.metodoPagamento === 4 && 'pix'}</Badge> 
            o valor de <Badge className="mx-1" variant="secondary">R$ {converterReal((valor).toString())}</Badge>!
        </p>
        { (dados.metodoPagamento != 4 || pix === '') ? '' : (typeof pix.erros != 'undefined') ?  
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <p>Erro não foi possivel criar um QRCode devido:</p>
                    {pix.erros.map((erro, index) => <p key={index}>ERRO: {erro}</p>)}
                </div>
            :
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img className="img-fluid" style={{ maxWidth: '250px', width: '100%' }} src={pix.qrCode} alt='QRCode' ></img>
                    <Button onClick={ () => { navigator.clipboard.writeText(pix.pixCopiaCola) }}> PIX COPIA E COLA </Button> 
                    <Link to={'../Carteira'}>
                        <p>Ao finalizar o pagamento retorne a CARTEIRA.</p>   
                    </Link>
                </div>
        }

        <p className={'d-flex justify-content-between mt-3'}>
            <Button
                variant = {'primary'}
                onClick = {() => {
                    switch(dados.metodoPagamento){
                        case 1: alterarEtapa('formularioCartao'); break;
                        case 2: alterarEtapa('formularioBoleto'); break;
                        case 3: alterarEtapa('formularioDebito'); break;
                        // case 4: alterarEtapa('formularioPix'); break;
                        case 4: alterarEtapa('finalizar'); break; // pix
                    }
                }}
            >
                Voltar
            </Button>
            

            {dados.metodoPagamento === 4 ? '' :
                <Button
                    variant={'success'}
                    onClick={salvar}
                    disabled={salvando}
                >
                    { salvando ? 
                        <>
                            <FontAwesomeIcon icon={faSpinner} pulse />
                            <span>Processando!</span>
                        </>
                        :
                        'Pagar!'
                    }
                </Button> 
            }
        </p>
       
    </>

   
}