import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { useSelector  } from 'react-redux';

// componentes
import Login from './Login';
import Painel from './Painel';
import Cadastro from './Cadastro';
import RedefinirSenha from './RedefinirSenha';
import AssociadosVigentes from './AssociadosVigentes';
import AssociadosPedreira from './AssociadosPedreira';

function Sistema({

}){

    // reducers
    // let loginReducer = useSelector(state => state.loginReducer);
    const { loginReducer, configuracaoReducer } = useSelector(state => state);

    return <>
        <Router>
            <Switch>
                <Route exact path={`/`}>
                    <Redirect to='/painel' />
                </Route>
                <Route path={`/cadastro`}>
                    { loginReducer.logado ? <Redirect to="/painel" /> : <Cadastro /> }
                </Route>
                <Route path={[`/login`, `/associado`]}>
                    { loginReducer.logado ? <Redirect to="/painel" /> : <Login /> }
                </Route>
                <Route path={`/painel`}>
                    { loginReducer.logado ? <Painel /> : <Redirect to="/login" /> }
                </Route>
                <Route path={`/redefinirSenha/:token`}>
                    { loginReducer.logado ? <Redirect to="/painel" /> :  <RedefinirSenha /> }
                </Route>
                <Route path={`/vigentes`}>
                    <AssociadosVigentes />
                </Route>
                <Route path={`/pedreira`}>
                    <AssociadosPedreira />
                </Route>
                <Route path={`*`}>
                    <Redirect to='/painel' />
                </Route>
            </Switch>
        </Router>
    </>
}

export default Sistema;