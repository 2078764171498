import React, { useState, useEffect, useContext } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";

import { Container } from 'react-bootstrap';

import Upload from "./Upload";
import ListaArquivos from "./../ListaArquivos";

export default function DropArquivos({
    arquivosEnviados, 
    aoSelecionarArquivos,
    aoDeletar,
    editavel=true,
    preview=true
}){
    
    const aoSelecionarArquivosDrop = arquivos => {
        const arquivosSelecionados = arquivos.map(arquivo => ({
            arquivo,
            key: uniqueId(),
            name: arquivo.name,
            tamanho: filesize(arquivo.size),
            preview: URL.createObjectURL(arquivo),
            progresso: 0,
            salvo: false
        }));

        console.log(arquivosSelecionados)
        aoSelecionarArquivos(arquivosSelecionados);

    };

    return <>
        <Container>
            <div>
                <Upload aoSelecionarArquivos={aoSelecionarArquivosDrop} />
                {preview && !!arquivosEnviados.length && (
                    <ListaArquivos arquivos={arquivosEnviados} aoDeletar={aoDeletar} editavel={editavel}/>
                )}
            </div>
        </Container>
    </>
}
