import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { ProgressBar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

import InputValorReal from './../../../../Componentes/InputValorReal';
import { ImportarXlsUsuariosComponent } from './components';

// contexto
import { ConfiguracaoContexto } from './../../ConfiguracaoContexto';

// valor de associação
export default function ImportarXlsUsuarios(){


    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const configuracaoProvider = useContext(ConfiguracaoContexto);
    const [arquivo, alterarArquivo] = useState(null);
    const [progresso, alterarProgresso] = useState(null);
    const [erros, alterarErros] = useState(null);
    const [importando, alterarImportando] = useState(false);
    const [importado, alterarImportado] = useState(false);

    let refs = {
        inputArquivo: React.createRef()
    }

    // salva código de configuracao
    async function importar(){

        // inicia o progresso do envio
        alterarProgresso(0);

        try{
                        
            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('arquivo', arquivo);

            // faz a requisição
            let { data } = await axios.post(`/usuario/importarXls`, formData, {
                onUploadProgress: (progressEvent) => {
                    let progressoEnvio = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    alterarProgresso(progressoEnvio);

                    // se enviou o arquivo
                    if(progressoEnvio == 100){
                        alterarImportando(true);
                    }
                }
            });

            // finalizado com sucesso
            alterarProgresso(null);
            alterarImportado(true);

        }catch({response}){
            alterarErros(response.data);
        }finally{
            alterarImportando(false);
        }

    }

    function limparDados(){
        alterarErros(null);
        alterarArquivo(null);
        alterarProgresso(null);
        alterarImportado(false);
    }

    return <>
        <ImportarXlsUsuariosComponent
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Importar arquivo XLS de usuários
            </p>
            <div
                className='flex-grow-1 ml-2 d-flex align-items-center text-right justify-content-end'
            >
                {
                    importado && <>
                        <b>Importado!</b>
                        <Button
                            onClick={() => {
                                limparDados();
                            }}
                            variant='padrao'
                            size='sm'
                            className="mx-2"
                        >
                            Novo
                        </Button>
                    </>
                }
                {
                    !importado && 
                    <>
                    {
                    importando ? 
                        <span className="mr-2">Importando</span>
                    : 
                    <>
                    {
                        progresso == null ?
                            <>
                                <input 
                                    hidden 
                                    type="file" 
                                    ref={refs.inputArquivo} 
                                    onChange={() => {
                                        if(refs.inputArquivo.current.files.length > 0){
                                            alterarArquivo(refs.inputArquivo.current.files[0]);
                                        }
                                    }}
                                />
                                <OverlayTrigger 
                                    placement='top'
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Selecionar arquivo
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant='padrao'
                                        onClick={() => {
                                            refs.inputArquivo.current.click();
                                        }}
                                        size='sm'
                                        className="mx-2"
                                    >
                                        <span><FontAwesomeIcon icon={faFileExcel} /></span>
                                    </Button>
                                </OverlayTrigger>
                                {
                                    arquivo &&
                                    <Button
                                        variant='success'
                                        onClick={() => {
                                            importar()
                                        }}
                                        size='sm'
                                    >
                                        <span>Importar</span>
                                    </Button>
                                }
                            </>
                            :
                            <div className="flex-grow-1">
                                <div className="flex-grow-1 d-flex align-items-center">
                                    <ProgressBar 
                                        variant={erros ? 'danger' : 'success'} 
                                        animated
                                        now={progresso}
                                        label={`${progresso}%`}
                                    />
                                    {erros && 
                                        <OverlayTrigger 
                                            placement='top'
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    Remover
                                                </Tooltip>
                                            }
                                        >
                                            <button 
                                                className={'botao-remover'}
                                                onClick={() => {
                                                    limparDados();
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </OverlayTrigger>
                                    }
                                </div>
                                {
                                    erros && erros.arquivo &&
                                    <Alert className="alerta-erro text-left" dismissible onClose={() => {
                                        limparDados()
                                    }} variant="danger"><p>{erros.arquivo}</p></Alert>
                                }
                            </div>
                        }
                    </>
                    }
                    </>
                }
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {importando && <FontAwesomeIcon pulse icon={faSpinner} />}
                </div>
            </div>
        </ImportarXlsUsuariosComponent>
    </>

}