import React, { useState, useEffect } from 'react';
import './LoginPagina.scss';
import { LoginComponent } from './components';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link, useHistory
} from "react-router-dom";
import useIsMounted from 'ismounted';
import { Container } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';
import AtivarLogin from './AtivarLogin';
import FazerLogin from './FazerLogin';
import MandarEmail from '../MandarEmail';
import RedefinirSenha from './../RedefinirSenha';

import LogoIcone from './../../icones/svg/logoProvisoria-icone.png';

// cria contexto
import { PainelLogarContexto } from './PainelLogarContexto';

function Login({

}){

    // estados
    const history = useHistory();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    let [carregando, alterarCarregando] = useState(false);
    let [dados, alterarDados] = useState({
        email: '',
        senha: ''
    });
    let [erros, alterarErros] = useState({});
    let [lembrar, alterarLembrar] = useState(false);
    let loginReducer = useSelector(state => state.loginReducer);

    // faz login
    async function fazerLogin(){
        alterarCarregando(true);
        
        // faz a requisição para login
        axios.post('/usuario/login', dados)
        .then(({data, ...retorno}) => {

            // finaliza login
            loginFinalizado(data);


        })
        .catch(({response, ...retorno}) => {
            
            // erro
            if(response.status === 400){
                alterarErros(response.data);
            }
        })
        .then(() => {
            if (isMounted.current) {
                alterarCarregando(false);
            }
        });

    }

    // ao finalizar login
    function loginFinalizado(dadosUsuario, outros = null){
        
        // altera
        dispatch(Object.assign({
            type: 'loginReducer/FAZER_LOGIN',
            dadosUsuario: dadosUsuario
        }, outros));
    }

    function irParaCadastro(){
        history.push('/cadastro');
    }

    return <>
        <PainelLogarContexto.Provider value={{
            dados, alterarDados, fazerLogin, loginFinalizado, erros, alterarErros, irParaCadastro
        }}>
            <Router>
                <Switch>
                    <Route path={`/login`}>
                        { loginReducer.logado ? <Redirect to="/painel" /> : <FazerLogin /> }
                    </Route>
                    <Route path={`/associado`}>
                        { loginReducer.logado ? <Redirect to="/painel" /> : <AtivarLogin /> }
                    </Route>
                    <Route path={`/enviarEmail`}>
                        { loginReducer.logado ? <Redirect to="/painel" /> : <MandarEmail /> }
                    </Route>
                </Switch>
            </Router>
        </PainelLogarContexto.Provider>
    </>

}

export default Login;