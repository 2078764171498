
// icones font awesome
import { library } from '@fortawesome/fontawesome-svg-core';

// fab
import { 
    faFacebook, faInstagram
} from '@fortawesome/free-brands-svg-icons';


library.add(
    faFacebook, faInstagram
);