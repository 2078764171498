import React from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Carregador({
    className='',
    small=false
}){

    return <div className={[styles.carregador, className].join(' ')}>
        <FontAwesomeIcon className={[styles.icone, small ? styles.small : ''].join(' ')} icon={'spinner'} pulse />
        <span>Carregando</span>
    </div>
}

export default Carregador;