import styled, { css } from "styled-components";
import backGroundImage from './../Login/background.svg';

export const CadastroComponent = styled.div.attrs({
	className: ''
})`

	background: url(${backGroundImage});
	background-size: cover;

	.botao-cadastrar{
		background: #f38225;
		border-color: #e2712a;

		&:hover{
			background: #ef7715;
			border-color: #e26a1f;
		}

		&:active, &:focus{
			background: #ea7f28 !important;
			border-color: #e0702a !important;
		}
	}
	
`;