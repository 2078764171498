import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck, FormLabel, Badge, Modal, ModalBody } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros, 
    mostrarFiltros,
    alterarMostrarFiltros
}){

    return <div className="parametros">
        <div className="parametros-inicial" style={{
            alignItems: 'unset'
        }}>
            {/* <Link to='/painel/movimentos/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    { isBrowser && <span>Novo</span>}
                </Button>
            </Link> */}
            <div className={[styles.campoPesquisa, 'd-flex flex-grow-1 align-items-center'].join(' ')}>
                <div className={[styles.icone].join(' ')}>
                    <FontAwesomeIcon icon={["fas", "search"]} />
                </div>
                <DebounceInput 
                    placeholder="Pesquise um projeto" 
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.texto}
                    onChange={(e) =>{
                        alterarParametros({...parametros, descricao: e.target.value, pagina: 1})
                    }}
                />
            </div>
            <Button 
                className={[styles.botao].join(' ')}
                style={{
                    fontSize: 12
                }}
                variant="padrao"
                onClick={(e) => {
                    alterarMostrarFiltros(!mostrarFiltros)
                }}
            >
                <FontAwesomeIcon className="icone" icon={["fas", "filter"]} />
            </Button>
        </div>
        <Modal
            className={['modal-personalizado'].join(' ')}
            size="sm"
            show={mostrarFiltros}
            onHide={() => {
                alterarMostrarFiltros(false)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className={'text-center flex-grow-1'}>
                    Filtros
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Row>
                        <Col className={'mb-3'}>
                            <Form.Label>Realizado de</Form.Label>
                            <InputDataCombo
                                data={parametros.dataInicioRealizado}
                                onChange={(data) => {
                                    alterarParametros({...parametros, dataInicioRealizado: data});
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Realizado até</Form.Label>
                            <InputDataCombo
                                data={parametros.dataFimRealizado}
                                onChange={(data) => {
                                    alterarParametros({...parametros, dataFimRealizado: data});
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Modal.Body>
        </Modal>
    </div>
}