
import moment from 'moment';
import * as R from 'ramda';

// converte para reais o valor
export function converterReal(valor, direcao = 'up'){
    if(direcao === 'up'){
        return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }else{
        let calcDec = Math.pow(10, 2);
        valor = Math.trunc(valor * calcDec) / calcDec;
        return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }
    
}

// verifica se a data é valida
export function validarData(data, tipo = 'valid'){
    if(data !== null && data.length === 10){

        if(tipo === 'valid' && moment(new Date(data)).isValid()){
           return true;
        }else if(!moment(new Date(data)).isValid()){
            return true;
        }
    }
    if(tipo === 'valid'){
        return false;
    }else{
        return false;
    }
}

export function mascaraTelefone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export function mascaraCpf(valor){
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
}

export function mascaraCnpj(valor) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
}

// intersect object
export function intersectObject(obj1, obj2){
    let foo = R.clone(obj1);
    let bar = R.clone(obj2);
    for (var key in bar) {
        if (key in foo)
            foo[key] = bar[key];
    }
    return foo;
}