import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card } from 'react-bootstrap';
import OpcaoBoleana from './../OpcaoBoleana';

// componente
export default function ConfiguracoesFuncoes(){

    return <>
        <Card
            className='border-0 shadow-sm'
        >
            <Card.Body>
                <p className={styles.cabecalho}>
                    Permissões avançadas
                </p>
                
                <OpcaoBoleana 
                    descricao={`Permite alterar as permissões avançadas dos usuários`}
                    codigo={'permitirAlterarConfiguracaoUsuario'}
                />
                <OpcaoBoleana 
                    descricao={`Habilitar o acesso a tela de consulta de usuários`}
                    codigo={'habilitarConsultaUsuarios'}
                />
                <OpcaoBoleana 
                    descricao={`Habilitar o acesso a tela de modelos de email`}
                    codigo={'habilitarConsultaModelosEmail'}
                />
                <OpcaoBoleana 
                    descricao={`Permite alterar as configurações do sistema`}
                    codigo={'habilitarConfiguracaoSistema'}
                />
                <OpcaoBoleana 
                    descricao={`Permite importar xls de usuários`}
                    codigo={'habilitarImportarXlsUsuarios'}
                />
                <OpcaoBoleana 
                    descricao={`Habilitar consulta por QRCode`}
                    codigo={'habilitarConsultaQrcode'}
                />

            </Card.Body>
        </Card>
    </>
}