import styled, { css } from "styled-components";

export const ImportarXlsUsuariosComponent = styled.div.attrs({
	className: ''
})`

    .progress{
        width: -webkit-fill-available;
    }

    .botao-remover{
        background: none;
        padding: 0.2rem 0.35rem;
        border: none;
        color: #616161;
    }

    .alerta-erro{
        padding: 0.5rem 0.5rem;
        padding-right: 1.5rem;
        font-size: 0.75rem;
        margin: 0;

        p{
            margin: 0;
        }

        button{
            padding: 0.25rem 0.3rem;
        }

    }
`;