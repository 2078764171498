import * as React from "react";

const SvgCursosIcone = (props) => {
  return (
    <svg
      id="cursos-icone_svg__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M101.5 78h-75c-4.7 0-8.5-3.8-8.5-8.5V20c0-4.7 3.8-8.5 8.5-8.5h75c4.7 0 8.5 3.8 8.5 8.5v49.5c0 4.7-3.8 8.5-8.5 8.5zm-75-63.5c-3 0-5.5 2.5-5.5 5.5v49.5c0 3 2.5 5.5 5.5 5.5h75c3 0 5.5-2.5 5.5-5.5V20c0-3-2.5-5.5-5.5-5.5h-75z" />
          <path
            d="M112 19.5H16c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h96c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1z"
            fill="#fff"
          />
          <path d="M112 21H16c-1.4 0-2.5-1.1-2.5-2.5v-7c0-1.4 1.1-2.5 2.5-2.5h96c1.4 0 2.5 1.1 2.5 2.5v7c0 1.4-1.1 2.5-2.5 2.5zm-95.5-3h95v-6h-95v6zM67.6 86h-7.3c-2.7 0-4.9-2.2-4.9-4.9v-4.6c0-.8.7-1.5 1.5-1.5h14c.8 0 1.5.7 1.5 1.5v4.6c.1 2.7-2.1 4.9-4.8 4.9zm-9.1-8v3.1c0 1 .8 1.9 1.9 1.9h7.3c1 0 1.9-.8 1.9-1.9V78H58.5z" />
          <path d="M64 107c-.8 0-1.5-.7-1.5-1.5v-21c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v21c0 .8-.7 1.5-1.5 1.5z" />
          <path d="M89 119H39c-.8 0-1.5-.7-1.5-1.5v-3.2c0-5.7 4.6-10.3 10.3-10.3h32.4c5.7 0 10.3 4.6 10.3 10.3v3.2c0 .8-.7 1.5-1.5 1.5zm-48.5-3h47v-1.7c0-4-3.3-7.3-7.3-7.3H47.8c-4 0-7.3 3.3-7.3 7.3v1.7zM96 39H32c-.8 0-1.5-.7-1.5-1.5S31.2 36 32 36h64c.8 0 1.5.7 1.5 1.5S96.8 39 96 39z" />
          <g>
            <path d="M96 51H32c-.8 0-1.5-.7-1.5-1.5S31.2 48 32 48h64c.8 0 1.5.7 1.5 1.5S96.8 51 96 51z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default SvgCursosIcone;
