import styled, { css } from "styled-components";

export const Campo = styled.span`
	font-size: 1.1rem;
	margin: 8px;
	padding: 2px;
`;

export const TextoDestaque = styled.p`
	font-size: 1.3rem;
	margin-top: 6px;
    padding: 2px;
    margin-bottom: 7px;
`;

export const CentroComponent = styled.div.attrs({
	className: ''
})`
	flex-grow: 1;
	border-radius: 5px;
    width: clamp(300px,60vw,700px);
	margin: auto;

	@media only screen and (max-width : 600px) {
    	min-width: 100%;
	}

	.col, .col-md-5{
    	padding-right: 0.5rem !important;
    	padding-left: 0.5rem !important;
	}
`;

export const CodigoQrCode = styled.div`
	width: -webkit-fill-available;
	display: flex;
	align-items: center;
	justify-content: center;
    padding: 10px;

	img{
		width: 200px;
		height: 200px;
	}
`;


export const Alerta = styled.div`
	border-radius: 3px;
	padding: 10px;
`;