import React from 'react';
import styles from './index.module.scss';

// componentes
import { Button } from 'react-bootstrap';

// componente para mostrar a imagem
function Imagem({
    dados,
    incluir
}){

    return <div
        className={[styles.container, 'mb-3 rounded bg-light p-2'].join(' ')}
    >
        <img 
            src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/imagensEmail/${dados.nomeTemporario}`} 
            className={[styles.imagem, 'mr-2 rounded'].join(' ')} 
        />
        <div className={styles.info}>
            <div className={styles.descricao}>
                {dados.nome}
            </div>
            <div
                className={styles.opcoes}
            >
                <Button
                    variant='padrao'
                    size='sm'
                    onClick={incluir}
                >
                    Incluir
                </Button>
            </div>
        </div>
    </div>
}

export default Imagem;