import React, { useState, useEffect } from 'react';
import './../Login/LoginPagina.scss';
import axios from 'axios';
import { CadastroComponent } from './components';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Link, useHistory } from 'react-router-dom';
import useIsMounted from 'ismounted';
import { Container } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';

import LogoIcone from './../../icones/svg/logo2.svg';

import logo from './../../assets/midiaLocalLogo.png';

export default function Cadastro({

}){

    // estados
    let history = useHistory();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    let [carregando, alterarCarregando] = useState(false);
    let [dados, alterarDados] = useState({
        id: null,
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: ''
    });
    let [erros, alterarErros] = useState({});

    // faz login
    async function clicouCadastrar(){
        
        // mostra carregamento
        alterarCarregando(true);             

        try {
            
            // faz a requisição para login
            await axios.post('/usuario', {
                dados: dados
            })
                        
            history.push('/login');

            if (isMounted.current) {
                alterarCarregando(false);
            }

        } catch(e) {            
            if(e.response.data) {
                console.log(e.response.data)
                alterarErros({ ...erros, 
                    nome: e.response.data.nome, 
                    email: e.response.data.email, 
                    senha: e.response.data.senha,
                    confirmarSenha: e.response.data.confirmarSenha
                })
            }
            alterarCarregando(false)
        }                            

    }

    // retorna componente
    return <CadastroComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
            // style={{
            //     background: `url(${backGroundImage})`,
            //     backgroundSize: `cover`
            // }}
        >
            <div md={3} className="logo">
                <img src={LogoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Cadastro</h3>
                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputNome"
                                        className={["form-control", (erros.nome) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Nome" 
                                        required autoFocus
                                        value={dados.nome}
                                        onChange={(e) => {
                                            dados.nome = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.key === 'Enter') clicouCadastrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputNome">Nome</label>
                                    { erros.nome && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({ ...erros, nome: null })} variant="danger"><p>{erros.nome}</p></Alert>
                                    }
                                    
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", (erros.email) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Endereço de email" 
                                        required autoFocus
                                        value={dados.email}
                                        onChange={(e) => {
                                            dados.email = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.key === 'Enter') clicouCadastrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Endereço de email</label>
                                    { erros.email && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({ ...erros, email: null })} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                    
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", (erros.senha) ? 'input-erro' : ''].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha}
                                        onChange={(e) => {
                                            dados.senha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.key === 'Enter') clicouCadastrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {(erros.senha) && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({ ...erros, senha: null })} variant="danger"><p>{erros.senha}</p></Alert>
                                    }
                                </div>
                                
                                <div className="form-label-group">
                                    <input 
                                        id="inputRepetirSenha"
                                        type="password"
                                        className={["form-control", (erros.confirmarSenha) ? 'input-erro' : ''].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.confirmarSenha}
                                        onChange={(e) => {
                                            dados.confirmarSenha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.key === 'Enter') clicouCadastrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputRepetirSenha">Repetir a senha</label>
                                    {(erros.confirmarSenha) && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({ ...erros, confirmarSenha: null })} variant="danger"><p>{erros.confirmarSenha}</p></Alert>
                                    }
                                </div>

                                <button 
                                    onClick={clicouCadastrar}
                                    className="botao-cadastrar btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregando}
                                >
                                    {carregando && 
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    {carregando ? ' Carregando' : 'Cadastrar' }
                                </button>

                                <div className="text-center">
                                    <Link className="small" to="/login">já tenho cadastro!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="desenvolvido" ><p>Desenvolvido por</p> <img src={logo} /></div>
    </CadastroComponent>;

}