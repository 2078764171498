import styled from 'styled-components';

export const MetodoComponent = styled.div.attrs({
    className: ''
})`

    width: 100%;
    text-align: center;
    cursor: pointer;

    .card {

        background: #28a745 !important;

        &:hover {
            box-shadow: 0 0 3px #555c63;
            background: #555c63 !important;
        }

    }

    .descricao {
        align-items: center;
        font-size: 1rem;
        display: inline-flex;
        flex-direction: column;
        white-space: normal;
    }

`