import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

import BotaoTabela from './../../../../Componentes/BotaoTabela';

// componente
export default function Registro({
    dados,
    clicouEditar,
    clicouExcluir,
    clicouDireto
}){

    // // estados
    // let configuracao = useSelector(state => state.ConfiguracaoReducer);
    // const configuracaoUsuarioReducer = useSelector(state => state.ConfiguracaoUsuarioReducer);

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={faPencilAlt}
            >
                <span>Editar</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={faTrash}
            >
                <span>Excluir</span>
            </BotaoTabela>
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                onClick={clicouDireto}
            >
                <td className={'text-nowrap estreito'}>
                    {dados.dataRealizado}
                </td>
                <td>
                    {dados.titulo}
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        { isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouDireto}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.titulo}
                                </span>
                            </div>
                        </div>
                        <div className={'text-nowrap text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}