import styled from 'styled-components';

export const BandeiraComponent = styled.div.attrs({
    className: ''
})`

    display: inline-flex;
    align-items: center;
    /* padding: 5px; */

`;