

export default function(state = {}, action){

    // ações globais
    switch(action.type){

        // aplica os dados de login
        case 'configuracaoReducer/ATUALIZAR':
            return {...state, ...action.dados};
        default: 
            return state
    }
}