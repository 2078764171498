import React from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import OpcaoBoleana from './../OpcaoBoleana';
import ValorAssociar from './ValorAssociar';
import ImportarXlsUsuarios from './ImportarXlsUsuarios';
import ServidorEmail from './ServidorEmail';
import ModeloEmailBemVindo from './ModeloEmailBemVindo';

// contexto
import { ConfiguracaoContexto } from './../ConfiguracaoContexto';


// componente
export default function ConfiguracoesSistema(){

    // estados
    const { configuracaoReducer } = useSelector(state => state);

    return <>
        <Card
            className='border-0 shadow-sm mb-5'
        >
            <Card.Body>
                <p className={styles.cabecalho}>
                    Configurações do sistema
                </p>
                
                {/* <OpcaoBoleana 
                    descricao={`Permite alterar as permissões avançadas do usuário`}
                    codigo={'permitirAlterarConfiguracaoUsuario'}
                /> */}

                <ValorAssociar />

                {
                    configuracaoReducer.habilitarImportarXlsUsuarios === 'S' &&
                    <ImportarXlsUsuarios />
                }
                
                <p className={styles.cabecalho}>
                    Servidor de email para envio
                </p>

                <ServidorEmail />

                <p className={styles.cabecalho}>
                    Enviar email após: 
                </p>

                <ModeloEmailBemVindo />

                <p className="text-right">
                    <small><a href="/painel/modelos-email">Modelos disponiveis</a></small>
                </p>

            </Card.Body>
        </Card>
    </>
}