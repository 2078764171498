import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Toast, Row, Col, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import {Link, Redirect, useHistory} from 'react-router-dom';
import logo from './../../assets/midiaLocalLogo.png';
import logoIcone from './../../icones/svg/logo2.svg'
import './MandarEmail.scss';
import Carregador from './../Componentes/Carregador';

// componentes
import {MandarEmailComponent} from './components';

export default function MandarEmail(){

    async function mandarEmailParaNovaSenha(){
        alterarCarregando(true);
        
        try{
            //enviar email ao backend
            await axios.post(`/usuario/enviarTokenEmail`, {
                email: email 
            });

            //ativa o modal
            alterarModalEmailEnviado(true);

        }catch(e){
            console.log(e)
            //se houver erro, toast fala que o email nao foi encontrado
            toast(({closeToast }) => <>
                    <div className="toast-header bg-danger text-white">
                        <strong className="mr-auto">E-mail não encontrado!</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body  bg-danger text-white">
                        O e-mail está incorreto ou não está cadastrado
                    </div>
                </>
            )    
        }finally{
            alterarCarregando(false);
        }
    }
    //modal para redirecionar o usuario para a tela de login
    function montarModalEmailEnviado(){
        return <> 
            <Modal 
                show={modalEmailEnviado} 
                //quando o modal fechar, redirecionar o usuario para a tela de login
                onHide={() => {
                    history.push('/login');
                }}
                size="md"
                className={['modal-personalizado'].join(' ')}
                aria-labelledby="contained-modal-title-center"
                centered
                backdrop={true}    
            >
                <Modal.Header closeButton>
                    <Modal.Title>O E-mail foi enviado com sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Enviamos um email para você, acesse para continuar com a troca de senha
                </Modal.Body>
                <Modal.Footer>

                    <Link to="/login">
                        <Button variant="primary">
                            OK
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </>
    }

    const [modalEmailEnviado, alterarModalEmailEnviado] = useState(false);
    const [email, alterarEmail] = useState('');
    const [mostraAlerta, alterarMostraAlerta] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const history = useHistory();

    return <MandarEmailComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
        >
            <div md={3} className="logo">
                <img src={logoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                                <h3 className="login-heading mb-4">Redefinir Senha</h3>
                                    <div className="form-label-group">
                                        <input 
                                             type="text" 
                                             id="inputEmail"
                                             className={["form-control"].join(' ')} 
                                             placeholder="Endereço de email" 
                                             required autoFocus
                                             value={email || ''}
                                             onChange={(e) => {
                                                 //pegar o valor digitado pelo usuario
                                                 alterarEmail(e.target.value)
                                             }}
                                             onKeyDown={e => {
                                                //se clicou na tecla enter, verifica se o email esta nulo ou vazio
                                                //se estiver mostra o alerta, se nao chama a funcao de mandar o email ao backend
                                                if(e.keyCode === 13) 
                                                email == null || email == '' ? alterarMostraAlerta(true)  : mandarEmailParaNovaSenha()
                                            }}
                                        />
                                        <label htmlFor="inputEmail">Endereço de email</label>
                                        {/* alerta caso o usuario enviou o email sem ter digitado nada */}
                                        {mostraAlerta == true && 
                                        <Alert className="alerta-erro" onClose={()=> alterarMostraAlerta(false)} dismissible variant="danger"><p>E-mail não encontrado</p></Alert>
                                        }
                                    </div>
                                      
                                    <div className="d-flex align-items-center justify-content-around px-1">
                                    
                                        <Link to="/login" className={['col-5 p-0'].join('')}>
                                            <Button
                                                type="button"
                                                className={['rounded-pill botao-associado'].join(' ')}
                                            >
                                                <span>Cancelar</span>
                                            </Button>
                                        </Link>
                                            <Button
                                                type="submit"
                                                className={['rounded-pill botao-associado col-5'].join(' ')}
                                                onClick={() => {
                                                    email == null || email == '' ? alterarMostraAlerta(true)  : mandarEmailParaNovaSenha(email)
                                                }}
                                            >
                                                <span>Enviar</span>
                                            </Button>
                                    </div>
                                    {/* funcao de carregamento */}
                                    {carregando && 
                                         <Carregador className="bg-transparent text-dark" />
                                    }  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="desenvolvido" ><p>Desenvolvido por</p> <img src={logo} /></div>

        {/* Se o modalEmailEnviado for true, chama o modal */}
        { modalEmailEnviado &&  montarModalEmailEnviado() }

    </MandarEmailComponent>

}
