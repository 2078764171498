import React, { useState, useEffect, useContext }  from 'react';
import styles from './index.module.scss';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

// contextos    
import { PainelContexto } from './../../Painel/PainelContexto';

import FormularioImagem from './../../../icones/ilustracoes/formulario-imagem.svg';
import CursosImagem from './../../../icones/ilustracoes/cursos-imagem.svg';
import ProjetosImagem from './../../../icones/ilustracoes/trip-imagem.svg';
import QRCodeImagem from './../../../icones/ilustracoes/qrcode-imagem.svg';
import CarteiraImagem from './../../../icones/ilustracoes/carteirinha-imagem.svg';
import AgendaImagem from './../../../icones/ilustracoes/calendario-imagem.svg';
import BemVindoImagem from './../../../icones/ilustracoes/bem-vindo-imagem.svg';

// inicializa
export default function Inicio(){

    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const { loginReducer, configuracaoReducer } = useSelector(state => state);
    let painelContextoDados = useContext(PainelContexto);
    const [carregando, alterarCarregando] = useState(true);
    const [mostrarBemVindoAtivacao, alterarMostrarBemVindoAtivacao] = useState(false);
    
    // inicializa
    useEffect(() => {
        
        // verifica se deve mostrar mensagem de bem vindo
        alterarMostrarBemVindoAtivacao(loginReducer.loginAtivacao || false);

        // deve mostrar só uma vez ao logar
        if(loginReducer.loginAtivacao){
            loginReducer.loginAtivacao = false;
        }
    }, []);

    return <>
        <div className={[styles.divCentro, ''].join(' ')}>
            {
                mostrarBemVindoAtivacao &&
                <Row>
                    <Col className={['d-flex mb-3'].join(' ')}>
                        <Card
                            className={[styles.sombraCard, 'border-0 flex-grow-1'].join(' ')}
                        >
                            <Card.Body className={'d-flex flex-grow-1'}>
                                <div className={['d-flex align-items-center justify-content-center flex-grow-1'].join(' ')}>
                                    <div className='font-weight-light'>Bem vindo, {loginReducer.dadosUsuario.nome}! Seu usuário foi ativado.</div>
                                    <img
                                        className={['ml-3'].join(' ')} style={{
                                        width: 'clamp(30px, 20vw, 70px)'
                                    }} src={BemVindoImagem}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
            <Row>
                <Col md={4} className={['d-flex mb-3'].join(' ')}>
                    <Card
                        className={[styles.cardAtalho, styles.sombraCard, 'border-0 flex-grow-1'].join(' ')}
                    >
                        <Link 
                            className={'d-flex flex-grow-1'}
                            to={'/painel/usuario'} 
                        >
                            <Card.Body className={'d-flex flex-grow-1'}>
                                <div className={['d-flex align-items-center justify-content-around flex-column flex-grow-1'].join(' ')}>
                                    <img
                                        className={['mb-3'].join(' ')} style={{
                                        width: 'clamp(30px, 20vw, 200px)'
                                    }} src={FormularioImagem}/>
                                    <div className='font-weight-lighter'>Meus dados</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={4} className={['mb-3'].join(' ')}>
                    <Card
                        className={[styles.cardAtalho, styles.sombraCard, 'border-0'].join(' ')}
                    >
                        <Link 
                            to={'/painel/cursos'} 
                        >
                            <Card.Body>
                                <div className={['d-flex align-items-center justify-content-center flex-column'].join(' ')}>
                                    <img
                                        className={['mb-3'].join(' ')} style={{
                                        width: 'clamp(30px, 20vw, 150px)'
                                    }} src={CursosImagem}/>
                                    <div className='font-weight-lighter'>Cursos</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                
                </Col>
                <Col md={4} className={['d-flex mb-3'].join(' ')}>
                    <Card
                        className={[styles.cardAtalho, styles.sombraCard, 'border-0 flex-grow-1'].join(' ')}
                    >
                        <Link 
                            className={'d-flex flex-grow-1'}
                            to={'/painel/projetos'} 
                        >
                            <Card.Body className={'d-flex flex-grow-1'}>
                                <div className={['d-flex align-items-center justify-content-around flex-column flex-grow-1'].join(' ')}>
                                    <img
                                        className={['mb-3'].join(' ')} style={{
                                        width: 'clamp(30px, 20vw, 200px)'
                                    }}  src={ProjetosImagem}/>
                                    <div className='font-weight-lighter'>Projetos</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                
                </Col>
            </Row>
            <Row>
                <Col md={7} className={['d-flex'].join(' ')}>
                    <Card
                        className={[styles.cardAtalho, styles.sombraCard, 'border-0 mb-3 flex-grow-1'].join(' ')}
                    >
                        <Link 
                            className={'d-flex flex-grow-1'}
                            to={'/painel/carteira'}
                        >
                            <Card.Body className={'d-flex flex-grow-1'}>
                                <div className={['d-flex align-items-center justify-content-center flex-column flex-grow-1'].join(' ')}>
                                    <img
                                        className={['mb-3'].join(' ')} 
                                        style={{
                                            width: 'clamp(35px, 40vw, 450px)'
                                        }} 
                                        src={CarteiraImagem}
                                    />
                                    <div className='font-weight-lighter'>Carteira</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={5} className={['mb-3'].join(' ')}>
                    {/* <Row>
                        <Col className={[''].join(' ')}>
                            <Card
                                className={[styles.cardAtalho, styles.sombraCard, 'border-0'].join(' ')}
                            >
                                <Link 
                                    to={'/painel/novo'} 
                                >
                                    <Card.Body>
                                        <div className={['d-flex align-items-center justify-content-center flex-column'].join(' ')}>
                                            <img
                                                className={['mb-3'].join(' ')} style={{
                                                width: 'clamp(30px, 20vw, 100px)'
                                            }} src={AgendaImagem}/>
                                            <div className='font-weight-lighter'>Criar</div>
                                        </div>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row> */}
                    {configuracaoReducer.habilitarConsultaQrcode === 'S' &&
                        <Row>
                            <Col className={['mb-3'].join(' ')}>
                                <Card
                                    className={[styles.cardAtalho, styles.sombraCard, 'border-0'].join(' ')}
                                >

                                    <Link 
                                        to={'/painel/consultar'} 
                                    >
                                        <Card.Body>
                                            <div className={['d-flex align-items-center justify-content-center flex-column'].join(' ')}>
                                                <img
                                                    className={['mb-3'].join(' ')} style={{
                                                    width: 'clamp(30px, 20vw, 150px)'
                                                }} src={QRCodeImagem} />
                                                <div className='font-weight-lighter'>QRCode</div>
                                            </div>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>

    </>

}