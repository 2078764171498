import * as React from "react";

const SvgProjetosIcone = (props) => {
  return (
    <svg
      id="projetos-icone_svg__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".projetos-icone_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}"
        }
      </style>
      <switch>
        <g>
          <path d="M24.7 39.6h-2.8c-4.5 0-8.2-3.3-8.2-7.2 0-4 3.7-7.2 8.2-7.2h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-2.9 0-5.2 1.9-5.2 4.2s2.3 4.2 5.2 4.2h2.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM24.7 62.6h-2.8c-4.5 0-8.2-3.3-8.2-7.2s3.7-7.2 8.2-7.2h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-2.9 0-5.2 1.9-5.2 4.2s2.3 4.2 5.2 4.2h2.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM24.7 85.6h-2.8c-4.5 0-8.2-3.3-8.2-7.2s3.7-7.2 8.2-7.2h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-2.9 0-5.2 1.9-5.2 4.2s2.3 4.2 5.2 4.2h2.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM24.7 108.6h-2.8c-4.5 0-8.2-3.3-8.2-7.2 0-4 3.7-7.2 8.2-7.2h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-2.9 0-5.2 1.9-5.2 4.2 0 2.3 2.3 4.2 5.2 4.2h2.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
          <path
            className="projetos-icone_svg__st0"
            d="M109.8 115.3H27c-1.7 0-3-1.3-3-3V15.8c0-1.7 1.3-3 3-3h82.7c1.7 0 3 1.3 3 3v96.5c.1 1.6-1.3 3-2.9 3z"
          />
          <path d="M109.8 116.8H27c-2.5 0-4.5-2-4.5-4.5V15.8c0-2.5 2-4.5 4.5-4.5h82.7c2.5 0 4.5 2 4.5 4.5v96.5c.1 2.4-2 4.5-4.4 4.5zM27 14.3c-.8 0-1.5.7-1.5 1.5v96.5c0 .8.7 1.5 1.5 1.5h82.7c.8 0 1.5-.7 1.5-1.5V15.8c0-.8-.7-1.5-1.5-1.5H27z" />
          <path
            className="projetos-icone_svg__st0"
            d="M40 115.3H27.6c-1.7 0-3-1.3-3-3V15.8c0-1.7 1.3-3 3-3H40v102.5z"
          />
          <path d="M40 116.8H27.6c-2.5 0-4.5-2-4.5-4.5V15.8c0-2.5 2-4.5 4.5-4.5H40c.8 0 1.5.7 1.5 1.5v102.5c0 .8-.7 1.5-1.5 1.5zM27.6 14.3c-.8 0-1.5.7-1.5 1.5v96.5c0 .8.7 1.5 1.5 1.5h10.9V14.3H27.6z" />
          <path d="M40 33.9c-.8 0-1.5-.7-1.5-1.5 0-2.3-2.2-4.2-4.8-4.2H24c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.7c4.3 0 7.8 3.3 7.8 7.2 0 .8-.7 1.5-1.5 1.5zM40 56.9c-.8 0-1.5-.7-1.5-1.5 0-2.3-2.2-4.2-4.8-4.2H24c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.7c4.3 0 7.8 3.3 7.8 7.2 0 .8-.7 1.5-1.5 1.5zM40 79.9c-.8 0-1.5-.7-1.5-1.5 0-2.3-2.2-4.2-4.8-4.2H24c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.7c4.3 0 7.8 3.3 7.8 7.2 0 .8-.7 1.5-1.5 1.5zM40 102.9c-.8 0-1.5-.7-1.5-1.5 0-2.3-2.2-4.2-4.8-4.2H24c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.7c4.3 0 7.8 3.3 7.8 7.2 0 .8-.7 1.5-1.5 1.5z" />
          <g>
            <path
              className="projetos-icone_svg__st0"
              d="M51 31.9h48.5v64.2H51z"
            />
            <path d="M99.5 97.6H51c-.8 0-1.5-.7-1.5-1.5V31.9c0-.8.7-1.5 1.5-1.5h48.5c.8 0 1.5.7 1.5 1.5v64.2c0 .8-.6 1.5-1.5 1.5zm-47-3H98V33.4H52.5v61.2z" />
          </g>
          <g>
            <path
              className="projetos-icone_svg__st0"
              d="M51 84.1L64.3 64l11 18.2 10-12.2 14.2 15.8v10.8H51z"
            />
            <path d="M99.5 98.1H51c-.8 0-1.5-.7-1.5-1.5V84.1c0-.3.1-.6.2-.8L63 63.2c.3-.4.8-.7 1.3-.7s1 .3 1.3.7l9.9 16.4L84.1 69c.3-.3.7-.5 1.1-.6.4 0 .9.2 1.1.5l14.3 15.8c.3.3.4.6.4 1v10.8c0 .9-.6 1.6-1.5 1.6zm-47-3H98v-8.7l-12.7-14-8.9 10.9c-.3.4-.8.6-1.3.5-.5 0-.9-.3-1.2-.7l-9.8-16.2-11.6 17.7v10.5z" />
          </g>
          <g>
            <circle
              className="projetos-icone_svg__st0"
              cx={75.3}
              cy={46}
              r={6}
            />
            <path d="M75.3 53.5c-4.1 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5 7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5zm0-12c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2.1-4.5-4.5-4.5z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default SvgProjetosIcone;
