import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import InputValorReal from './../../../../Componentes/InputValorReal';

// contexto
import { ConfiguracaoContexto } from './../../ConfiguracaoContexto';

// valor de associação
export default function ValorAssociar(){


    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const configuracaoProvider = useContext(ConfiguracaoContexto);
    const [salvando, alterarSalvando] = useState(false);
    const [valorNovo, alterarValorNovo] = useState(configuracaoProvider.configuracoes['valorAssociar']);


    // salva código de configuracao
    async function salvar(valorAlterado){

        // altera se foi modificado
        if(valorAlterado === valorNovo){
            return false;
        }

        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/configuracaoUsuarios/${configuracaoProvider.idUsuario}/valorAssociar`, {valor: valorAlterado});
            alterarValorNovo(data.valor);

            // se a alteração é do usuário atual, já reflete
            if(parseInt(configuracaoProvider.idUsuario) === parseInt(loginReducer.dadosUsuario.id)){
                dispatch({
                    type: 'configuracaoReducer/ATUALIZAR',
                    dados: {['valorAssociar']: data.valor}
                });
            }


        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    return <>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Valor a cobrar para associar-se
            </p>
            <div
                className='ml-2 d-flex align-items-center'
            >
                <InputValorReal 
                    valor={valorNovo}
                    onChange={valorAlterar => {
                        salvar(valorAlterar);
                    }}
                    disabled={salvando}
                    className='form-control-sm mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
    </>

}