import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { faFileInvoice, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

import BotaoTabela from './../../../../Componentes/BotaoTabela';

// componente
export default function Registro({
    dados,
    verBoleto
}){

    // // estados
    // let configuracao = useSelector(state => state.ConfiguracaoReducer);
    // const configuracaoUsuarioReducer = useSelector(state => state.ConfiguracaoUsuarioReducer);

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {(dados.metodoPagamento === '2' && ['1', '2'].includes(dados.statusTransacao)) && 
                <BotaoTabela 
                    onClick={verBoleto}
                    icone={faFileInvoice}
                >
                    <span>Boleto</span>
                </BotaoTabela>
            }
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
            >
                <td className={'text-nowrap estreito'}>
                    {['1', '2'].includes(dados.statusTransacao) && <Badge variant="info">Aberto</Badge>}
                    {['3', '4'].includes(dados.statusTransacao) && <Badge variant="success">Aprovado</Badge>}
                    {['5', '6', '7'].includes(dados.statusTransacao) && <Badge variant="danger">Cancelado</Badge>}
                </td>
                <td>
                    Solicitado {moment(dados.dataCadastro).calendar().toLowerCase()}
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        { isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1 d-flex align-items-center'}>
                            {['1', '2'].includes(dados.statusTransacao) && <Badge variant="info" className={'mr-1'}>Aberto</Badge>}
                            {['3', '4'].includes(dados.statusTransacao) && <Badge variant="success" className={'mr-1'}>Aprovado</Badge>}
                            {['5', '6', '7'].includes(dados.statusTransacao) && <Badge variant="danger" className={'mr-1'}>Cancelado</Badge>}
                            <div>
                                <span>
                                    Solicitado {moment(dados.dataCadastro).calendar().toLowerCase()}
                                </span>
                            </div>
                        </div>
                        <div className={'text-nowrap text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}