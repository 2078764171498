import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Card, Row, Col, Form, Button, Modal, Spinner, Alert, InputGroup, FormControl} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import InputDataCombo from './../../Componentes/InputDataCombo';
import InputValorReal from './../../Componentes/InputValorReal';
import moment from 'moment';

// retorna conteudo
export default function ModalDataPagamento({
    aoFechar,
    aoSalvar,
    idUsuario
}) {

    let idUsuarioParaAlterar = idUsuario
    let momentoAgora = moment().format("YYYY-MM-DD")
    let [novoValor, alterarNovoValor] = useState('0');
    let [novaData, alterarNovaData] = useState(momentoAgora);
    let [metodoDePagamento, alterarMetodoPagamento] = useState(6);

    //variavel para o select
    const tipoDePagamento = [
        {value:1, label: 'Cartão de crédito'},
        {value:2, label: 'Boleto'},
        {value:3, label: 'Cartão de débito'},
        {value:4, label: 'Saldo PagSeguro'},
        {value:5, label: 'Depósito em conta'},
        {value:6, label: 'Outros'}
    ];


    return <>

        <Modal
            className={['modal-personalizado'].join(' ')}
            size="md"
            show={true}
            aria-labelledby="contained-modal-title-center"
            centered
            onHide={() => {
                aoFechar();
            }}
            backdrop={true}
        >
            <Modal.Header
                closeButton
                onHide={() => {
                    aoFechar();
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                        Nova Transação
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Row className="pt-2">
                    <Form.Group as={Col} >
                        <InputValorReal
                            onChange={valorAlterar => {
                               alterarNovoValor(valorAlterar);
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <InputDataCombo
                            data={momentoAgora}
                            onChange={(data) => {
                                alterarNovaData(data);
                            }}
                        />
                    </Form.Group>
                </Row>

                <Select
                    options={tipoDePagamento}
                    placeholder='Tipo de Pagamento'
                    classNamePrefix={'select'}
                    onChange={(e) => {
                        // altera os filtros selecionados
                        alterarMetodoPagamento(e.value);       
                    }}
                    isDisabled={false}
                />
                   
                
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => { aoFechar(); }}
                >
                    Fechar
                    </Button>
                <Button
                    variant="primary"
                    onClick={() => { 
                        aoSalvar({idUsuarioParaAlterar, novaData, novoValor, metodoDePagamento});
                        aoFechar();
                    }} 
                >
                    Salvar
                    </Button>
            </Modal.Footer>
        </Modal>
    </>
    
}
