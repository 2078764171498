import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Card, Row, Col, Form, Button, Modal, Spinner, Alert, InputGroup, FormControl} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import InputDataCombo from './../../Componentes/InputDataCombo';
import InputValorReal from './../../Componentes/InputValorReal';
import moment from 'moment';

// retorna conteudo
export default function ModalDataPagamento({
    dadosUsuario,
    aoFechar,
    aoSalvar
}) {    

    const [ checkAutoResgate, alterarCheckAutoResgate ] = useState(false);
    const [ checkPrimeirosSocorros, alterarCheckPrimeirosSocorros ] = useState(false);
    const [ id, alterarId ] = useState(null);
    
    
    useEffect(() => {
        if(dadosUsuario){
            alterarId(dadosUsuario.id)
            alterarCheckAutoResgate(dadosUsuario.possuiCursoAutoResgate === 'S' ? true : false );
            alterarCheckPrimeirosSocorros(dadosUsuario.possuiCursoPrimeirosSocorros === 'S' ? true : false)
        }        
    }, [])
 

    return <>

        <Modal
            className={['modal-personalizado'].join('')}   
            show={true}
            centered
            onHide={() => {
                aoFechar();
            }}
            backdrop={true}
        >
            <Modal.Header
                closeButton
                onHide={() => {
                    aoFechar();
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                    Informar se o usuário possui algum curso
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
                <Form.Check 
                    key={0}
                    inline
                    checked={checkAutoResgate}
                    id={"check-auto-resgate"}
                    type='checkbox'
                    label={'Auto Resgate'}
                    size='lg'
                    onChange={() => {
                        alterarCheckAutoResgate(!checkAutoResgate);
                    }}
                />
                
                <Form.Check 
                    key={1}
                    inline
                    checked={checkPrimeirosSocorros}
                    id={"check-primeiros-socorros"}
                    type='checkbox'
                    label={'Primeiros Socorros'}
                    onChange={() => {
                        alterarCheckPrimeirosSocorros(!checkPrimeirosSocorros)
                    }}
                />
                
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => { aoFechar(); }}
                >
                    Fechar
                </Button>
                <Button
                    variant="primary"
                    onClick={() => { 
                        aoSalvar({ id, possuiCursoAutoResgate: checkAutoResgate === true ? 'S' : 'N', possuiCursoPrimeirosSocorros: checkPrimeirosSocorros === true ? 'S' : 'N' });
                        aoFechar();
                    }} 
                >
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    
}
