import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { faCircle, faEnvelope, faExclamationTriangle, faIdBadge, faLock, faDollarSign, faLifeRing, faChalkboardTeacher, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// componente
export default function Registro({
    dados,
    clicouEnviarEmail,
    clicouEditar,
    clicouPermissoes,
    clicouVerDataPagamento,
    clicouVerCursosUsuario,
    clicouMudarCursos
}){

    // // estados
    const { configuracaoReducer } = useSelector(state => state);
    let mesesExpirar = dados.dataExpiraCarteira ? moment(dados.dataExpiraCarteira).diff(moment(), "month") : null;
    let expirados = mesesExpirar < 0;
    const naoPossuiCarteira = dados.possuiCarteira === "N";
    const expiradoOuSemCarteira = expirados || naoPossuiCarteira == true

    // retorna opções
    let retornarOpcoes = () => {        
        return <>       


            <BotaoTabela  
                icone={faLifeRing} 
                onClick={clicouMudarCursos}
            >      
                <span>Alterar se possui curso de auto resgate ou curso de primeiros socorros</span>
            </BotaoTabela>    

            { expiradoOuSemCarteira &&  
                <BotaoTabela  
                    icone={faDollarSign} 
                    onClick={clicouVerDataPagamento}
                >      
                    <span>Adicionar nova transação</span>
                </BotaoTabela>
            }
        
            { configuracaoReducer.permitirAlterarConfiguracaoUsuario === 'S' &&
                <BotaoTabela 
                    onClick={clicouPermissoes}
                    icone={faLock}
                >
                    <span>Permissões</span>
                </BotaoTabela>
            }

            <BotaoTabela 
                onClick={clicouEditar}
                icone={faIdBadge}
            >
                <span>Ver Cadastro</span>
            </BotaoTabela>

            {/* <BotaoTabela
                onClick={clicouVerCursosUsuario}
                icone={faChalkboardTeacher}
            >                
                <span>Ver Cursos do Usuário</span>
            </BotaoTabela>  */}

        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                onClick={clicouEditar}
            >
                <td className={['text-nowrap estreito font-weight-bold', dados.cadastroAtivo === 'S' ? 'text-info' : 'text-warning'].join(' ')}>
                    {dados.id}
                </td>
                <td>
                    {dados.dataExpiraCarteira && <Badge className="mr-1" variant={mesesExpirar < 0 ? 'danger' : 'warning'}>
                        {mesesExpirar == 0 && <>Esse mês</>}
                        {(mesesExpirar > 0 || mesesExpirar < 0) && <>{mesesExpirar} {Math.abs(mesesExpirar) > 1 ? 'meses' : 'mês'}</>}
                    </Badge>}
                    {dados.nome}
                </td>
               
                <td className={'text-right text-nowrap estreito'}>
                

                    {/* <BotaoTabela
                        icone={dados.possuiCursoAutoResgate === 'N' ? faTimesCircle : faCheckCircle}
                        color={dados.possuiCursoAutoResgate === 'N' ? 'gray' : 'green'}
                    >
                        <span>{dados.possuiCursoAutoResgate === 'N' ? 'Não possui curso de auto resgate' : 'Possui curso de auto resgate'}</span>
                    </BotaoTabela>

                    <BotaoTabela
                        icone={dados.possuiCursoPrimeirosSocorros === 'N' ? faTimesCircle : faCheckCircle}
                        color={dados.possuiCursoPrimeirosSocorros === 'N' ? 'gray' : 'green'}
                    >
                        <span>{dados.possuiCursoPrimeirosSocorros === 'N' ? 'Não possui curso de primeiros socorros' : 'Possui curso de primeiros socorros'}</span>
                    </BotaoTabela>   */}

                </td>

                <td className={'text-right text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={() => {
                    clicouEditar()
                }}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.dataExpiraCarteira && <Badge className="mr-1" variant={mesesExpirar < 0 ? 'danger' : 'warning'}>
                                        {mesesExpirar == 0 && <>Esse mês</>}
                                        {(mesesExpirar > 0 || mesesExpirar < 0) && <>{mesesExpirar} {Math.abs(mesesExpirar) > 1 ? 'meses' : 'mês'}</>}
                                    </Badge>}
                                    {dados.nome}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}
