import React, { useState, useEffect, useContext } from 'react';
import './PainelSidebar.scss';

// componentes
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagemSidebar from './sidebar-6.jpg';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Card } from 'react-bootstrap';

// icones
import LogoMenuAbertaIcone from './../../../icones/svg/logoMenu.png';
import LogoIcone from './../../../icones/svg/logo.png';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

// componente
function PainelSidebar({
    className,
    menuEncolhido,
    encolherMenu,
    logo,
    links
}){

    

    // propriedades
    return <div className={["painel-sidebar rounded", className, isMobile ? 'mobile' : ''].join(' ')}>
        <div className="itens">
            <div className="logo">
                <Link 
                    to='/painel' 
                    className="texto-simples"
                    onClick={() => {
                        if(isMobile){
                            encolherMenu(true);
                        }
                    }}
                >
                    {menuEncolhido ? <img height={40} src={LogoIcone}/> : <><img height={40} src={LogoMenuAbertaIcone}/></> }
                </Link>
            </div>
            <ul className="nav">
                { links
                .filter(link => {
                    if(link.habilitar === false){
                        return false;
                    }
                    return true;
                })
                .map((link, a) => {

                    let Icone = () => <FontAwesomeIcon className="icone" icon={link.icone} style={{color: link.color}} />;
                    if(link.svg){
                        Icone = link.svg;
                    }else if(link.img){
                        Icone = () => <div className="icone"><img src={link.img} height="20"/></div>;
                    }

                    return <li key={a} className="nav-item">
                        <Link 
                            to={link.url} 
                            className="nav-link"
                            onClick={() => {
                                if(isMobile){
                                    encolherMenu(true);
                                }
                            }}
                        >
                            <Icone fill="#666666" className="icone" />
                            
                            <p>{link.descricao}</p>
                        </Link >
                    </li>
                })}
            </ul>
            <div className="alternar-menu" onClick={encolherMenu}>
                {
                    menuEncolhido ?
                    <span className="seta">
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    :
                    <>
                        <span>Fechar</span>
                        <span className="seta">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                    </>
                }
            </div>
        </div>
        {/* <div 
            className="itens-fundo" 
            style={{
                // backgroundImage: `url(${imagemSidebar})`
            }}
        ></div> */}
    </div>
}

// retorno
export default PainelSidebar;