import styled from 'styled-components';

export const TransacaoAbertoComponent = styled.div.attrs({
    className: 'centro-container mb-3'
})`

    .titulo-card{
        font-size: clamp(15px, 1.4rem, 5vw);
    }

`