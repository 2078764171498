import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

// contexto
import { AssociarContexto } from './../AssociarContexto';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BandeiraComponent } from './components';
import { Card, Row, Col, Form, Badge, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Estados from './Estados';
import Bandeiras from './Bandeiras';

// componente
export default function FormularioDebito({

}){

    // estados
    const { dados, alterarDados, dadosCartao, dadosComprador, alterarDadosComprador, alterarDadosCartao, alterarEtapa, erros, errosPagamento, instituicaoFinanceira, alterarInstituicaoFinanceira } = useContext(AssociarContexto);
    const [carregandoHash, alterarCarregandoHash] = useState(false);
    const [erroHash, alterarErroHash] = useState(false);
    const [carregandoBandeira, alterarCarregandoBandeira] = useState(false);
    const [dadosBandeira, alterarDadosBandeira] = useState(null);

    // referencias
    let cpfInputRef = React.createRef();
    let cartaoRef = React.createRef();
    let telefoneRefInput = React.createRef();
    let cepInputRef = React.createRef();


    // gerar hash de segurança do cartão
    async function gerarHashCartao(){
        alterarCarregandoHash(true);
        alterarErroHash(false);
        console.log('gerando hash cartão');
        
            // faz a requisição para criar token para o cartão
            window.PagSeguroDirectPayment.createCardToken({
                cardNumber: dadosCartao.numero,
                brand: dadosCartao.bandeira,
                cvv: dadosCartao.cvv,
                expirationMonth: dadosCartao.mes,
                expirationYear: dadosCartao.ano,
                success: retorno => {

                    // salva o token do cartão
                    dadosCartao.tokenCartao = retorno.card.token;
                    console.log('hash gerada',  retorno.card.token);
                },
                error: retorno => {
                    alterarErroHash(true);
                },
                complete: retorno => {
                    alterarCarregandoHash(false);
                }
            });

        

    }

    // busca cep
    async function buscarCep(cepBuscar){
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDadosComprador({...dadosComprador, 
                    cidade: retorno.data.localidade,
                    estado: retorno.data.uf,
                    endereco: retorno.data.logradouro,
                    bairro: retorno.data.bairro,
                    cep: cepBuscar
                });
            });

        }
    }

    // gera hash do cartão
    useEffect(() => {

        // os dados necessários para criar token
        if( dadosCartao.tokenCartao === null &&
            dadosCartao.numero.length > 15 &&
            dadosCartao.mes.length > 1 &&
            dadosCartao.ano.length > 3 &&
            dadosCartao.cvv.length > 2 &&
            dadosCartao.bandeira !== null
        ){
            gerarHashCartao();
        }

    }, [dadosCartao]);

    useEffect(() => {
    }, [instituicaoFinanceira])

    // carrega bandeira do cartão
    useEffect(() => {

        if(dadosCartao.numero.length < 6){
            dadosCartao.bandeira = null;
            dadosCartao.tokenCartao = null;
            alterarDadosCartao({...dadosCartao});
            alterarDadosBandeira(null);
        }
        else if(dadosCartao.numero.length >= 6 && dadosBandeira === null){

            // carrega
            alterarCarregandoBandeira(true);
            
            // faz a requisição
            window.PagSeguroDirectPayment.getBrand({
                cardBin: dadosCartao.numero,
                success: retorno => {
                    dadosCartao.bandeira = retorno.brand.name;
                    alterarDadosCartao({...dadosCartao});
                    alterarDadosBandeira(retorno.brand);
                },
                error: retorno => {
                    
                },
                complete: retorno => {
                    alterarCarregandoBandeira(false);
                }
            });

        }

    }, [dadosCartao.numero]);
    
    return <>

        <div>
            <Form.Group>
                <Form.Label>Nome <small>(Extamente como está no cartão)</small>*</Form.Label>
                <Form.Control 
                    value={dadosComprador.nome}
                    onChange={e => {
                        dadosComprador.nome = e.target.value;
                        alterarDadosComprador({...dadosComprador});
                    }}
                    isInvalid={(errosPagamento.nomeCartao) ? true : false}
                />
                { errosPagamento.nomeCartao &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.nomeCartao}</Form.Control.Feedback>
                }
            </Form.Group>

            <Form.Group>
                <Form.Label>CPF*</Form.Label>
                <MaskedInput
                    className={[
                        "form-control",
                        (errosPagamento.cpfCnpjCartao) ? 'is-invalid' : ''
                    ].join(' ')}
                    mask="111.111.111-11"
                    ref={cpfInputRef}
                    value={dadosComprador.cpfCnpj}
                    onChange={(e) => {
                        dadosComprador.cpfCnpj = cpfInputRef.current.mask.getRawValue().split('_').join('');
                        alterarDadosComprador({...dadosComprador});
                    }}
                />
                { errosPagamento.cpfCnpjCartao &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.cpfCnpjCartao}</Form.Control.Feedback>
                }

            </Form.Group>


            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <span className="mr-2">Numero do cartão de débito*</span>
                        </Form.Label>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <MaskedInput
                                    className={[
                                        "form-control",
                                        (errosPagamento.tokenCartao) ? 'is-invalid' : ''
                                    ].join(' ')}
                                    mask="1111-1111-1111-1111"
                                    ref={cartaoRef}
                                    value={dadosCartao.numero}
                                    onChange={(e) => {
                                        let valorInput = cartaoRef.current.mask.getRawValue().split('_').join('');
                                        dadosCartao.numero = valorInput;
                                        alterarDadosCartao({...dadosCartao});
                                    }}
                                />
                                { errosPagamento.tokenCartao &&
                                    <Form.Control.Feedback type="invalid">{errosPagamento.tokenCartao}</Form.Control.Feedback>
                                }

                            </div>
                            <BandeiraComponent>
                                { (carregandoBandeira || carregandoHash) &&
                                    <FontAwesomeIcon className="icone mx-2" pulse icon={faSpinner} />
                                }
                                { erroHash &&
                                    <>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip>
                                                Não foi possível gerar o token para o seu cartão, verifique se os dados estão corretos!
                                            </Tooltip>}
                                        >
                                            <FontAwesomeIcon 
                                                className="icone text-danger" 
                                                icon={faExclamationTriangle}
                                            />
                                        </OverlayTrigger>
                                    </>
                                }
                                {dadosBandeira && 
                                    <img alt='' src={`https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/${dadosBandeira.name}.png`} />
                                }
                            </BandeiraComponent>

                        </div>

                          

                    </Form.Group>    
                </Col>
                <Col>
                    <Form.Label>Instituição Financeira*</Form.Label>
                        <Bandeiras
                            onChange={(e) => {
                                alterarInstituicaoFinanceira(e.value);                      
                            }}
                        />
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={2}>
                    <Form.Group>
                        <Form.Label>Mês</Form.Label> 
                        <Form.Control 
                            placeholder='00'
                            maxLength="2"
                            value={dadosCartao.mes}
                            onChange={(e) => {
                                if(e.target.value > 12){
                                    e.target.value = 12
                                };
                                dadosCartao.mes = e.target.value;
                                alterarDadosCartao({...dadosCartao});
                            }} 
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Ano*</Form.Label> 
                        <Form.Control 
                            placeholder='0000'
                            maxLength="4"
                            value={dadosCartao.ano}
                            onChange={(e) => {
                                dadosCartao.ano = e.target.value;
                                alterarDadosCartao({...dadosCartao});
                            }} 
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                
                </Col>
                <Col md={5}>
                    <Form.Group>
                        <Form.Label>Código de segurança*</Form.Label> 
                        <Form.Control 
                            placeholder='000'
                            maxLength="3"
                            value={dadosCartao.cvv}
                            onChange={(e) => {
                                dadosCartao.cvv = e.target.value;
                                alterarDadosCartao({...dadosCartao});
                            }}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
            
            <hr />


            <Form.Group>
                <Form.Label title='Telefone'>Telefone</Form.Label>
                <MaskedInput 
                    className={[
                        "form-control",
                        (errosPagamento.telefone) ? 'is-invalid' : ''
                    ].join(' ')}
                    mask="(11) 11111-1111"
                    value={dadosComprador.telefone}
                    ref={telefoneRefInput}
                    onChange={(e) => {
                        alterarDadosComprador({...dadosComprador, telefone: telefoneRefInput.current.mask.getRawValue().split('_').join('')});
                    }}
                />
                { errosPagamento.telefone &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.telefone}</Form.Control.Feedback>
                }
            </Form.Group>



            {/* A PARTIR DAQUI */}

            
            <Form.Row>
                <Col md='3'>
                    <Form.Label>Cep</Form.Label>
                    <MaskedInput
                        className={[
                            "form-control",
                            (errosPagamento.cepCartao) ? 'is-invalid' : ''
                        ].join(' ')}
                        mask="11111-111"
                        value={dadosComprador.cep}
                        ref={cepInputRef}
                        onChange={(e) => {
                            let cepNovo = cepInputRef.current.mask.getRawValue().split('_').join('');
                            buscarCep(cepNovo);
                            alterarDadosComprador({...dadosComprador, cep: cepNovo});
                            
                        }}
                    />
                    { errosPagamento.cepCartao &&
                        <Form.Control.Feedback type="invalid">{errosPagamento.cepCartao}</Form.Control.Feedback>
                    }
                </Col>
                <Col md='7'>
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control 
                        value={dadosComprador.cidade}
                        onChange={e => {
                            alterarDadosComprador({...dadosComprador, cidade: e.target.value});
                        }}
                        isInvalid={errosPagamento.cidadeCartao ? true : false}
                    />
                    { errosPagamento.cidadeCartao &&
                        <Form.Control.Feedback type="invalid">{errosPagamento.cidadeCartao}</Form.Control.Feedback>
                    }
                </Col>
                <Col>
                    <Form.Label>Estado</Form.Label>
                    <Estados 
                        value={dadosComprador.estado} 
                        onChange={e => {
                            alterarDadosComprador({...dadosComprador, estado: e.target.value});
                        }}
                    />
                </Col>
            </Form.Row>
            <Form.Row className='mt-3'>
                <Col md='9'>
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control 
                        value={dadosComprador.endereco} 
                        onChange={e => {
                            alterarDadosComprador({...dadosComprador, endereco: e.target.value});
                        }}
                        isInvalid={errosPagamento.enderecoNumeroCartao ? true : false}
                    />
                    { errosPagamento.enderecoNumeroCartao &&
                        <Form.Control.Feedback type="invalid">{errosPagamento.enderecoNumeroCartao}</Form.Control.Feedback>
                    }
                </Col>
                <Col>
                    <Form.Label>Numero</Form.Label>
                    <Form.Control 
                        value={dadosComprador.numero} 
                        onChange={e => {
                            alterarDadosComprador({...dadosComprador, numero: e.target.value});
                        }}
                    />
                </Col>
            </Form.Row>
            
            <Form.Group>
                <Form.Label>Bairro</Form.Label>
                <Form.Control 
                    value={dadosComprador.bairro} 
                    onChange={e => {
                        alterarDadosComprador({...dadosComprador, bairro: e.target.value});
                    }}
                    isInvalid={errosPagamento.bairroCartao ? true : false}
                />
                { errosPagamento.bairroCartao &&
                    <Form.Control.Feedback type="invalid">{errosPagamento.bairroCartao}</Form.Control.Feedback>
                }
            </Form.Group>

          
            {/* ATE AQUI */}


        </div>
                
        <p className={'d-flex justify-content-between mt-3'}>
            <Button
                variant={'primary'}
                onClick={() => {
                    alterarEtapa('metodo')
                }}
            >
                Voltar
            </Button>
            <Button
                variant={'success'}
                onClick={() => {
                    alterarEtapa('finalizar')
                }}
                disabled={erroHash}
            >
                Finalizar
            </Button>
        </p>
    </>
}