import * as React from "react";

const SvgBombeiro1Icone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <style>{".bombeiro-1-icone_svg__st4{fill:#e60000}"}</style>
      <g id="bombeiro-1-icone_svg__contato">
        <path
          className="bombeiro-1-icone_svg__st4"
          d="M113.6 78.8l-15 23.8-20.5-13.1v24.4H49.9V90.5l-19.4 13.1-15.7-23.4 23.5-15.8-23.9-15.2 15-23.8 20.5 13.1V14.1h28.2v23.4l19.4-13.1 15.7 23.4-23.5 15.8z"
        />
        <path
          className="bombeiro-1-icone_svg__st4"
          d="M102 63.4l21.5-14.5-23.3-34.6-15.4 10.5V6.2H43.2v20l-17-10.8L4 50.7l21.9 13.9L4.4 79.1l23.3 34.6 15.5-10.5v18.7h41.6v-20l16.9 10.7L124 77.3l-22-13.9zm-1.3 44.8l-19-12.1v22.6H46.3V97.3l-17.8 12L8.7 79.9l23-15.4L8.3 49.7l18.9-29.9 19.1 12.1V9.3h35.4v21.4l17.8-12 19.8 29.4-23 15.4 23.4 14.8-19 29.9z"
        />
        <path
          className="bombeiro-1-icone_svg__st4"
          d="M113.6 78.8l-15 23.8-20.5-13.1v24.4H49.9V90.5l-19.4 13.1-15.7-23.4 23.5-15.8-23.9-15.2 15-23.8 20.5 13.1V14.1h28.2v23.4l19.4-13.1 15.7 23.4-23.5 15.8z"
        />
        <path
          className="bombeiro-1-icone_svg__st4"
          d="M113.6 78.8l-15 23.8-20.5-13.1v24.4H49.9V90.5l-19.4 13.1-15.7-23.4 23.5-15.8-23.9-15.2 15-23.8 20.5 13.1V14.1h28.2v23.4l19.4-13.1 15.7 23.4-23.5 15.8z"
        />
        <g>
          <path
            d="M76.9 43.4c-3.2.4-7.7 2.8-11.4 5.5v-1.6c1.7-.6 3-2.3 3-4.3 0-2.5-2-4.6-4.6-4.6s-4.6 2-4.6 4.6c0 2 1.2 3.6 3 4.3v1.6c-3.7-2.7-8.2-5.1-11.4-5.5C40.8 42 38.5 46.7 32.5 48c3.9 4.2 7 3.5 12.4 1-.9 1.9-3.2 3.2-5.4 4 4.8 2.3 9.2-.6 11-2.3-.4 1.7-1.9 3.2-3.7 4.4 5.5.7 11.1-.1 15.5-2.2l.1 13.5c-.2 0-.4-.1-.6-.1-3.9-.6-7.6-1.2-8.1-3.3-.2-.7-.1-1.4.4-1.9.2-.3.5-.5.9-.7.9-.4 1.8.3 2.9.2 1.4-.1 2-.9 1.8-2-.2-1.3-1.6-1.5-2.3-1.5-2.2 0-4.3.9-5.4 2.4-.9 1.2-1.2 2.6-.9 4.2.7 2.9 3.5 4.1 6.8 4.8-2 1.1-3.5 2.7-3.7 5.3-.3 3.7 1.9 5.5 4.6 6.6-1.7.8-3 2-3.2 4.1-.3 3 1.5 4.5 3.7 5.5-1.5.8-2.5 1.9-2.5 3.8h2.7c0-1.1 1.2-1.6 3.2-2.2v2.2H65v-2.2c2 .5 3.2 1.1 3.2 2.2h2.7c0-1.9-1-3-2.5-3.8 2.3-.9 4.1-2.5 3.7-5.5-.2-2.1-1.5-3.3-3.2-4.1 2.7-1 4.9-2.8 4.6-6.6-.2-2.7-1.7-4.3-3.7-5.3 3.3-.7 6.1-1.9 6.8-4.8.4-1.6.1-3-.9-4.2-1.2-1.5-3.2-2.4-5.4-2.4-.7 0-2.1.2-2.3 1.5-.1 1.1.4 1.9 1.8 2 1.1.1 2-.7 2.9-.2.4.2.7.4.9.7.4.5.5 1.1.4 1.9-.5 2.2-4.2 2.7-8.1 3.3-.2 0-.4.1-.6.1l.1-13.5c4.4 2.1 10 2.9 15.5 2.2-1.7-1.1-3.2-2.6-3.7-4.4 1.8 1.7 6.2 4.6 11 2.3-2.2-.7-4.5-2.1-5.4-4 5.4 2.5 8.5 3.2 12.4-1-5.9-1.3-8.2-6-18.3-4.6zM57.7 57.6c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5zm-.8 16.2c.2-2.4 2.3-3.5 5.6-4.3l.1 8.8c-4-.8-5.9-1.7-5.7-4.5zm1.4 10.8c.2-1.6 1.8-2.3 4.4-2.9l.1 6.3c-3.2-.8-4.7-1.6-4.5-3.4zm11.9-25.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5c.1.2-.2.5-.5.5zm-.6 25.9c.2 1.9-1.3 2.6-4.4 3.4l.1-6.3c2.6.6 4.1 1.3 4.3 2.9zM71 73.8c.2 2.8-1.6 3.7-5.7 4.6l.1-8.8c3.3.7 5.4 1.8 5.6 4.2z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgBombeiro1Icone;
