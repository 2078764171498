import styled from 'styled-components'

export const ContainerImagemComponente = styled.div.attrs({
    className: 'd-flex align-items-center'
})`

    height: 60vh;
    user-select: none;

`;

export const ImagemComponente = styled.div.attrs({
    className: 'flex-grow-1 text-center'
})`
    pointer-events: none;

    img{
        max-height: -webkit-fill-available;
        max-width: -webkit-fill-available;
        height: unset;
        width: unset;
    }

`;

export const BotaoAlterarImagem = styled.span.attrs({
    className: ''
})`
    display: flex;
    padding: 1rem;
    border-radius: 100%;
    background: #f2f3f5f0;
    color: #333;
    cursor: pointer;
    margin: calc(-1rem - 10px);
    box-shadow: 0px 0px 2px grey;
    user-select: none;

    &:hover{    
        background: #eceef1;
    }
`;

export const OpcoesModalComponente = styled.div`
    display: flex;
    align-items: center;
    margin: -1rem -1rem 0 -1rem;
    padding: 5px 10px;
    padding-bottom: 0;
    justify-content: flex-end;

    span{
        flex: none;
        position: relative;
        transition: background-color .3s ease;
        border-radius: 50%;
        padding: 8px;
        margin-left: 10px;
        cursor: pointer;
        color: #333;
    }
`