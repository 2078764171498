import React from 'react';
import styles from './index.module.scss';

// componentes
import BotaoTabela from './../../../../Componentes/BotaoTabela';

// elemento
function Registro({
    dados,
    clicouEditar
    // clicouExcluir
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.descricao}</td>
        <td>{dados.titulo}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
            {/* <BotaoTabela 
                onClick={clicouExcluir}
                icone={["fas", "trash"]}
            >
                Excluir
            </BotaoTabela> */}
        </td>
    </tr>

}

export default Registro;