import React from 'react';
import styles from './index.module.scss';
import { Route } from "react-router-dom";

// componentes
import Consultar from './Consultar';
import ModalCadastroCurso from './ModalCadastroCurso';

// escreve o email
export default function Cursos(){
      
    return <>

        <div className={[styles.divCentro, ''].join(' ')}>
            <Consultar />

            {/* rotas */}
            <Route exact path={[`/painel/cursos/cadastrar`, `/painel/cursos/editar/:id`, `/painel/cursos/visualizar/:id`]}>
                <ModalCadastroCurso />
            </Route>
        </div>
    </>
}
