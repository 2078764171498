import * as React from "react";

const SvgMeusDadosIcone = (props) => {
  return (
    <svg
      id="meus-dados-icone_svg__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M111 104.9H17c-4.7 0-8.5-3.8-8.5-8.5V31.6c0-4.7 3.8-8.5 8.5-8.5h94c4.7 0 8.5 3.8 8.5 8.5v64.8c0 4.7-3.8 8.5-8.5 8.5zM17 26.1c-3 0-5.5 2.5-5.5 5.5v64.8c0 3 2.5 5.5 5.5 5.5h94c3 0 5.5-2.5 5.5-5.5V31.6c0-3-2.5-5.5-5.5-5.5H17z" />
          <path d="M64.1 94.4h-.3c-.6 0-1-.4-1-1V34.6c0-.6.4-1 1-1h.3c.6 0 1 .4 1 1v58.8c0 .5-.4 1-1 1zM108.1 41.5H73.9c-1.1 0-2 .9-2 2v.5c0 1.1.9 2 2 2h34.3c1.1 0 2-.9 2-2v-.5c-.1-1.1-1-2-2.1-2zM108.1 55H73.9c-1.1 0-2 .9-2 2v.5c0 1.1.9 2 2 2h34.3c1.1 0 2-.9 2-2V57c-.1-1.1-1-2-2.1-2zM108.1 68.5H73.9c-1.1 0-2 .9-2 2v.5c0 1.1.9 2 2 2h34.3c1.1 0 2-.9 2-2v-.5c-.1-1.1-1-2-2.1-2zM108.1 82h-27c-1.1 0-2 .9-2 2v.5c0 1.1.9 2 2 2h27c1.1 0 2-.9 2-2V84c0-1.1-.9-2-2-2z" />
          <g>
            <path d="M36.6 85.1c-.8 0-1.5-.1-2.3-.2l-12.6-2.1c-.7-.1-1.3-.7-1.3-1.5v-8.4C20.4 66 26 60.3 33 60.3h7.1c6.9 0 12.6 5.6 12.6 12.6v8.4c0 .7-.5 1.4-1.3 1.5l-12.6 2.1c-.6.1-1.4.2-2.2.2zm-13.1-5L34.8 82c1.2.2 2.4.2 3.6 0l11.3-1.9v-7.2c0-5.3-4.3-9.6-9.6-9.6H33c-5.3 0-9.6 4.3-9.6 9.6v7.2z" />
          </g>
          <g>
            <path d="M36.6 56.4c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6zm0-16.3c-3.7 0-6.6 3-6.6 6.6s3 6.6 6.6 6.6 6.6-3 6.6-6.6-2.9-6.6-6.6-6.6z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default SvgMeusDadosIcone;
