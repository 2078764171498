import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import './PainelNavbar.scss';
import styles from './index.module.scss';

// componentes
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown, ButtonToolbar, OverlayTrigger, Tooltip, Button, Overlay } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

// contextos
import { PainelContexto } from './../PainelContexto';

// navbar
function PainelNavbar({
    menuEncolhido,
    encolherMenu
}){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    const { loginReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    
    // clicou para fazer logoff
    function deslogar(e){
        
        // desloga
        dispatch({ type: 'loginReducer/FAZER_LOGGOF' });
    }
    

    return <>
        <nav className="painel-navbar navbar navbar-expand-lg ">

            <div className="container-fluid">
                {/* <p className="navbar-brand"> Usuários / Cadastrar / </p> */}
                {/* <a className={[styles.navbarBrand, 'navbar-brand'].join(' ')} href="/">{login.dadosUsuario.login}</a> */}
                
                <div className={["collapse navbar-collapse justify-content-end", isMobile ? 'mobile' : '' ].join(' ')}>
                    <ul className="nav navbar-nav mr-auto p-1 d-flex">
                        {
                            isMobile &&
                            <>
                                <ItemMenuTipo2
                                    onClick={() => {
                                        encolherMenu(!menuEncolhido);
                                    }}
                                    icone={["fas", menuEncolhido ? 'ellipsis-v' : 'bars']}
                                    label={menuEncolhido ? 'Expandir' : 'Minimizar'}
                                />
                            </> 
                        }

                        { isBrowser && 
                            <>

                                <ItemMenuTipo1
                                    link={'/painel'}
                                    icone={["fas", 'home']}
                                    label={'Início'}
                                />

                                <ItemMenuTipo1
                                    link={'/painel/configuracao'}
                                    icone={["fas", 'cog']}
                                    label={'Configurações'}
                                />
                            </>
                        }
                    </ul>
                    <ul className={["navbar-nav ml-auto"].join(' ')}>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#pablo">
                                <span className="no-icon">Account</span>
                            </a>
                        </li> */}
                        <BrowserView>

                        </BrowserView>


                        <div className={[styles.navbarBrand, 'pl-2'].join(' ')}>
                            { isBrowser &&
                                <div className={styles.info}>
                                    <span className={[styles.nome].join(' ')}>
                                        Olá, {loginReducer.dadosUsuario.nome.split(' ')[0]}
                                    </span>
                                </div>
                            }
                            { loginReducer.dadosUsuario.foto ?
                                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${loginReducer.dadosUsuario.foto}`} className={styles.foto} />
                                :
                                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
                            }

                            {/* <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon> */}

                            
                        </div>
                        <li className="nav-item">
                            <button className={[styles.botaoSair, ""].join('')} onClick={deslogar}>
                                {/* <span className="no-icon">Sair</span> */}
                                <FontAwesomeIcon className={[styles.icone, 'text-primary'].join(' ')} icon={["fas", "power-off"]}></FontAwesomeIcon>
                            </button>
                        </li>
                        {/* <ItemMenuTipo1
                            link={'/painel/configuracoes'}
                            icone={["fas", "cog"]}
                            label={'Configurações'}
                        /> */}
                    </ul>
                </div>
            </div>
            
        </nav>
    </>
}

function ItemMenuTipo1({
    link,
    label,
    icone,
    className=''
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <BrowserView>
            <OverlayTrigger
                placement={'bottom'}
                trigger={['hover', 'focus']}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Link className={[styles.navLink, 'nav-link p-2 ', className].join(' ')} data-toggle="dropdown" to={link}>
                    <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
                </Link>
            </OverlayTrigger>
        </BrowserView>

        <MobileView>
            <Link className={[styles.navLink, 'nav-link p-2', className].join(' ')} data-toggle="dropdown" to={link}>
                <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
            </Link>
        </MobileView>

    </li>

}

function ItemMenuTipo2({
    onClick,
    label,
    icone,
    className=''
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <BrowserView>
            <OverlayTrigger
                placement={'bottom'}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Button className={[styles.linkButton,'nav-link p-2', className].join(' ')} onClick={onClick}>
                    <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon>
                </Button> 
            </OverlayTrigger>
        </BrowserView>
        
        <MobileView>
            <Button className={[styles.linkButton,'nav-link p-2', className].join(' ')} onClick={onClick}>
                <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon>
            </Button> 
        </MobileView>
    </li>

}





// pega as propriedades do painel
const mapStateToProps = function(state){
    let estado = {
        painel: state.painel,
        login: state.login
    };
    return estado
}

// retorna
export default connect(mapStateToProps)(PainelNavbar);