import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import queryString  from 'query-string';

// componentes
import { Card, Table, Button, Badge, InputGroup, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import Paginacao from './../../../Componentes/Paginacao';
import ParametrosConsulta from './ParametrosConsulta';
import ModalTrocaCursos from './../../ModalTrocaCursos';
import DataPagamento from './../../ModalDataPagamento';

// import ModalExcluir from './ModalExcluir';

// contextos    
import { PainelContexto } from './../../PainelContexto';

import AdicionarArquivosImagem from './../../../../icones/ilustracoes/adicionar-arquivos2-imagem.svg';
import { faCircle, faMailBulk } from '@fortawesome/free-solid-svg-icons';

export default function Consultar(){
    

    // estados
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();
    const location = useLocation();
    const [ dados, alterarDados ] = useState(null);
    const [ mostrarDataPagamento, alterarMostrarDataPagamento ] = useState(false)
    const [ mostrarMudarCursos, alterarMostrarMudarCursos ] = useState(false)
    let { path, url } = useRouteMatch();
    let [ carregando, alterarCarregando ] = useState(false);
    let [ totalRegistros, atualizarTotalRegistros ] = useState(0);
    let [ parametros, alterarParametros ] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        cadastroAtivo: [],
        dataVencimentoInicio: null,
        dataVencimentoFim: null
    }, queryString.parse(location.search, {arrayFormat: 'bracket'}));
    let [ consulta, alterarConsulta ] = useState([]);
    let [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    
    // inicializa
    useEffect(() => {

        // codifica a query para salvar no navegador
        let encodeQuery = queryString.stringify(parametros, {arrayFormat: 'bracket'});

        // atualizar url
        history.replace({
            pathname: path,
            search: "?"+encodeQuery
        });
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    // faz consulta
    async function fazerConsulta() {

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try {

            let { data } = await axios.get('/usuario', {
                params: parametros
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e) {
            console.log(e)
        }finally {
            alterarCarregando(false);
        }
    }

    //mandando as informacoes pro back-end
    async function salvarNovaCarteira(dados) {
        alterarCarregando(true);

        const informacoesAoBack = {
            idUsuario: dados.idUsuarioParaAlterar,
            totalBruto: dados.novoValor,
            totalLiquido: dados.novoValor,
            metodoPagamento: dados.metodoDePagamento,
            dataExpira: dados.novaData,
        }
        try {

            await axios.post(`/transacao/salvarTransacaoCarteira`, {
                dados: informacoesAoBack
            });
            fazerConsulta();

        } catch(e){
            console.log(e)
        } finally{
            alterarCarregando(false);
        }
    }

    // muda informações do usuário sobre se possui curso de auto resgate
    async function mudarPossuiAutoResgate(dados) {
        
        // mostra carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            await axios.post(`/usuario`, { dados: { 
                id: dados.id,
                possuiCursoAutoResgate: dados.possuiCursoAutoResgate,
                possuiCursoPrimeirosSocorros: dados.possuiCursoPrimeirosSocorros
            }});
            fazerConsulta();

        } catch(e) {
            console.log(e.response)
        } finally {
            // esconde carregamento
            alterarCarregando(false)
        }

    }

    // retorna registro
    let retornaRegistros = registro => {
        
        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                alterarDados={alterarDados}
                // clicouEnviarEmail={() => {
                //     history.push(`${path}/enviar-email/${registro.dados.id}`);
                // }}
                clicouEditar={() => {
                    history.push(`/painel/usuario/${registro.dados.id}`)
                }}
                clicouPermissoes={() => {
                    history.push(`/painel/configuracao/${registro.dados.id}`)
                }}
                clicouVerDataPagamento={() => {
                    alterarDados(registro.dados);
                    alterarMostrarDataPagamento(true)
                }}
                clicouMudarCursos={() => {
                    alterarDados(registro.dados)
                    alterarMostrarMudarCursos(true)
                }}
                clicouVerCursosUsuario={() => {
                    history.push(`/painel/cursos/visualizar/${registro.dados.id}`)
                }}
            />   
        )
    }

    return <>
        <div className={['centro-container'].join(' ')}>
            <Card className="painel-consulta border-0 flex-grow-1" >
                <Card.Header>
                    <div className={'d-flex align-items-center'}>
                        <Card.Title className={[styles.tituloCard, 'flex-grow-1 m-0'].join(' ')}>Usuários</Card.Title>
                        {/* <div>
                            <OverlayTrigger 
                                overlay={<Tooltip>Envia email para todos os usuários presentes na consulta atual!</Tooltip>}
                                placement='left'
                            >
                                <Button
                                    variant='warning'
                                    size='sm'
                                    onClick={() => {
                                        history.push('/painel/usuarios/emails-massa', {
                                            parametros
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon className="icone" icon={faMailBulk} />
                                    <span>Emails em massa</span>
                                </Button>
                            </OverlayTrigger>
                        </div> */}
                    </div>
                </Card.Header>
                <Card.Body className={['d-flex flex-column'].join(' ')}>
                    <ParametrosConsulta 
                        parametros={parametros}
                        alterarParametros={alterarParametros}
                        mostrarFiltros={mostrarFiltros}
                        alterarMostrarFiltros={alterarMostrarFiltros}
                    />

                    
                    <div 
                        className={[styles.corpo, 'my-3 d-flex flex-grow-1'].join(' ')}
                    >
                        {
                            carregando ?

                            <div className={['d-flex justify-content-center align-items-center flex-grow-1']}>
                                <Spinner animation="border"  style={{width: '3rem', height: '3rem', color: '#6f6f6f'}} />
                            </div>

                            :
                            <>
                                {
                                    totalRegistros > 0 ?
                                    <table className="table table-hover tabela clicavel">
                                        {/* <thead>
                                            <tr>
                                                <th></th>
                                                <th>Projeto</th>
                                                <th></th>
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            { retornaRegistros() }
                                        </tbody>
                                    </table>
                                    :
                                    <div className={['d-flex justify-content-center align-items-center flex-grow-1']}>
                                        <img
                                            className={['mb-3'].join(' ')} style={{
                                            width: 'clamp(30px, 35vw, 300px)'
                                        }} src={AdicionarArquivosImagem}/>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className={'d-flex justify-content-end my-1'} style={{fontSize: '0.9rem'}}>
                        <span className={'mx-1'}>
                            <FontAwesomeIcon className={'text-info'} icon={faCircle} /> Ativo
                        </span>
                        <span className={'mx-1'}>
                            <FontAwesomeIcon className={'text-warning'} icon={faCircle} /> Não ativo
                        </span>
                    </div>
                    <Paginacao
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={parseInt(totalRegistros)}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                </Card.Body>
            </Card>
        </div>

        {(dados && mostrarDataPagamento) && 
            <DataPagamento 
                idUsuario = {dados.id}
                aoFechar={() => {
                    alterarDados(null);
                    alterarMostrarDataPagamento(false)
                }} 
                aoSalvar={(novosValores) => {
                    salvarNovaCarteira(novosValores)
                }}
            />
        }

        {(dados && mostrarMudarCursos) && 
            <ModalTrocaCursos 
                aoFechar={() => {
                    alterarDados(null)
                    alterarMostrarMudarCursos(false)
                }}
                dadosUsuario={dados}
                aoSalvar={(novosValores) => {
                    mudarPossuiAutoResgate(novosValores)
                }}
            />
        }

    </>
}