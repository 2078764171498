import React, { useState, useEffect, useContext } from 'react';

// contexto
import { AssociarContexto } from './../AssociarContexto';

// components
import { MetodoComponent } from './components';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faCreditCard } from '@fortawesome/free-solid-svg-icons';


// metodo de pagamento
export default function MetodoPagamento(){

    // estados
    const { dados, alterarDados, alterarEtapa } = useContext(AssociarContexto);

    return <>
        <div className="d-sm-flex align-items-strech">
{/*            
            <MetodoComponent
                selecionado={dados.metodoPagamento === 2}
            >
                <Card 
                    className="bg-secondary text-white border-0 m-2"
                    onClick={() => {
                        dados.metodoPagamento = 2;
                        alterarDados({...dados});
                        alterarEtapa('formularioBoleto');
                    }}
                >
                    <Card.Body>
                        <div className="descricao text-light">
                            <FontAwesomeIcon icon={faFileInvoice}  size="3x" />
                            <span className="mt-2">Boleto</span>
                        </div>
                    </Card.Body>
                </Card>
            </MetodoComponent>
            <MetodoComponent
                selecionado={dados.metodoPagamento === 3}
            >
                <Card 
                    className="bg-secondary text-white border-0 m-2"
                    onClick={() => {
                        dados.metodoPagamento = 3;
                        alterarDados({...dados});
                        alterarEtapa('formularioDebito');
                    }}
                >
                    <Card.Body>
                        <div className="descricao text-light">
                            <FontAwesomeIcon icon={faCreditCard}  size="3x" />
                            <span className="mt-2">Débito Online</span>
                        </div>
                    </Card.Body>
                </Card>
            </MetodoComponent>
            <MetodoComponent
                selecionado={dados.metodoPagamento === 1}
            >
                <Card 
                    className="bg-secondary text-white border-0 m-2"
                    onClick={() => {
                        dados.metodoPagamento = 1;
                        alterarDados({...dados});
                        alterarEtapa('formularioBoleto');
                    }}
                >
                    <Card.Body>
                        <div className="descricao text-light">
                            <FontAwesomeIcon icon={faCreditCard} size="3x" />
                            <span className="mt-2">Cartão de crédito</span>
                        </div>
                    </Card.Body>
                </Card>
            </MetodoComponent> */}
            <MetodoComponent
                selecionado={dados.metodoPagamento === 4}
            >
                <Card 
                    className="bg-secondary text-white border-0 m-2"
                    onClick={() => {
                        dados.metodoPagamento = 4;
                        alterarDados({...dados});
                        alterarEtapa('finalizar'); // pix
                    }}
                >
                    <Card.Body>
                        <div className="descricao text-light">
                            <FontAwesomeIcon icon={faCreditCard}  size="3x" />
                            <span className="mt-2">PIX</span>
                        </div>
                    </Card.Body>
                </Card>
            </MetodoComponent>
        </div>
    
           
        {/* <div>
            <p className={'d-flex justify-content-between mt-3'}>
                { dados.metodoPagamento == 1 && 
                    <Button
                        variant={'success'}
                        onClick={() => {
                            alterarEtapa('formularioCartao')
                        }}
                    >
                        Dados do cartão
                    </Button>
                }
                { dados.metodoPagamento == 2 && 
                    <Button
                        variant={'success'}
                        onClick={() => {
                            alterarEtapa('formularioBoleto')
                        }}
                    >
                        Dados do boleto
                    </Button>
                }
            </p>
        </div> */}
    </>
}