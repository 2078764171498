import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';


// componentes
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// contexto
import { ConfiguracaoContexto } from './../../ConfiguracaoContexto';


// configurações de email
export default function ServidorEmail(){

    // estados
    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const { configuracoes, idUsuario } = useContext(ConfiguracaoContexto);
    const [salvando, alterarSalvando] = useState(null);
    // const [valorNovo, alterarValorNovo] = useState(configuracoes['servidorEmail']);
    const [dadosSalvar, alterarDadosSalvar] = useState(Object.assign({
        host: '',
        usuario: '',
        senha: '',
        porta: '',
        protocolo: ''
    }, configuracoes['servidorEmail']));
    

    // pega os dados de configuração do email
    // console.log(valorNovo);


    // salva código de configuracao
    async function salvar(campoSalvando){
        alterarSalvando(campoSalvando);

        try{

            // faz a requisição
            let { data } = await axios.post(`/configuracaoUsuarios/${idUsuario}/servidorEmail`, {valor: dadosSalvar});


        }catch({response}){

        }finally{
            alterarSalvando(null); 
        }

    }



    return <>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Host
            </p>
            <div
                className='ml-2 d-flex align-items-center w-50'
            >
                <Form.Control 
                    placeholder='Ex.: smtp.gmail.com'
                    value={dadosSalvar.host}
                    onChange={e => {
                        alterarDadosSalvar({...dadosSalvar, host: e.target.value});
                    }}
                    onBlur={() => {salvar('host')}}
                    size="sm"
                    className='mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando === 'host' && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Usuário
            </p>
            <div
                className='ml-2 d-flex align-items-center w-50'
            >
                <Form.Control 
                    value={dadosSalvar.usuario}
                    onChange={e => {
                        alterarDadosSalvar({...dadosSalvar, usuario: e.target.value});
                    }}
                    onBlur={() => {salvar('usuario')}}
                    size="sm"
                    className='mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando === 'usuario' && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Senha
            </p>
            <div
                className='ml-2 d-flex align-items-center w-50'
            >
                <Form.Control 
                    value={dadosSalvar.senha}
                    onChange={e => {
                        alterarDadosSalvar({...dadosSalvar, senha: e.target.value});
                    }}
                    onBlur={() => {salvar('senha')}}
                    size="sm"
                    className='mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando === 'senha' && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Protocolo
            </p>
            <div
                className='ml-2 d-flex align-items-center'
            >
                <Form.Control 
                    placeholder='Ex.: ssl'
                    value={dadosSalvar.protocolo}
                    onChange={e => {
                        alterarDadosSalvar({...dadosSalvar, protocolo: e.target.value});
                    }}
                    onBlur={() => {salvar('protocolo')}}
                    size="sm"
                    className='mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando === 'protocolo' && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                Porta
            </p>
            <div
                className='ml-2 d-flex align-items-center'
            >
                <Form.Control 
                    placeholder='Ex.: 465'
                    value={dadosSalvar.porta}
                    onChange={e => {
                        alterarDadosSalvar({...dadosSalvar, porta: e.target.value});
                    }}
                    onBlur={() => {salvar('porta')}}
                    size="sm"
                    className='mr-2'
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando === 'porta' && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
    </>


}