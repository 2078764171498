import styled, { css }  from "styled-components";
import { isMobile } from "react-device-detect";

const containerGridMobile = css`
	display: block;
`;

const containerFlex = css`
	display: inline-flex;
	flex-wrap: wrap;
    /* justify-content: space-evenly; */
`;

const containerInline = css`
	display: flex;
	max-width: 100%;
    overflow-x: auto;
    background: #ffffff87;
    border-radius: 0 0 2px 2px;
	margin: 0;
	
	${
		!isMobile &&
		css`
			&::-webkit-scrollbar-thumb{
				background-color: rgba(0,0,0,.2);
			}

			&::-webkit-scrollbar-track{    
				background: hsla(0,0%,100%,.1);
			}

			&::-webkit-scrollbar{
				width: 6px !important;
				height: 6px !important;
			}
		`
	}
`;

export const Container = styled.div`
	margin-top: 5px;
	width: 100%;

	${containerGridMobile}
	${props => props.flex && containerFlex}
	${props => props.inline && containerInline}
	${props => (!props.flex || isMobile) && css`

		.imagem-container{
			max-width: unset !important;
			flex-grow: unset !important;

			.imagem-preview{
				flex-grow: unset !important;
			}
		}
	`}

	div {
		display: flex;
		align-items: center;
		color: #444;
		position: relative;

		div{
			margin: 3px 5px;
		}

		&.imagem-container{
			max-width: 25%;
    		flex-grow: 1;

			.imagem-preview{
    			flex-grow: 1;
			}
		}
	}
`;

export const FileInfo = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;

	&.ver-mais{
		span{
			position: absolute;
			pointer-events: none;
			font-size: 20px;
			font-weight: 500;
			text-align: center;
			left: 0;
			right: 0;
		}

		div{
			box-shadow: inset 0px 0px 11px 7px #6b6b6b7d;
		}
	}

	&:hover{
		.remover-imagem{
			display: block !important;
		}
	}

`;

export const Info = styled.div`
	display: flex;
	flex-direction: ${isMobile ? 'row' : 'column'};
	flex-grow: 1;
	justify-content: space-between;
    flex-direction: row;

	strong{
		font-size: 12px;
		max-width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		flex-grow: 1;
	}

	span {
		font-size: 9px;
		color: #999;
		text-align: center;
	}
`;

export const RemoverImagemTexto = styled.span`
	font-size: 9px;
	color: #999;
	margin-top: 2px;
	text-align: center;

	button {
		border: 0;
		background: transparent;
		color: #e57878;
		margin-left: 5px;
		cursor: pointer;
	}
`;

export const RemoverImagem = styled.div.attrs({
	className: `remover-imagem`
})`
	display: ${isMobile ? 'block' : 'none'} !important;
	position: absolute !important;
	top: 0;
	right: 0;
    padding: 2px 0px;
    margin: -4px -4px !important;
    font-size: 10px;
    border-radius: 100%;
    background: #eceef1f5;
    color: #333;
    cursor: pointer;
    box-shadow: 0px 0px 2px #bbbbbbd4;
    -webkit-user-select: none;
    user-select: none;

	button{
		border: 0;
		box-shadow: none;
		background: none;
		outline: none;
	}

	&:hover{
    	background: #eceef1e0;
	}
`;

export const Preview = styled.div.attrs({
	className: 'imagem-preview'
})`
	width: ${props => props.width || (isMobile ? '36px' : '50px')};
	height: ${props => props.height || (isMobile ? '36px' : '50px')};
	border-radius: 5px;
	background-image: url(${props => props.src});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	cursor: ${props => props.cursor || 'auto'};
	margin: 0;
    box-shadow: inset -2px -2px 9px 1px #d6d6d657;
	${props => props.selecionada && css`
    	border: 2px solid #d4d4d4;
	`}

	&:hover{
		${props => props.hover && css`
			box-shadow: inset 0px 0px 11px 7px #d4d3d37d !important;
		`}
	}

`;

export const BarraProgresso = styled.div.attrs({
	// className: 'd-flex'
})`
	display: flex;
    position: absolute !important;
    right: 0;
    bottom: 0;
    left: 0;

	.progress{
		flex-grow: 1;
		background: #f7f7f8db;
		height: 10px;
		box-shadow: 0px 0px 2px #e4e4e4;

		.progress-bar{
			width: 20%;
			height: -webkit-fill-available;
			margin: 0;
			font-size: 8px;
		}
	}
`;

export const InfoUpload = styled.div`
	background: ${props => props.erro ? '#e57878' : '#28a745'};
	border-radius: 25px;
    position: absolute !important;
	bottom: 0;
	right: 0;
    color: white !important;
    padding: 5px;
    font-size: 12px;
	box-shadow: 0px 0px 1px grey;
	margin: 0 8px !important;
    width: 22px;

	svg{
		margin: auto;
	}
`;