import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componentes
import { CarteiraComponent } from './components';
import { Campo, CodigoQrCode, TextoDestaque } from './../components';
import { mascaraTelefone } from './../../../Funcoes';
import { Row, Col, Badge } from 'react-bootstrap';

// escreve o email
export default function ComponenteCarteira({
    dados,
    dadosUsuario,
    leitura = false
}){

    const { id } = useParams();
    const { configuracaoReducer, loginReducer } = useSelector(state => state);
    const idUsuario = id || loginReducer.dadosUsuario.id;

    return <>
        <CarteiraComponent leitura={leitura}>
            <div className={'foto'}>
                {
                    dados.foto ?
                    <img alt="Foto de perfil" src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados.foto}`} />
                    :
                    <FontAwesomeIcon  icon={["fas", "user-circle"]}></FontAwesomeIcon>
                }
            </div>
            {
                !leitura &&
                <CodigoQrCode>
                    <img alt="QRCode" src={loginReducer.qrcode} height="200" width="200"/>
                </CodigoQrCode>
            }
            <TextoDestaque style={{borderBottom: '1px solid #eeeeee'}}>{dados.nome}</TextoDestaque>
            <Row>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>CPF: </label>
                    <Campo>{dados.cpf}</Campo>
                </Col>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Cel.:</label>
                    <Campo>{dados.celular ? mascaraTelefone(dados.celular) : <i>Sem celular</i>}</Campo>
                </Col>
            </Row>
            <Row>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Nascimento:</label>
                    <Campo>{dados.dataNascimento ? moment(dados.dataNascimento).format('DD/MM/YYYY') : <>-</>}</Campo>
                </Col>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Sangue:</label>
                    <Campo>{dados.tipoSanguineo ? dados.tipoSanguineo : <>-</>}</Campo>
                </Col>
            </Row>
            <Row>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Nome Responsável:</label>
                    <Campo>{dados.pessoaAvisar || <i>-</i>}</Campo>
                </Col>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Tel:</label>
                    <Campo>{dados.telefonePessoaAvisar ? mascaraTelefone(dados.telefonePessoaAvisar) : <i>Sem telefone</i>}</Campo>
                </Col>
            </Row>
            <Row>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Cidade:</label>
                    <Campo>{dados.cidade || <i>-</i>}</Campo>
                </Col>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Estado:</label>
                    <Campo>{dados.estado || <i>-</i>}</Campo>
                </Col>
            </Row>
            <Row>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Endereço:</label>
                    <Campo>{dados.endereco || <i>-</i>}</Campo>
                </Col>
                <Col className={'mb-1'} sm={12} md={6}>
                    <label>Profissão:</label>
                    <Campo>{dados.profissao || <i>-</i>}</Campo>
                </Col>
            </Row>
            <Row>
            <Col className={'mb-1'} sm={12} md={12}>
                    <label>E-mail:</label>
                    <Campo>{dados.email || <i>-</i>}</Campo>
            </Col>
            </Row>
            
            {(dadosUsuario.possuiCursoAutoResgate === "S" || dadosUsuario.possuiCursoPrimeirosSocorros === "S") &&
                <>
                    {/* <hr /> */}
                    <Row>
                        <Col className={'mb-1'} sm={12} md={12}>
                            <label>Cursos:</label>
                            {/* <Campo>{dados.email || <i>-</i>}</Campo> */}
                            {/* <div className="d-flex justify-content-between"> */}
                                {dadosUsuario.possuiCursoAutoResgate === "S" && <Badge variant="success" className="mx-2">Auto Resgate</Badge>}
                                {dadosUsuario.possuiCursoPrimeirosSocorros === "S" && <Badge variant="success">Primeiros Socorros</Badge>}
                            {/* </div> */}
                        </Col>
                    </Row>
                    
                </>
            }
            
            {
                !leitura &&
                <Row>
                    <Col className="d-flex flex-row-reverse">
                        <span 
                            className="info-rodape" 
                            style={{
                                fontSize: '0.8em',
                                color: 'gray',
                                fontStyle: 'italic'
                            }}
                        >Válida até {moment(dados.dataExpira).format('DD/MM/YYYY')}</span>
                    </Col>
                </Row>
            }
        </CarteiraComponent>
    </>
}