import React from 'react';
import { Form } from 'react-bootstrap';

// componente checkbox
function ListaCheckbox({
    itens,
    label,
    value,
    // atualizar: atualizar,
    onChange
}){

    // retorna somente os checados
    function retornarArray(){
        let filtrar = itens.filter(item => {
            if(item.selecionado){
                return true;
            }
            return false;
        });
        return filtrar.map(item => {
            return item.id;
        });
    }

    // value.includes(item.id)
    itens.forEach(item => {
        if(value.includes(item.id)){
            item.selecionado = true;
        }else{
            item.selecionado = false;
        }
    });

    return <>
        {itens.map((item, a) => {

            return <Form.Check 
                key={a}
                inline
                checked={item.selecionado || value.includes(item.id)}
                id={`parametro-${label}-${item.id}`}
                type='checkbox'
                label={item.label}
                onChange={e => {
                    item.selecionado = e.target.checked;
                    onChange(retornarArray());
                }}
            />
        })}
    </>
}

export default ListaCheckbox;