import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Script from 'react-load-script';

// contexto
import { AssociarContexto } from './AssociarContexto';
import { AssociarComponent } from './components';

// componentes
import MetodoPagamento from './MetodoPagamento';
import FormularioBoleto from './FormularioBoleto';
import FormularioCartao from './FormularioCartao';
import FormularioDebito from './FormularioDebito';
import FormularioPix from './FormularioPix';
import FinalizarPagamento from './FinalizarPagamento';
import { Card, Alert, Button } from 'react-bootstrap';

// associa-se
export default function Associar(){

    // estados
    const history = useHistory();
    const { loginReducer } = useSelector(state => state);
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        metodoPagamento: 2, // 1 - cartão credito, 2 - boleto, 3 - cartão de débito
    });
    const [valor, alterarValor] = useState(null);
    const [etapa, alterarEtapa] = useState('metodo'); // metodo -> boleto/cartão dados -> pagode
    // const [pagseguroCarregado, alterarPagseguroCarregado] = useState(null);
    const [instituicaoFinanceira, alterarInstituicaoFinanceira] = useState(null);
    
    // dados de finalização
    const [dadosComprador, alterarDadosComprador] = useState({
        nome: loginReducer.dadosUsuario.nome,
        dataNascimento: loginReducer.dadosUsuario.dataNascimento || '',
        cpfCnpj: loginReducer.dadosUsuario.cpf || '',
        cep: loginReducer.dadosUsuario.cep || '',
        endereco: loginReducer.dadosUsuario.endereco || '',
        numero: loginReducer.dadosUsuario.numero || '',
        bairro: loginReducer.dadosUsuario.bairro || '',
        cidade: loginReducer.dadosUsuario.cidade || '',
        estado: loginReducer.dadosUsuario.estado || 'AC',
        telefone: loginReducer.dadosUsuario.celular || '',
        complemento: loginReducer.dadosUsuario.complemento || ''
    });
    const [dadosCartao, alterarDadosCartao] = useState({
        numero: '',
        mes: '',
        ano: '',
        cvv: '',
        bandeira: null,
        valorTotal: '',
        tokenCartao: null,
    });
    const [erros, alterarErros] = useState({});
    const [errosPagamento, alterarErrosPagamento] = useState({});

    // inicializa
    useEffect(() => {
        consultarValorVenda();
    }, []);

    // carrega configurações importantes para venda
    async function consultarValorVenda(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoUsuarios/${loginReducer.dadosUsuario.id}/valorAssociar`);
            alterarValor(parseFloat(data));
            alterarCarregando(false);

        }catch(e){}
    }

    // salva, finaliza pagamento
    async function salvar(){
        alterarErros({});
        alterarErrosPagamento({});
        alterarSalvando(true);

        try{
            // faz a requisição
            let { data } = await axios.post(`/transacao`, {
                hashPagseguro: window.PagSeguroDirectPayment.getSenderHash(), 
                //metodo pagamento
                dados,
                dadosCartao,
                //dados do usuario
                dadosComprador,
                instituicaoFinanceira
            });
            
            // se for boleto, carrega boleto na página
            if(dados.metodoPagamento === 2){
                window.open(data.linkBoleto, '_blank');
            }

            // finalizado
            alterarEtapa('finalizado');

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                    if(response.data.pagamento){
                        alterarErrosPagamento(response.data.pagamento);
                    }

                    // retorna a etapa anterior
                    switch(dados.metodoPagamento){
                        case 1: alterarEtapa('formularioCartao'); break;
                        case 2: alterarEtapa('formularioBoleto'); break;
                        case 3: alterarEtapa('formularioDebito'); break;
                    }

                }
            }
        }finally{
            alterarSalvando(false);
        }
    }

    // url pagseguro
    let urlPagseguroAmbiente = "https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";
    if(process.env.REACT_APP_AMBIENTE_PAGSEGURO === 'production'){
        urlPagseguroAmbiente = "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";
    }

    // carregando
    if(carregando || valor === null){
        return <></>
    }


    return <AssociarContexto.Provider value={{
        valor, erros, errosPagamento,
        dados, alterarDados, 
        dadosCartao, alterarDadosCartao,
        etapa, alterarEtapa,
        dadosComprador, alterarDadosComprador,
        salvar, salvando,
        instituicaoFinanceira, alterarInstituicaoFinanceira
    }}>



        {/* APRESENTA TRANSAÇÕES EM ABERTO AQUI */}
        {/* {etapa === 'metodo' &&
            <TransacaoAberto />
        } */}

        <AssociarComponent>
            <Card className="painel-consulta border-0 flex-grow-1" >
                <Card.Header>
                    <div className={'d-flex align-items-center'}>
                        <Card.Title className='titulo-card flex-grow-1 m-0'>Associar-se</Card.Title>
                    </div>
                </Card.Header>
                <Card.Body className='d-flex flex-column'>
                    
                    <div className='pagamento'>
                        
                        { etapa === 'metodo' &&
                            <MetodoPagamento />
                        }

                        { etapa === 'formularioBoleto' &&
                            <FormularioBoleto />
                        }

                        { etapa === 'formularioCartao' && 
                            <FormularioCartao />
                        }

                        { etapa === 'formularioDebito' && 
                            <FormularioDebito />
                        }

                        { etapa === 'formularioPix' && 
                            <FormularioPix />
                        }

                        { etapa === 'finalizar' &&
                            <FinalizarPagamento />
                        }

                        { etapa === 'finalizado' &&
                            <>
                                <Alert variant="success" className="border-0">
                                    Compra finalizada com sucesso, aguarde a confirmação de pagamento!
                                </Alert>
                                <div className="text-center">
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            history.push(`/painel/carteira`);
                                        }}
                                    >
                                        <span>Aguardar!</span>
                                    </Button>
                                </div>
                            </>
                        }

                    </div>
                </Card.Body>
            </Card>

            { Object.values(erros).length > 0 && 
                <Alert variant="danger" className="border-0 text-center mt-3">
                    Não foi possível completar, verifique se todos os campos foram preenchidos corretamente!
                </Alert>
            }
        </AssociarComponent>
        
    </AssociarContexto.Provider>
}