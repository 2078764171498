import React from 'react';
import Select from 'react-select';

function Bandeiras({
    value,
    onChange,
    name,
    size='md'
}){

    const bancos = [
        {value:"Bradesco", label: 'Bradesco'},
        {value:"BancoDoBrasil", label: 'Banco do Brasil'},
        {value:"Itau", label: 'Itaú'},
        {value:"Banrisul", label: 'Banrisul'},
    ];

    return <Select
        onChange={onChange}
        options={bancos}
        placeholder="Selecione uns dos bancos"
        classNamePrefix={'select'}
        isDisable={false}
    >
      
    </Select>
}

export default Bandeiras;