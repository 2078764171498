import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { Card, Button, Spinner, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Paginacao from './../../../Componentes/Paginacao';
import Confirmar from './../../../Componentes/Confirmar';

import Registro from './Registro';
import ParametrosConsulta from './ParametrosConsulta';

// import ModalExcluir from './ModalExcluir';

// contextos    
import { PainelContexto } from './../../PainelContexto';

import ProjetosImagem from './../../../../icones/ilustracoes/projetos3-imagem.svg';

export default function Consultar({
    clicouEditar,
    atualizarLista,
    alterarAtualizarLista
}){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();
    const [carregando, alterarCarregando] = useState(false);
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        descricao: '',
        dataInicioRealizado: null,
        dataFimRealizado: null
    });
    const [consulta, alterarConsulta] = useState([]);
    const [mostrarFiltros, alterarMostrarFiltros] = useState(false);
    const [confirmar, alterarConfirmar] = useState(null);

    // inicializa
    useEffect(() => {
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    useEffect(() => {

        // se retornar a essa rota
        if(location.pathname === "/painel/projetos"){
            fazerConsulta();
        }
    }, [location.pathname]);

    // inicializa
    useEffect(() => {
        
        if(atualizarLista){
            // faz a consulta
            fazerConsulta();
            alterarAtualizarLista(false)
        }

    }, [atualizarLista]);

    // faz consulta
    async function fazerConsulta(){

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try{
            let { data } = await axios.get('/projetos', {
                params: parametros
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    
    function clicouExcluir(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Deletar projeto',
            texto: `Tem certeza que deseja deletar este projeto?`,
            textoBotao: 'Deletar',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            aoConfirmar: () => {
                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // clicou excluir
    async function deletar(dados){
        // carregando
        alterarCarregando(true);
        
        // faz a requisição
        try{

            let { data } = await axios.delete(`/projetos/${dados.id}`);

        }catch(e){

        }finally{
            alterarCarregando(false);

            // atualiza registros
            fazerConsulta();
        }
    }

    // retorna registro
    let retornaRegistros = registro => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                clicouEditar={() => {
                    
                    // verifica se edição ou cadastro
                    history.push(`${path}/editar/${registro.dados.id}`);

                }}
                clicouExcluir={() => {
                    clicouExcluir(registro)
                }}
                clicouDireto={() => {
                    // verifica se edição ou cadastro
                    history.push(`${path}/visualizar/${registro.dados.id}`);
                }}
            />
        )
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Card className="painel-consulta border-0 flex-grow-1" >
            <Card.Header>
                <div className={'d-flex align-items-center'}>
                    <Card.Title className={[styles.tituloCard, 'flex-grow-1 m-0'].join(' ')}>Projetos</Card.Title>
                    <div>
                        <OverlayTrigger 
                            overlay={<Tooltip>Adicionar novo projeto.</Tooltip>}
                            placement='bottom'
                        >
                            <Button
                                variant='success'
                                size='sm'
                                onClick={() => {
                                    // console.log('ABRIR MODAL DE CADASTRO')
                                    // clicouEditar(null)
                                    history.push(`${path}/cadastrar`);
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                                <span>Novo</span>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className={['d-flex flex-column'].join(' ')}>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                />

                
                <div 
                    className={[styles.corpo, 'my-3 d-flex flex-grow-1 flex-column'].join(' ')}
                >
                    {
                        carregando ?

                        <div className={['d-flex justify-content-center align-items-center flex-grow-1']}>
                            <Spinner animation="border"  style={{width: '3rem', height: '3rem', color: '#6f6f6f'}} />
                        </div>

                        :
                        <>
                            {
                                totalRegistros > 0 ?
                                (    
                                    isBrowser ? 
                                    <table className="table table-hover tabela clicavel">
                                        <tbody>
                                            { retornaRegistros() }
                                        </tbody>
                                    </table>
                                    :
                                    <>
                                        { retornaRegistros() }
                                    </>
                                )
                                :
                                <div className={['d-flex justify-content-center align-items-center flex-grow-1']}>
                                    <img
                                        className={['mb-3'].join(' ')} style={{
                                        width: 'clamp(30px, 35vw, 300px)'
                                    }} src={ProjetosImagem}/>
                                </div>
                            }
                        </>
                    }
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={parseInt(totalRegistros)}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </Card.Body>
        </Card>
    </>
}