import React, { useEffect, useState } from 'react';
import { Route } from "react-router-dom";
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componentes
import { ContainerCentro } from '../../ComponentesEstilo';
import { converterReal } from './../../Funcoes';
import { CentroComponent, Campo, CarteiraComponent, CodigoQrCode, TextoDestaque } from './components';
import { Card, Row, Col, Alert, Button } from 'react-bootstrap';

import TransacaoHistorico from './TransacaoHistorico';
import ComponenteCarteira from './ComponenteCarteira';

// escreve o email
export default function Carteira(){
      
    const { configuracaoReducer, loginReducer } = useSelector(state => state);

    const { id } = useParams();
    const idUsuario = id || loginReducer.dadosUsuario.id;
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);
    const [dadosUsuario, alterarDadosUsuario] = useState(null);
    const [historicoAberto, alterarHistoricoAberto] = useState(false);
    
    // carregar os dados da carteira
    useEffect(() => {
        consultar();
    }, []);


    // carrega as configurações
    async function consultar(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/carteira`);
            
            // altera dados
            alterarDadosUsuario(data.usuario);
            alterarDados(data.dados);

        }catch({}){}

        // finaliza
        alterarCarregando(false);

    }

    if(carregando){
        return <>
            <CentroComponent>
                Carregando
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </CentroComponent>
        </>
    }

    return <>
        <CentroComponent>
            {
                dados.associacaoAtiva === 'S' &&
                    <>
                        {
                            moment(dados.dataExpira).diff(moment(), "days") <= configuracaoReducer.tempoAvisoRenovacaoCarteira && 
                            <Row>
                                <Col>
                                    <Link to={'/painel/carteira/associar'}>
                                        <Alert className={['my-1 mb-2'].join(' ')} variant="danger">
                                            <p className={['mb-0'].join(' ')}>Sua carteira está perto do vencimento ({moment(dados.dataExpira).format('DD/MM/YYYY')}). Clique aqui para renovar.</p>
                                        </Alert>
                                    </Link>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col className={'d-flex mb-2'}>
                                <ComponenteCarteira 
                                    dados={dados}
                                    dadosUsuario={dadosUsuario}
                                />
                            </Col>
                        </Row>
                    </> 
            }
            <Row>
                {
                    dados.transacaoEmAguardo === 'S' &&
                    <Col className={'d-flex mb-2'}>
                        <Card
                            className={['border-0 shadow-sm flex-grow-1'].join(' ')}
                        >
                            <Card.Body>
                                <TextoDestaque>Aguardando pagamento</TextoDestaque>
                                Pedido: {moment(dados.dataCadastroTransacao).format('DD/MM/YYYY')}<br/>
                                Valor: R$ {converterReal((dados.totalTransacao).toString())}
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {
                    dados.associacaoAtiva !== 'S' &&
                    <>
                        {/* <Col className={'d-flex mb-2'}>
                            <Card
                                className={['border-0 shadow-sm flex-grow-1 align-items-center'].join(' ')}
                            >
                                <Card.Body>
                                    <TextoDestaque>
                                        {dados.jaAssociado === 'N' ? `Faça sua primeira carteira!` : `Você não está associado`}
                                    </TextoDestaque>
                                    <Link to={'/painel/carteira/associar'}>
                                        <p>Escolha sua forma de pagamento.</p>   
                                    </Link>
                                </Card.Body>                              
                            </Card>
                        </Col> */}
                    </>
                }
            </Row>
            {
                <Row>
                    <Col>
                        <TransacaoHistorico />
                    </Col>
                </Row>
            }
        </CentroComponent>
    </>
}
