import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import InputData from './../../../../Componentes/InputData';
import IconeBombeiro from './../../../../../icones/svg/Bombeiro1Icone';
import IconeBombeiroDois from './../../../../../icones/svg/Bombeiro2Icone';


// retorna conteudo
export default function Formulario({
    dados={},
    alterarDados,
    funcaoSalvar,
    salvando,
    modo,
    erros,
    alterarErros,
    leitura
}){

    const { loginReducer } = useSelector(state => state);
    const history = useHistory();
    
    // array para o select de tipos sanguíneos
    const tiposSanguineo = [
        {value: 'A-', label: 'A-'},
        {value: 'A+', label: 'A+'},
        {value: 'AB-', label: 'AB+'},
        {value: 'B-', label: 'B-'},
        {value: 'B+', label: 'B+'},
        {value: 'O-', label: 'O-'},
        {value: 'O+', label: 'O+'}
    ]

    // prepara objeto para armazenar referências
    let referencias = {
        cpfInput: React.createRef()
    }

    // busca cep
    function buscarCep(cepBuscar){
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    cidade: retorno.data.localidade,
                    estado: retorno.data.uf,
                    endereco: retorno.data.logradouro,
                    bairro: retorno.data.bairro,
                    cep: cepBuscar
                });
            });

        }
    }

    return <>
        <Row>
            <Col className='mb-4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>{loginReducer.dadosUsuario.id === dados.id ? `Meus dados` : `Cadastro do usuário`}</Card.Title>
                    </Card.Header>
                    <Card.Body className={['formulario'].join(' ')}>
                        <Form.Group>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Nome completo</Form.Label>
                                    <Form.Control 
                                        value={dados.nome || ''}
                                        onChange={e => {
                                            alterarDados({...dados, nome: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.nome) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm={12} md={7}>
                                    <Form.Label>CPF</Form.Label>
                                    {/* <Form.Control 
                                        value={dados.cpf || ''}
                                        onChange={e => {
                                            alterarDados({...dados, cpf: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    /> */}
                                    <MaskedInput 
                                        className={[
                                            "form-control", 
                                            (erros.cpf) && 'is-invalid'
                                        ].join(' ')}
                                        mask="111.111.111-11"
                                        value={dados.cpf}
                                        ref={referencias.cpfInput}
                                        onChange={(e) => {
                                            dados.cpf = referencias.cpfInput.current.mask.getRawValue().split('_').join('');
                                            alterarDados({...dados});
                                        }}
                                    />
                                    {(erros.cpf) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.cpf}</p></Alert>
                                    }
                                </Col>
                                <Col sm={12} md={5}>
                                    <Form.Label>RG</Form.Label>
                                    <Form.Control 
                                        value={dados.rg || ''}
                                        onChange={e => {
                                            alterarDados({...dados, rg: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.rg) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.rg}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col md='4'>
                                    <Form.Label>Nascimento</Form.Label>
                                    <InputData 
                                        data={dados.dataNascimento}
                                        onChange={(data) => {
                                            alterarDados({...dados, dataNascimento: data});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.dataNascimento) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.dataNascimento}</p></Alert>
                                    }
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Label>Órgão Exp.</Form.Label>
                                    <Form.Control 
                                        value={dados.orgaoExp || ''}
                                        onChange={(e) => {
                                            // altera os filtros selecionados
                                            alterarDados({...dados, orgaoExp: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Label>Tipo sanguíneo</Form.Label>
                                    <Select 
                                        className={[styles.select, 'select'].join(' ')}
                                        options={tiposSanguineo}
                                        defaultValue={tiposSanguineo.filter(tipo => dados.tipoSanguineo === tipo.value)[0]}
                                        isMulti={false}
                                        placeholder='Tipo'
                                        classNamePrefix={'select'}
                                        onChange={(e) => {
                                            // altera os filtros selecionados
                                            alterarDados({...dados, tipoSanguineo: e.value});
                                            console.log(e.value);
                                        }}
                                        isDisabled={leitura}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>CEP</Form.Label>
                                    <MaskedInput
                                        className={['form-control'].join(' ')}
                                        mask="11111-111"
                                        value={dados.cep}
                                        ref={referencias.cep = React.useRef()}
                                        onChange={(e) => {
                                            let cepNovo = referencias.cep.current.mask.getRawValue().split('_').join('');
                                            buscarCep(cepNovo);
                                            alterarDados({...dados, cep: cepNovo});
                                            
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.cep) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.cep}</p></Alert>
                                    }
                                </Col>
                                <Col sm={12} md={8}>
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control 
                                        value={dados.endereco || ''}
                                        onChange={e => {
                                            alterarDados({...dados, endereco: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs={9} sm={9} md={6}>
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control 
                                        value={dados.cidade || ''}
                                        onChange={e => {
                                            alterarDados({...dados, cidade: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                                <Col xs={3} sm={3} md={3}>
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Control 
                                        value={dados.estado || ''}
                                        onChange={e => {
                                            alterarDados({...dados, estado: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                                <Col sm={12} md={3}>
                                    <Form.Label>País</Form.Label>
                                    <Form.Control 
                                        value={dados.pais || ''}
                                        onChange={e => {
                                            alterarDados({...dados, pais: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col md='7'>
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control 
                                        value={dados.email || ''}
                                        onChange={e => {
                                            alterarDados({...dados, email: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.email) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                </Col>
                                <Col md='5'>
                                    <Form.Label>Celular</Form.Label>
                                    <MaskedInput 
                                        className="form-control"
                                        mask="(11) 11111-1111"
                                        value={dados.celular}
                                        ref={referencias.celular = React.useRef()}
                                        onChange={(e) => {
                                            alterarDados({...dados, celular: referencias.celular.current.mask.getRawValue().split('_').join('')});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.celular) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.celular}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm={12} md={6}>
                                    <Form.Label>Telefone</Form.Label>
                                    <MaskedInput 
                                        className="form-control"
                                        mask="(11) 1111-11111"
                                        value={dados.telefone}
                                        ref={referencias.telefone = React.useRef()}
                                        onChange={(e) => {
                                            alterarDados({...dados, telefone: referencias.telefone.current.mask.getRawValue().split('_').join('')});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.telefone) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.telefone}</p></Alert>
                                    }
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Label>Telefone Comercial</Form.Label>
                                    <MaskedInput 
                                        className="form-control"
                                        mask="(11) 1111-11111"
                                        value={dados.telefoneComercial}
                                        ref={referencias.telefoneComercial = React.useRef()}
                                        onChange={(e) => {
                                            alterarDados({...dados, telefoneComercial: referencias.telefoneComercial.current.mask.getRawValue().split('_').join('')});
                                        }}
                                        {...{
                                            
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.telefoneComercial) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.telefoneComercial}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                    
                                <Col 
                                    md='7'
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <IconeBombeiro 
                                        className={'pr-2'}
                                        style={{
                                            width: 'clamp(30px, 5vw, 60px)'   
                                        }}    
                                    />  
                                    <div style={{width: '100%'}} className={'text-center'}>     
                                        <Form.Label>Nome Responsável</Form.Label>
                                        <Form.Control 
                                            value={dados.pessoaAvisar || ''}
                                            onChange={e => {
                                                alterarDados({...dados, pessoaAvisar: e.target.value});
                                            }}
                                            readOnly={leitura}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                    </div>    
                                </Col>
                                <Col 
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <IconeBombeiroDois
                                        className={'pr-1 pt-4'}
                                        style={{
                                            width: 'clamp(20px, 5vw, 30px)'

                                        }}    
                                    />  
                                    <div style={{width: '100%'}} className={'text-center'}>     
                                        <Form.Label >Contato de Emergência</Form.Label>
                                        <MaskedInput 
                                            className="form-control"
                                            mask="(11) 1111-11111"
                                            value={dados.telefonePessoaAvisar}
                                            ref={referencias.telefonePessoaAvisar = React.useRef()}
                                            onChange={(e) => {
                                                alterarDados({...dados, telefonePessoaAvisar: referencias.telefonePessoaAvisar.current.mask.getRawValue().split('_').join('')});
                                            }}
                                            readOnly={leitura}
                                        />
                                    </div>
                                    {(erros.telefonePessoaAvisar) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.telefonePessoaAvisar}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Profissão</Form.Label>
                                    <Form.Control 
                                        value={dados.profissao || ''}
                                        onChange={e => {
                                            alterarDados({...dados, profissao: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                                <Col md='4'>
                                    <Form.Label>Data início escalada</Form.Label>
                                    <InputData 
                                        data={dados.dataInicioEscalada}
                                        onChange={(data) => {
                                            alterarDados({...dados, dataInicioEscalada: data});
                                        }}
                                        readOnly={leitura}
                                    />
                                    {(erros.dataInicioEscalada) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.dataInicioEscalada}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Escolaridade</Form.Label>
                                    <Form.Control 
                                        value={dados.escolaridade || ''}
                                        onChange={e => {
                                            alterarDados({...dados, escolaridade: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Motivos de se associar</Form.Label>
                                    <Form.Control 
                                        className={['textarea'].join(' ')}
                                        as="textarea" 
                                        rows="2" 
                                        value={dados.motivoAssociar || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, motivoAssociar: e.target.value});
                                        }}
                                        readOnly={leitura}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        { !leitura &&
            <Row>
                <Col>
                    <SalvarFormulario 
                        salvando={salvando}
                        modo={modo}
                        cancelar={() => {
                            history.push(`/painel`);
                        }}
                        onClick={() => funcaoSalvar(dados)}
                    />
                </Col>
            </Row>
        }
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}