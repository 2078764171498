import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// contexto
import { ConfiguracaoContexto } from './../ConfiguracaoContexto';

// componente para habilitar/desabilitar alguma parâmetro
export default function OpcaoBoleana({
    descricao,
    codigo
}){

    // estados
    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const configuracaoProvider = useContext(ConfiguracaoContexto);
    const [salvando, alterarSalvando] = useState(false);
    const [valorNovo, alterarValorNovo] = useState(configuracaoProvider.configuracoes[codigo]);

    // salva código de configuracao
    async function salvar(valorAlterado){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/configuracaoUsuarios/${configuracaoProvider.idUsuario}/${codigo}`, {valor: valorAlterado});
            alterarValorNovo(data.valor);

            // se a alteração é do usuário atual, já reflete
            if(parseInt(configuracaoProvider.idUsuario) === parseInt(loginReducer.dadosUsuario.id)){
                dispatch({
                    type: 'configuracaoReducer/ATUALIZAR',
                    dados: {[codigo]: data.valor}
                });
            }


        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    return <>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                {descricao}
            </p>
            <div
                className='ml-2 d-flex'
            >
                <Form.Check 
                    type="switch"
                    id={`switch-configuracao-${codigo}`}
                    label={''}
                    checked={valorNovo === 'S' ? true : false}
                    onChange={e => {
                        salvar(e.target.checked ? 'S' : 'N');
                    }}
                    disabled={salvando}
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
    </>

}