import React, { useState, useEffect, useContext } from 'react';
import './../LoginPagina.scss';
import { LoginComponent } from './../components';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Link, useHistory } from "react-router-dom";
import useIsMounted from 'ismounted';
import { Container } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';
import logo from './../../../assets/midiaLocalLogo.png';

// contextos
import { PainelLogarContexto } from './../PainelLogarContexto';

import LogoIcone from './../../../icones/svg/logo2.svg';

function FazerLogin({

}){

    // estados
    let painelLogarContextoDados = useContext(PainelLogarContexto);
    let { dados, alterarDados, fazerLogin, erros, alterarErros, irParaCadastro } = painelLogarContextoDados;

    const history = useHistory();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    let [carregando, alterarCarregando] = useState(false);
    let [lembrar, alterarLembrar] = useState(false);
    let loginReducer = useSelector(state => state.loginReducer);
    
    useEffect(() => {

        if(erros.cadastroAtivo){
            // se não está ativo

            erros.email = "Usuário não está ativo";

            // manda para o associado
            history.push({
                pathname: `/associado`,
                ativar: true
            })
            
        }

    }, [erros])

    // retorna componente
    return <LoginComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
            // style={{
            //     background: `url(${backGroundImage})`,
            //     backgroundSize: `cover`
            // }}
        >
            <div md={3} className="logo">
                <img src={LogoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Bem vindo!</h3>
                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", (erros.email) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Endereço de email" 
                                        required autoFocus
                                        value={dados.email || ''}
                                        onChange={(e) => {
                                            dados.email = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) fazerLogin(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Endereço de email</label>
                                    { erros.email && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                    
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", (erros.senha) ? 'input-erro' : ''].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha || ''}
                                        onChange={(e) => {
                                            dados.senha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) fazerLogin(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {(erros.senha) && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                    }
                                </div>

                                {/* <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                    <label className="custom-control-label" htmlFor="customCheck1">Lembrar</label>
                                </div> */}
                                <button 
                                    onClick={fazerLogin} 
                                    className="botao-entrar btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregando}
                                >
                                    {carregando && 
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    {carregando ? ' Carregando' : 'Entrar' }
                                </button>
                                <div className="d-flex align-items-center justify-content-between">
                                    
                                    <Button
                                        className={['rounded-pill px-4 mr-2 botao-associado'].join(' ')}
                                        size='sm'
                                        onClick={() => {
                                            history.push('/associado');
                                        }}
                                    >
                                        <span>Já sou associado</span>
                                    </Button>
                                    <Button
                                        variant='success'
                                        className='rounded-pill px-4 botao-cadastrar'
                                        size='sm'
                                        onClick={irParaCadastro} // chama função para direcionar para o cadastro
                                    >
                                        <span>Cadastrar</span>
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Link to="/enviarEmail" className="small">Esqueceu a senha?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="desenvolvido" ><p>Desenvolvido por</p> <img src={logo} /></div>
    </LoginComponent>;

}

export default FazerLogin;