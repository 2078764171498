

export default function(state = Object.assign({
    logado: false,
    dadosUsuario: null,
    teste: 1,
    // dadosEntidade: {},
    // // permissao: {},
    // horaLogin: null,
}, JSON.parse(window.localStorage.getItem('login'))), action){

    // ações globais
    switch(action.type){
        case 'loginReducer/FAZER_LOGIN':
            
            let dadosAtualizar = {
                logado: true,
                dadosUsuario: action.dadosUsuario,
                // // permissao: action.permissao,
                // horaLogin: action.horaLogin
            };

            // se passado
            if(typeof action.loginAtivacao !== "undefined"){
                dadosAtualizar.loginAtivacao = action.loginAtivacao;
            }

            window.localStorage.setItem('login', JSON.stringify(dadosAtualizar));
            return Object.assign({}, state, dadosAtualizar);
        case 'loginReducer/ATUALIZAR_DADOS':
            let dadosAtualizarCadastro = {
                dadosUsuario: Object.assign(state.dadosUsuario, action.dadosUsuario),
                qrcode: action.qrcode || state.qrcode
            };
            
            let dadosAtualizados = Object.assign({}, state, dadosAtualizarCadastro);
            window.localStorage.setItem('login', JSON.stringify(dadosAtualizados));
            return dadosAtualizados;
        case 'loginReducer/ATUALIZAR_TEMPO_LOGADO': 
            let dadosAtual = JSON.parse(window.localStorage.getItem('login'));
            dadosAtual.horaLogin = new Date();
            window.localStorage.setItem('login', JSON.stringify(dadosAtual));
            return Object.assign({}, state, dadosAtual);

        case 'loginReducer/FAZER_LOGGOF':
            window.localStorage.removeItem('login');
            return Object.assign({}, state, {
                logado: false,
                dadosUsuario: null,
                // // permissao: {},
                // horaLogin: null
            });
        case 'loginReducer/SOMAR': 

        return Object.assign({}, state, {
            teste: state.teste+1
        });
        default: 
            return state
    }
}