
// icones font awesome
import { library } from '@fortawesome/fontawesome-svg-core';


// far
import { 
    faHeart, faPlusSquare, faMinusSquare
} from '@fortawesome/free-regular-svg-icons'; 


library.add(
    faHeart, faPlusSquare, faMinusSquare
);