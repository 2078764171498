import styled, { css } from "styled-components";

const focado = css`
	border-color: grey;
`;

const dragAtivo = css`
  	border-color: #6ee27f;
`;

const dragRejeitado = css`
  	border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
	border: 1px dashed #ddd;
	border-radius: 4px;
	cursor: pointer;
	outline: 0;

	transition: height 0.2s ease;

	${props => (props.isFocused || props.isFileDialogActive) && focado};
	${props => props.isDragActive && dragAtivo};
	${props => props.isDragReject && dragRejeitado};
`;

const coresMensagem = {
	default: "#999",
	error: "#e57878",
	success: "#6ee27f"
};

export const UploadMensagem = styled.p`
    display: flex;
    color: ${props => coresMensagem[props.type || "default"]};
    justify-content: center;
    align-items: center;
	padding: 15px 0;
	margin: 0;
`;
