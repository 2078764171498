import React, { useState, useEffect } from 'react';
import axios from 'axios';

// components
import { Card, Spinner } from 'react-bootstrap';
import { TransacaoAbertoComponent } from './components';
import Registro from './Registro';
import Paginacao from './../../../Componentes/Paginacao';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ParametrosConsulta from './ParametrosConsulta';

// componente
export default function TransacaoAberto({

}){

    // estados
    const [carregando, alterarCarregando] = useState(true);
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 5,
        statusTransacao: [1,2],
        filtroStatus: 1
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [consulta, alterarConsulta] = useState([]);

    // faz a consulta
    async function consultarTransacoes(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/transacao`, { params: parametros });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }


    // retorna registro
    let retornaRegistros = registro => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                verBoleto={() => {
                    window.open(registro.dados.boletoLink, '_blank');
                }}
            />
        )
    }

    // inicializa
    useEffect(() => {
        consultarTransacoes();
    }, [parametros]);

    return <TransacaoAbertoComponent>
        <Card className="painel-consulta border-0 flex-grow-1" >
            <Card.Header>
                <div className={'d-flex align-items-center'}>
                    <Card.Title className='titulo-card flex-grow-1 m-0'>Transações</Card.Title>
                    <ParametrosConsulta 
                        parametros={parametros}
                        alterarParametros={alterarParametros}
                    />
                </div>
            </Card.Header>
            <Card.Body className='d-flex flex-column'>
                <div 
                    className='d-flex flex-grow-1 flex-column'
                >
                    {
                        carregando ?

                        <div className={['d-flex justify-content-center align-items-center flex-grow-1']}>
                            <Spinner animation="border"  style={{width: '3rem', height: '3rem', color: '#6f6f6f'}} />
                        </div>

                        :
                        <>
                            {
                                totalRegistros > 0 ?
                                (    
                                    isBrowser ? 
                                    <table className="table table-hover tabela">
                                        <tbody>
                                            { retornaRegistros() }
                                        </tbody>
                                    </table>
                                    :
                                    <>
                                        { retornaRegistros() }
                                    </>
                                ) :
                                <>
                                    <span className={'text-center'} style={{color: 'gray'}}>Sem transações para mostrar</span>
                                </>
                            }
                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    </TransacaoAbertoComponent>

}