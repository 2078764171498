import * as React from "react";

const SvgUsuariosIcone = (props) => {
  return (
    <svg
      id="usuarios-icone_svg__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M64.5 103.9c-1.2 0-2.4-.1-3.6-.3l-20.4-3.4c-.7-.1-1.3-.7-1.3-1.5V85.1c0-10.7 8.7-19.4 19.4-19.4h11.5c10.7 0 19.4 8.7 19.4 19.4v13.7c0 .7-.5 1.4-1.3 1.5l-20.4 3.4c-.9.1-2.1.2-3.3.2zm-22.2-6.5l19.1 3.2c2 .3 4.1.3 6.1 0l19.1-3.2V85.1c0-9.1-7.4-16.4-16.4-16.4H58.8c-9.1 0-16.4 7.4-16.4 16.4v12.3zM64.5 63.7c-8.1 0-14.7-6.6-14.7-14.7s6.6-14.7 14.7-14.7S79.2 40.9 79.2 49s-6.6 14.7-14.7 14.7zm0-26.3c-6.4 0-11.7 5.2-11.7 11.7S58 60.8 64.5 60.8 76.2 55.5 76.2 49s-5.3-11.6-11.7-11.6z" />
          <path d="M30.9 93.6c-.1 0-.1 0 0 0-.9-.1-1.6-.1-2.2-.2L8.3 89.9c-.8-.1-1.3-.7-1.3-1.4V74.8c0-10.7 8.7-19.4 19.4-19.4h11.5c5.8 0 11.3 2.6 15 7.1.4.4.4 1.1.2 1.6s-.8.9-1.4.9h-3c-9.1 0-16.4 7.4-16.4 16.4v10.7c0 .4-.2.8-.5 1.1-.2.2-.6.4-.9.4zM10 87.2l19.1 3.2h.2v-9c0-10.5 8.4-19.1 18.8-19.4-2.9-2.3-6.5-3.6-10.2-3.6H26.4c-9.1 0-16.4 7.4-16.4 16.4v12.4zM32.2 53.4c-8.1 0-14.7-6.6-14.7-14.7S24.1 24 32.2 24c7 0 13 5 14.4 11.8.1.5-.1 1-.4 1.3-2.1 2.2-3.3 5.1-3.3 8.1 0 .7.1 1.5.2 2.3.1.5-.1 1-.4 1.3-2.8 3-6.5 4.6-10.5 4.6zm0-26.3c-6.4 0-11.7 5.2-11.7 11.7s5.2 11.7 11.7 11.7c2.9 0 5.7-1.1 7.9-3.1-.1-.7-.1-1.4-.1-2 0-3.5 1.3-6.9 3.6-9.6-1.5-5.1-6.1-8.7-11.4-8.7zM97.1 93.6c-.4 0-.7-.1-1-.4-.3-.3-.5-.7-.5-1.1V81.4c0-9.1-7.4-16.4-16.4-16.4h-3c-.6 0-1.1-.3-1.4-.9-.2-.5-.2-1.1.2-1.6 3.7-4.5 9.2-7.1 15-7.1h11.5c10.7 0 19.4 8.7 19.4 19.4v13.7c0 .7-.5 1.4-1.3 1.5l-20.4 3.4c-.5 0-1.2.1-2.1.2.1 0 .1 0 0 0zM79.8 62c10.4.3 18.8 8.9 18.8 19.4v9h.2l19.1-3.2V74.8c0-9.1-7.4-16.4-16.4-16.4H90.1c-3.8 0-7.4 1.3-10.3 3.6z" />
          <g>
            <path d="M95.8 53.4c-4 0-7.7-1.6-10.5-4.5-.3-.4-.5-.8-.4-1.3.1-.8.2-1.5.2-2.3 0-3-1.2-5.9-3.3-8.1-.3-.4-.5-.8-.4-1.3 1.3-6.8 7.4-11.8 14.4-11.8 8.1 0 14.7 6.6 14.7 14.7s-6.6 14.6-14.7 14.6zm-7.8-6c2.2 2 4.9 3.1 7.9 3.1 6.4 0 11.7-5.2 11.7-11.7s-5.2-11.7-11.7-11.7c-5.3 0-9.9 3.6-11.3 8.7 2.3 2.7 3.6 6 3.6 9.6-.1.6-.1 1.3-.2 2z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default SvgUsuariosIcone;
