import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck, FormLabel, Badge, Modal, ModalBody } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros
}){

    // array com status
    const arrayStatusTransacao = [
        {value: 1, label: 'Em aberto'},
        {value: 2, label: 'Aprovadas'},
        {value: 3, label: 'Canceladas'}
    ]
    
    return <div className="parametros flex-grow-1">
        <div className={['d-flex align-items-center'].join(' ')}>
            <Form.Label 
                className="my-0 mx-2"
                hidden={isMobile}
            >
                Status
            </Form.Label>
            <Select 
                className={[styles.select, 'select'].join(' ')}
                options={arrayStatusTransacao}
                defaultValue={arrayStatusTransacao.filter(status => parametros.filtroStatus === status.value)[0]}
                isMulti={false}
                placeholder='Status'
                classNamePrefix={'select'}
                onChange={(e) => {
                    let filtro = e.value;
                    let valor = null;
                    switch(parseInt(filtro)){
                        case 1:
                            valor = [1,2];
                            break;
                        case 2:
                            valor = [3,4];
                            break;
                        case 3:
                            valor = [5,6,7];
                            break;
                        default:
                            valor = [filtro];
                            break;
                    }

                    // altera os filtros selecionados
                    alterarParametros({...parametros, filtroStatus: filtro, statusTransacao: valor});
                }}
            />
        </div>
    </div>
}