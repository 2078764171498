import React, { useState, useEffect } from 'react';

// componentes
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';

// funções
import { converterReal } from './../../Funcoes';

export default function InputValorReal({
    valor,
    onChange,
    disabled=false,
    tipo='R', // R: Real, %: porcentagem
    className='',
    onkeyDown=null,
    focar=false,
    decimal=2
}){
    
    let [focado, alterarFoco] = useState(false);
    let inputRef = React.createRef();
    let [valorPadrao, alterarValorPadrao] = useState(valor);

    // foca
    useEffect(() => {
        if(focar){
            inputRef.current.theInput.focus();
        }
    }, [focar]);
    
    return <CurrencyInput 
        ref={inputRef}
        value={valorPadrao && valorPadrao.replace('.', ',')}
        decimalSeparator={','}
        thousandSeparator={'.'}
        inputType={'text'}
        placeholder={`R$ 0,${'0'.repeat(decimal)}`}
        prefix={focado ? '': 'R$ '}
        className={['form-control', className].join(' ')}
        onChangeEvent={e => {
            alterarValorPadrao(e.target.value.split('.').join('').replace(',', '.'));
        }}
        onFocus={(e) => {
            alterarFoco(true);
        }}
        onBlur={(e) => {
            onChange(valorPadrao);
            alterarFoco(false);
        }}
        inputMode="decimal"
        allowEmpty={true}
        precision={decimal}
    />

}
