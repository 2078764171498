import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Toast, Row, Col, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {Link, useParams, Redirect, BrowserRouter as Router, useHistory} from 'react-router-dom';
import logo from './../../assets/midiaLocalLogo.png';
import logoIcone from './../../icones/svg/logo2.svg'
// import './RedefinirSenha.scss';
import Carregador from './../Componentes/Carregador';
import { AssociadosVigentesComponent } from './components';
import moment from "moment";
import "./style.scss";

export default function AssociadosPedreira(){


    const [ registros, alterarRegistros ] = useState([]);


    useEffect(() => {
        consultarRegistros();
    }, [])


    return <AssociadosVigentesComponent className="container-fluid login-pagina pedreira">
        <div
            className="row d-md-flex lista" 
        >
            <div md={3} className="logo">
                <img src={logoIcone}/>
            </div>
            <div className="col-md-8 col-lg-6 m-auto py-5 tabela">
                <div className="d-flex" style={{height: "100vh", overflowX: "hidden", overflowY: "scroll"}}>
                    <div>
                        <Table className="table">
                            <tbody>
                                {registros.map(r => 
                                    <tr key={r.nome}>
                                        <td style={{textTransform: "capitalize"}}>{r.nome.toLowerCase()} {r.convidados > 0 && <><br/><b>Convidados: </b>{r.convidados}</>}</td>
                                        <td>{moment(r.data).format("DD/MM/YYYY")} {r.hora}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>

        </div>
    </AssociadosVigentesComponent>


    async function consultarRegistros(){
        const axiosInstance = axios. create({ baseURL: 'https://associado.acem.org.br', timeout: 1000, headers: {'Content-Type': 'application/json'} });
        const { data } = await axiosInstance.get(`/pedreiralista/lista.php`);


        alterarRegistros(data);

    };

};