import React, { useState, useEffect, useContext }  from 'react';
import axios from 'axios';

import { Card, Row, Col, Spinner, Button, Alert, Modal } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faRedoAlt, faCamera, faImages } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import QrReader from 'react-qr-reader';

import { ConsultarQrCodeComponent } from './components.js';

import ComponenteCarteira from './../Carteira/ComponenteCarteira';

// inicializa
export default function Inicio(){

    const [dados, alterarDados] = useState(null);
    const [dadosUsuario, alterarDadosUsuario] = useState(null);
    const [codigo, alterarCodigo] = useState(null);
    const [erro, alterarErro] = useState(false);
    const [mensagemErro, alterarMensagemErro] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [novaConsultaLiberada, alterarNovaConsultaLiberada] = useState(false);
    const [legacyMode, alterarLegacyMode] = useState(false);
    const [alertaInvalido, alterarAlertaInvalido] = useState(false);
    const qrScanner = React.useRef();

    useEffect(() => {

        // alterar liberação da consulta
        alterarNovaConsultaLiberada(false);

        // se possuir erro
        if(erro){

            // verifica o erro
            switch(erro){
                case 'Permission dismissed':
                    alterarMensagemErro('Por favor, permita o uso da câmera para capturar o QrCode.')
                    break;
                case 'Código inválido':
                    alterarMensagemErro('Código inválido, por favor verifique se o código escaneado é fornecido em uma conta da ACEM. Clique aqui para nova consulta.')
                    alterarNovaConsultaLiberada(true);
                    break;
                case 'Problema carteira':
                    alterarMensagemErro('Foram encontrados problemas na carteira escaneada. Confira os dados e data de expiração da carteira.')
                    alterarNovaConsultaLiberada(true);
                    break;
                default:
                    alterarMensagemErro('Por favor, habilite o uso da câmera para capturar o QrCode.')
                    break;
            }
        }
    }, [erro])

    useEffect(() => {
        if(codigo){
            consultarCodigo();
        }
    }, [codigo])

    useEffect(() => {
        if(legacyMode){
            // abre seleção de imagem para o scanner
            qrScanner.current.openImageDialog();
        }
    }, [legacyMode])

    async function consultarCodigo(){

        // carregando
        alterarCarregando(true);

        // primeiro verifica se o código possui estrutura do código para a consulta
        const verifica = verificaCodigo(codigo);

        if(!verifica){
            alterarErro('Código inválido');

            // para aqui
            alterarCarregando(false);
            return;
        }

        // faz a requisição
        try{
            let { data } = await axios.get('/carteira', {
                params: { 
                    consultaQrcode: codigo
                }
            });
            console.log(data);

            // se não encontrado dados ou não possuir carteira ativa
            if(!data.dados || !data.dados.idCarteira || data.dados.associacaoAtiva === 'N'){

                // erro
                alterarDados(null);
                alterarErro('Problema carteira');

            }else{
                alterarDadosUsuario(data.usuario);
                alterarDados(data.dados);
            }
            
        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }

    // verifica o código encontrado
    function verificaCodigo(codigo){

        let valido = false;
        
        // confere a estrutura
        if(codigo.includes('acemQr')){
            valido = true;
        }

        return valido;
    }

    // console.log(erro);
    return <>
        <Modal
            className={['modal-personalizado'].join(' ')}
            // {...propriedades}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={dados ? true : false}
            onHide={() => {
                alterarDados(null)
            }}
        >
            {
                dados &&
                <ComponenteCarteira 
                    dados={dados}
                    dadosUsuario={dadosUsuario}
                    leitura={true}
                />
            }
        </Modal>
        <ConsultarQrCodeComponent status={erro ? false : true}>
            <Card className="border-0 flex-grow-1" >
                <Card.Header className='mb-0 border-0 bg-white'>
                    <div className={'d-flex align-items-center'}>
                        <Card.Title className={['flex-grow-1 m-0'].join(' ')}>Consultar QrCode</Card.Title>
                    </div>
                </Card.Header>
                <Card.Body className={['d-flex flex-column'].join(' ')}>

                    <div 
                        className={['container-qr'].join(' ')} 
                        hidden={!erro && !carregando && !dados}
                        {...(novaConsultaLiberada) && {
                            onClick: () => {
                                // limpa dados
                                alterarErro(null);
                                alterarCodigo(null);
                            },
                            style: {cursor: 'pointer'}
                        }}
                        
                    >   
                        {
                            // informa o erro
                            erro &&
                            <div 
                                className={['info', 'd-flex align-items-center justify-content-center flex-column'].join(' ')}
                            >
                                <FontAwesomeIcon className={'icone'} icon={faTimes} />
                                <p>{mensagemErro}</p>
                            </div>
                        }
                        {
                            // informa o erro
                            carregando &&
                            <div className={['info', 'd-flex align-items-center justify-content-center'].join(' ')}>
                                <Spinner animation="border" style={{width: '5rem', height: '5rem', color: '#fe6501', borderWidth: '0.35em'}} />
                            </div>
                        }
                        {
                            dados && 
                            <div className={['info', 'd-flex align-items-center justify-content-center'].join(' ')}>
                                <FontAwesomeIcon className={'icone'} icon={faCheck} />
                            </div>
                        }
                        {novaConsultaLiberada && 
                            <div 
                                className={['container-refazer-consulta'].join(' ')}
                                style={{display: (isMobile) ? 'block' : 'none'}}
                            >
                                <div 
                                    className={['info refazer-consulta', 'd-flex align-items-center justify-content-center flex-column'].join(' ')}
                                >
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                </div>
                            </div>
                        }
                    </div>
                    {
                        (!carregando && !dados) &&
                        <>
                            <QrReader
                                ref={qrScanner}
                                delay={300}
                                onError={(e) => {
                                    alterarErro(e.message)
                                }}
                                onScan={(scan) => {
                                    // console.log(scan)
                                    if(!scan && qrScanner.current.props.legacyMode){
                                        
                                        alterarAlertaInvalido(true);

                                        setTimeout(() => {
                                            alterarAlertaInvalido(false);
                                        }, 2500)
                                    }

                                    alterarCodigo(scan)
                                }}
                                style={{ width: '100%' }}
                                legacyMode={legacyMode}
                            />
                            <Alert show={alertaInvalido} variant="danger" onClose={() => alterarAlertaInvalido(false)} dismissible>
                                Nenhum código válido identificado.
                            </Alert>
                            <Row className={['footer', 'mt-3'].join(' ')}>
                                <Col>
                                    <Button
                                        onClick={() => {

                                            // remove erro se tiver
                                            alterarErro(null);
                                            alterarCodigo(null);

                                            if(qrScanner.current && legacyMode){

                                                // abre seleção de imagem para o scanner
                                                qrScanner.current.openImageDialog();
                                            }else{
                                                alterarLegacyMode(true)
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon className={'mr-1'} icon={faImages}/>
                                        Selecionar Imagem
                                    </Button>
                                </Col>
                                {
                                    legacyMode && 
                                    <>
                                        <Col className={'text-right'}>
                                            <Button
                                                onClick={() => {

                                                    // remove erro se tiver
                                                    alterarErro(null);
                                                    alterarCodigo(null);
                                                    
                                                    alterarLegacyMode(false)
                                                }}
                                            >
                                                <FontAwesomeIcon className={'mr-1'} icon={faCamera}/>
                                                Utilizar câmera
                                            </Button>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </>
                    }
                </Card.Body>
                <Card.Footer>
                    
                </Card.Footer>
            </Card>
        </ConsultarQrCodeComponent>
    </>

}