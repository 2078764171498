import React, { useState, useEffect, useRef } from "react";

import { Container, FileInfo, Preview, RemoverImagem, RemoverImagemTexto, Info, BarraProgresso, InfoUpload } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar } from 'react-bootstrap';

export default function ListaArquivos({ 
	arquivos, 
	aoDeletar,
	aoClicar,
	style,
	styleImagens,
	limite,
	aoClicarVerMais,
	arquivoSelecionado,
	editavel=true,
	mostrarInfo=true,
	mostrarProgresso=false
}){
		
	// função para rolar o scroll da lista de imagens
	const scrollToRef = (ref) => {
		// rola o scroll
		ref.current.parentElement.scrollTo({
			top: 0,
			left: ref.current.offsetLeft - (ref.current.parentElement.offsetWidth / 2) + (ref.current.offsetWidth / 2),
			behavior: 'smooth'
		  }) 
	}

	const refSelecionado = useRef();
	const executeScroll = () => scrollToRef(refSelecionado);

	useEffect(() => {
		
		if(refSelecionado.current){
			executeScroll()
		}
	}, [arquivoSelecionado])

	return <>
		<Container {...style} >
		{
			arquivos.filter((a, indice) => indice < ((limite - 1) || arquivos.length) || arquivos.length <= limite).map(arquivo => {
				
				return (
					<div 
						key={arquivo.key} 
						{...(arquivoSelecionado && arquivoSelecionado === arquivo.key) && {ref: refSelecionado}}
						className="imagem-container"
					>
						<FileInfo>
							<Preview 
								src={arquivo.preview} 
								selecionada={(arquivoSelecionado && arquivoSelecionado === arquivo.key)}
								cursor={aoClicar ? 'pointer' : 'default'}
								hover={!mostrarInfo}
								{...{
									...styleImagens,
									// outros
									...(aoClicar && {
										onClick: () => {
											aoClicar(arquivo.key)
										}
									})
								}} 
							/>
							{
								mostrarInfo && 
								<Info>
									<strong>{arquivo.salvo ? 'Salva' : arquivo.name}</strong>
									<span>{arquivo.tamanho}{" "}</span>
								</Info>
							}
							{
								!mostrarInfo && editavel && 
								<RemoverImagem>
									<button onClick={() => aoDeletar(arquivo.key)}>
										<FontAwesomeIcon fontSize={18} color="#5d5d5d" icon={["fas", "trash"]} />
									</button>
								</RemoverImagem>
							}
						</FileInfo>
						{
							mostrarInfo && editavel && arquivo.progresso === 0 &&
							<>
								<RemoverImagemTexto>
									<button onClick={(e) => {
										e.nativeEvent.stopImmediatePropagation();
										aoDeletar(arquivo.key)
									}}>
										Excluir
									</button>
								</RemoverImagemTexto>
							</>	
						}
						{
							!arquivo.salvo &&
							<>
								{(mostrarProgresso) ?
									<BarraProgresso>
										<ProgressBar 
											variant={arquivo.erro ? 'danger' : 'success'} 
											animated
											now={arquivo.progresso}
											label={`${arquivo.progresso}%`}
										/>
									</BarraProgresso>
									:
									<InfoUpload erro={arquivo.erro} hidden={!arquivo.erro && !arquivo.sucesso}>
										{arquivo.erro && <FontAwesomeIcon icon={['fas', 'times']}></FontAwesomeIcon>}
										{arquivo.sucesso && <FontAwesomeIcon icon={['fas', 'check']}></FontAwesomeIcon>}
									</InfoUpload>
								}
							</>
						}
					</div>
				)
			})
		}
		{
			
			(limite && arquivos.length > limite) &&
			<div 
				key={'ver-mais'}
				className="imagem-container"
			>
				<FileInfo className="ver-mais">
					<Preview 
						src={arquivos[limite - 1].preview}
						cursor={'pointer'}
						hover={!mostrarInfo}
						{...{
							...styleImagens,
							// outros
							...(aoClicarVerMais && {
								onClick: () => {
									aoClicarVerMais(arquivos[limite -1].key)
								}
							})
						}}
					/>
					<span>	
						+{arquivos.length - limite}
					</span>
				</FileInfo>
			</div>
		}
		</Container>
	</>
};
