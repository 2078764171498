import React, { Component } from "react";

import Dropzone from "react-dropzone";

import { DropContainer, UploadMensagem } from "./styles";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

export default function Upload({
	aoSelecionarArquivos
}){
	
	const retornaMensagem = (isDragActive, isDragReject) => {
		if (!isDragActive) {
			return <UploadMensagem>
				{
					isMobile ? 
					`Clique para selecionar arquivos` :
					`Arraste arquivos aqui...`
				}
			</UploadMensagem>;
		}

		if (isDragReject) {
			return <UploadMensagem type="error">Arquivo não suportado</UploadMensagem>;
		}

		return <UploadMensagem type="success">Solte os arquivos aqui</UploadMensagem>;
	};

	
	return (
		<Dropzone accept="image/*" onDropAccepted={aoSelecionarArquivos}>
			{({ getRootProps, getInputProps, isDragActive, isDragReject , isFocused, isFileDialogActive}) => {
				return (
					<DropContainer
						{...getRootProps()}
						isDragActive={isDragActive}
						isDragReject={isDragReject}
						isFocused={isFocused}
						isFileDialogActive={isFileDialogActive}
					>
						<input {...getInputProps()} />
						{retornaMensagem(isDragActive, isDragReject)}
					</DropContainer>
				)
			}}
		</Dropzone>
	);
}
