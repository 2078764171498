import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, ProgressBar} from 'react-bootstrap';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import { intersectObject, converterReal } from './../../../Funcoes';
import useIsMounted from 'ismounted';
import Formulario from './Formulario';
import { Link } from 'react-router-dom';

// contextos    
import { PainelContexto } from './../../PainelContexto';

import FormularioImagem from './../../../../icones/ilustracoes/formulario-imagem.svg';
import ProjetosImagem from './../../../../icones/ilustracoes/projetos3-imagem.svg';

import PastaArquivosImagem from './../../../../icones/ilustracoes/pasta-arquivos-imagem.svg';
import AdicionarArquivosImagem from './../../../../icones/ilustracoes/adicionar-arquivos-imagem.svg';
import AdicionarArquivosVazioImagem from './../../../../icones/ilustracoes/adicionar-arquivos2-imagem.svg';
import FotoImagem from './../../../../icones/ilustracoes/foto-imagem.svg';
import Foto from './Foto';

// escreve o email
export default function Ficha(){

    // dados
    const { loginReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    let { id } = useParams(); // parametro url
    const dispatch = useDispatch();

    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const [leitura, alterarLeitura] = useState(id ? true : false); // se for aberto por terceiros, é somente leitura
    const [erros, alterarErros] = useState({});
    const dadosPadrao = {
        id: id || loginReducer.dadosUsuario.id,
        nome: null,
        cpf: null, 
        rg: null,
        orgaoExp: null,
        dataNascimento: null,
        tipoSanguineo: null,
        cep: null,
        endereco: null,
        cidade: null,
        estado: null,
        pais: null,
        email: null,
        celular: null,
        telefone: null,
        telefoneComercial: null,
        foto: null,
        pessoaAvisar: null,
        telefonePessoaAvisar: null,
        escolaridade: null,
        profissao: null,
        dataInicioEscalada: null,
        motivoAssociar: null
    };
    const [arquivoFoto, alterarArquivoFoto] = useState({
        imagem: dadosPadrao.foto,
        arquivo: null,
        // onChange: (arquivo) => {
        //     alterarFoto({...foto, arquivo});
        // },
        progresso: null
    });
    
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    
    // inicializa
    useEffect(() => {
        // verifica se é alteração
        if(dados.id){
            alterarModo('edicao');
            consultarIdUsuario();

        }else{

        }

    }, []);

    // consulta os dados do cliente
    async function consultarIdUsuario(){

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/usuario/${dados.id}`);
            
            alterarDados({...data.dados});

        }catch(e){
            console.log('pegou o erro')
        }finally{
            alterarCarregando(false);
        }

    }

    // salva edição
    async function salvar(){
        alterarSalvando(true);

        // faz a requisição
        try{

            // formata o nome
            dados.nome = dados.nome.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim();

            // formata o nome da pessoa avisar
            dados.pessoaAvisar = dados.pessoaAvisar && dados.pessoaAvisar.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim();
            
            // faz a requisição
            let { data } = await axios.post(`/usuario`, {
                dados: intersectObject(dadosPadrao, dados)
            });
            
            // se alterado a imagem, quando salvar, faz upload
            if(arquivoFoto.arquivo !== null){
                salvarFoto(data.id);
            }

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Dados atualizados!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Seus dados cadastrais foram atualizados.
                </div>
            </>);

            // atualiza dados do usuário no login

            dispatch({
                type: 'loginReducer/ATUALIZAR_DADOS',
                dadosUsuario: intersectObject(dadosPadrao, dados)
            });

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');

            alterarErros({});

        }catch({response}){
            alterarErros(response.data)
        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    // salva a foto
    async function salvarFoto(id){

        // instancia um toast
        let toastId = toast(({ closeToast }) => <></>, {
            autoClose: false
        });

        try{

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('imagem', arquivoFoto.arquivo);
            formData.append('id', id);

            // verifica o progresso da imagem do card
            function progressoFoto(progressEvent){
                let progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                if(progresso === 100){
                    progresso = null;
                }
                
                // altera o progresso do arquivo
                alterarArquivoFoto({...arquivoFoto, progresso: progresso});
                toast.update(toastId, {
                    render: <>
                        <div className="toast-header">
                            <strong className="mr-auto">
                                {progresso === null ?
                                    <>Finalizado</>
                                    :
                                    <>Fazendo upload da sua foto!</>
                                }
                            </strong>
                            <button 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body">
                            {(progresso === null) ?
                                <>
                                    <FontAwesomeIcon className='mr-2' icon={faCheck} />
                                    <span>Finalizado!!</span>
                                </>
                                :
                                <ProgressBar className={styles.barra} animated now={progresso} label={`${progresso}%`} />
                            }
                        </div>
                    </>
                });
            }

            // faz a requisição
            let { data } = await axios.post(`/usuario/foto`, formData, {
                onUploadProgress: progressoFoto,
                headers: {'Content-type': 'multipart/form-data'},
            });
            
            // para não fazer upload novamente da foto
            alterarArquivoFoto({...arquivoFoto, arquivo: null});

            // atualiza foto de perfil do usuário
            dispatch({
                type: 'loginReducer/ATUALIZAR_DADOS',
                dadosUsuario: { foto: data.foto }
            });
            
        } catch ({response}) {
            if (response){
                if (response.status === 400){
                    
                    toast (<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Não Conseguimos salvar sua foto :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Não foi possível salvar sua foto, tente novamente!
                            </div>
                        </div>
                    </>);
                }
            }
        } finally {
            
            // quando finalizar, espera um tempo ainda para fechar
            toast.update(toastId, {autoClose: 1000});
        }

        return true;
    }
      
    if(carregando){
        return <div>Carregando...</div>
    }
    
    return <>
        <div className={[styles.divCentro, ''].join(' ')}>
            <Row className='justify-content-md-center'>
                {
                    isBrowser &&
                    <>
                        <Col md={3} >
                            <Row>
                                <Col className={['mb-3'].join(' ')}>
                                    {/* <Card
                                        className={[styles.cardAtalho, styles.sombraCard, 'border-0 flex-grow-1 shadow-sm'].join(' ')}
                                    >
                                        <Link 
                                            className={'d-flex flex-grow-1'}
                                            to={'/painel/usuario'} 
                                        >
                                            <Card.Body className={'d-flex flex-grow-1'}>
                                                <div className={['d-flex align-items-center justify-content-around flex-column flex-grow-1'].join(' ')}>
                                                    <img
                                                        className={['mb-3'].join(' ')} style={{
                                                        width: 'clamp(30px, 20vw, 120px)'
                                                    }} src={FotoImagem}/>
                                                    <div className='font-weight-lighter'>Minha foto</div>
                                                </div>
                                            </Card.Body>
                                        </Link>
                                    </Card> */}
                                    <Foto 
                                        leitura={leitura}
                                        imagem={dados.foto}
                                        alterou={arquivo => {
                                            alterarArquivoFoto({
                                                ...arquivoFoto, 
                                                arquivo: arquivo
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            {!leitura &&
                                <>
                                    <Row>
                                        <Col className={['mb-3'].join(' ')}>
                                            <Card
                                                className={[styles.cardAtalho, styles.sombraCard, 'border-0 shadow-sm'].join(' ')}
                                            >
                                                <Link 
                                                    to={'/painel/cursos'} 
                                                >
                                                    <Card.Body>
                                                        <div className={['d-flex align-items-center justify-content-center flex-column'].join(' ')}>
                                                            <img
                                                                className={['mb-3'].join(' ')} style={{
                                                                width: 'clamp(30px, 20vw, 120px)'
                                                            }} src={AdicionarArquivosVazioImagem}/>
                                                            <div className='font-weight-lighter'>Cursos</div>
                                                        </div>
                                                    </Card.Body>
                                                </Link>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={['mb-3'].join(' ')}>
                                            <Card
                                                className={[styles.cardAtalho, styles.sombraCard, 'border-0 shadow-sm'].join(' ')}
                                            >
                                                <Link 
                                                    to={'/painel/projetos'} 
                                                >
                                                    <Card.Body>
                                                        <div className={['d-flex align-items-center justify-content-center flex-column'].join(' ')}>
                                                            <img
                                                                className={['mb-3'].join(' ')} style={{
                                                                width: 'clamp(30px, 20vw, 120px)'
                                                            }} src={ProjetosImagem}/>
                                                            <div className='font-weight-lighter'>Projetos</div>
                                                        </div>
                                                    </Card.Body>
                                                </Link>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </>
                }

                <Col md={9}>
                    <Formulario
                        dados={dados}
                        alterarDados={dadosNovos => {
                            Object.assign(dados, dadosNovos);
                            alterarDados({...dados});
                        }}
                        funcaoSalvar={salvar}
                        salvando={salvando}
                        modo={modo}
                        erros={erros}
                        alterarErros={alterarErros}
                        leitura={leitura}
                    />
                </Col>
            </Row>
        </div>
    </>
}
