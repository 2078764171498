import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowLeft, faArrowRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Form, Button, Modal, Spinner, Alert, ProgressBar } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import { intersectObject, converterReal } from './../../../Funcoes';
import InputDataCombo from './../../../Componentes/InputDataCombo';
import DropArquivos from './../../../Componentes/DropArquivos';
import ListaArquivos from "./../../../Componentes/ListaArquivos";
import PainelImagens from "./PainelImagens";
import { uniqueId } from "lodash";

// retorna conteudo
export default function ModalCadastroProjeto({
    aoFechar=null,
    aoSalvar=null
}){
    
    let { path, url } = useRouteMatch();
    const { loginReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    const {id} = useParams(); // parametro url

    const [erros, alterarErros] = useState({});
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const dadosPadrao = {
        id: id || null,
        titulo: null,
        descricao: null, 
        dataRealizado: null
    };
    
    const caminhoArquivos = `${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/projetos/`;
    const [dadosIniciais, alterarDadosIniciais] = useState(Object.assign({}, dadosPadrao));
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [mostrar, alterarMostrar] = useState(true);

    // estados para imagens
    const [arquivosIniciais, alterarArquivosIniciais] = useState([]);
    const [arquivosEnviados, alterarArquivosEnviados] = useState([]);
    const [modalDropAberto, alterarModalDropAberto] = useState(false);
	const [imagemAberta, alterarImagemAberta] = useState(null);
    const [enviandoArquivos, alterarEnviandoArquivos] = useState(false);

    // se for aberto na visualização, é somente leitura 
    const [leitura, alterarLeitura] = useState(path === `/painel/projetos/visualizar/:id` ? true : false); 

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(id){
            alterarModo('edicao');
            consultarIdProjeto();

        }else{

        }

    }, [id]);

    // consulta os dados do cliente
    async function consultarIdProjeto(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/projetos/${dados.id}`);
            
            alterarDadosIniciais({...data.dados});
            alterarDados({...data.dados});

            // formata arquivos
            let arquivos = data.arquivos.map(arquivo => {
                return {
                    ...arquivo,
                    preview: `${caminhoArquivos}${arquivo.arquivo}`,
                    key: uniqueId(),
                    progresso: 0,
                    salvo: true
                }
            })

            // adiciona os arquivos
            alterarArquivosEnviados(arquivos);
            alterarArquivosIniciais(arquivos);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // salva edição
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        // faz a requisição
        try{

            // pega apenas ids dos arrays de arquivos
            const idsIniciais = arquivosIniciais.map(arquivo => arquivo.key);
            const idsEnviados = arquivosEnviados.map(arquivo => arquivo.key);

            // pega os ids dos arquivos que não existem mais
            const arquivosRemovidos = arquivosIniciais.filter(arquivo => !idsEnviados.includes(arquivo.key))

            // faz a requisição
            let { data } = await axios.post(`/projetos`, {
                dados: intersectObject(dadosPadrao, dados),
                arquivosDeletar: arquivosRemovidos
            });

            // pega id do projeto salvo
            const idProjeto = id || data.id;

            // pega arquivos que serão salvos
            const arquivosSalvar = arquivosEnviados.filter(arquivo => !idsIniciais.includes(arquivo.key));

            // verifica se há arquivos para salvar
            if(arquivosSalvar.length > 0){
                await salvarArquivos(idProjeto);
            }

            // // verifica se há arquivos para salvar
            // if(arquivosRemovidos.length > 0){
            //     await deletarArquivos(arquivosRemovidos);
            // }

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Projeto salvo!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Dados do projeto foram salvos.
                </div>
            </>);

            // executa callback
            if(aoSalvar){
                aoSalvar();
            }else{
                alterarMostrar(false);
            }

        }catch({response}){

            // erro
            if(response.status === 400){
                alterarErros(response.data);
            }

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
            
        }

    }

    // salva arquivos
    async function salvarArquivos(idProjeto){

        // registra cada upload e não retorna até que todos finalizem
        let uploads = [];


        // altera para mostrar o progresso
        alterarEnviandoArquivos(true);

        // pega ids iniciais
        const idsIniciais = arquivosIniciais.map(arquivo => arquivo.key);

        // inicia o upload de cada arquivo que foi adicionado
        arquivosEnviados.filter(arquivo => arquivo.progresso !== 100 && !idsIniciais.includes(arquivo.key)).forEach(async arquivo => {

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('arquivo', arquivo.arquivo);
            formData.append('idProjeto', idProjeto);

            // verifica o progresso do arquivo
            function progressoArquivo(progressEvent){
                arquivo.progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                alterarArquivosEnviados([...arquivosEnviados]);
            }

            // ajusta dados
            let requisicao = axios.post(`/projetos/arquivos`, formData, {
                onUploadProgress: progressoArquivo,
                headers: {'Content-type': 'multipart/form-data'},
            })
            .then(({data}) => {
                arquivo.dados = data;
                arquivo.sucesso = true;
                alterarArquivosEnviados([...arquivosEnviados]);
            })
            .catch(({response}) => {
                if(response){
                    arquivo.erro = true;
                    alterarArquivosEnviados([...arquivosEnviados]);

                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Não conseguimos salvar seu arquivo :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Não foi possível salvar o arquivo <b>{arquivo.arquivo.name}</b>!
                            </div>
                        </div>
                    </>);
                }
            })

            // incrementa
            uploads.push(requisicao);

        });

        // aguarda finalizar todos
        await Promise.all(uploads);

        // finalizado
        return true;
    }

    // ao clicar para deletar imagem
    const aoDeletarImagem = (id) => {
        let indice = null;
        const arquivos = arquivosEnviados.filter((arquivo, index) => {
            if(id === arquivo.key){
                indice = index
            }
            return id !== arquivo.key
        });

        // remove dos arquivos
        alterarArquivosEnviados(arquivos);

        // se não há mais dados
        if(arquivos.length === 0){
            // fecha a imagem aberta se houver
            alterarImagemAberta(null);
        }

        if(imagemAberta){
            // se imagem removida é antes da selecionada, altera a seleção para a imagem anterior
            let imagemAbertaNova = (imagemAberta >= indice) ? imagemAberta - 1 : imagemAberta;
            alterarImagemAberta(imagemAbertaNova)
        }

    }

    // ao clicar para deletar imagem
    const abrirImagem = (id) => {
        let indice = null;

        // pega o arquivo
        arquivosEnviados.forEach((arquivo, index) => {
            if(id === arquivo.key){
                indice = index;
            }
        });
        
        // altera
        alterarImagemAberta(indice);
    }
    
    // verifica se deve mostrar o modal de drop de arquivos
    if(modalDropAberto){

        return <>
            <Modal
                className={['modal-personalizado'].join(' ')}
                // {...propriedades}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => {
                    alterarModalDropAberto(false)
                }}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title className={'text-center flex-grow-1'}>
                        Anexar imagens
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DropArquivos
                        arquivosEnviados={arquivosEnviados} 
                        aoSelecionarArquivos={novosArquivos => {
                            alterarArquivosEnviados([...arquivosEnviados, ...novosArquivos])
                        }}
                        aoDeletar={aoDeletarImagem}
                        editavel={!leitura}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            // fecha modal
                            alterarModalDropAberto(false)
                        }}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    return <>
        <Modal
            className={['modal-personalizado'].join(' ')}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrar}
            onHide={() => {
                alterarMostrar(false);
            }}
            onExited={() => {
                if(aoFechar){
                    aoFechar();
                }else{
                    history.push(`/painel/projetos`, {refresh: true});
                }
            }}
            backdrop={!leitura ? 'static' : true}
        >
            <Modal.Header 
                closeButton 
                onHide={() => {
                    alterarMostrar(false);
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                    {id ? dadosIniciais.titulo : 'Adicionar novo projeto'}
                </Modal.Title>
                {
                    (leitura && dados.idUsuario === loginReducer.dadosUsuario.id) &&
                    <Button 
                        variant="light" 
                        onClick={() => {

                            alterarLeitura(false);

                            // vai para a edição
                            history.push(`/painel/projetos/editar/${dados.id}`);

                        }}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} style={{marginRight: '5px'}}/>
                        Editar
                    </Button>
                }
            </Modal.Header>
            <Modal.Body>
                {
                    carregando ?
                    <Col align="center">
                        <Spinner animation="border" />
                    </Col>
                    :
                    <Form.Group className={['formulario'].join(' ')}>
                        <Form.Row>
                            <Col sm={12} md={8} className={'mb-2'}>
                                <Form.Control 
                                    className={[styles.input].join(' ')}
                                    readOnly={leitura}
                                    placeholder={"Título"}
                                    value={dados.titulo || ''}
                                    onChange={(e) => {
                                        alterarDados({...dados, titulo: e.target.value});
                                    }}
                                />
                                {(erros.titulo) && 
                                    <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.titulo}</p></Alert>
                                }
                            </Col>
                            <Col className={'mb-2'}>
                                <InputDataCombo
                                    className={[styles.input].join(' ')}
                                    placeholder={'Realizado em'}
                                    disabled={leitura}
                                    data={dados.dataRealizado}
                                    onChange={(data) => {
                                        alterarDados({...dados, dataRealizado: data});
                                    }}
                                    disableMobile={true}
                                />
                                {(erros.dataRealizado) && 
                                    <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.dataRealizado}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col className={'mb-2'}>
                                <Form.Control 
                                    className={[styles.input, 'mb-2'].join(' ')}
                                    readOnly={leitura}
                                    placeholder={"Descrição do projeto"}
                                    as="textarea"
                                    value={dados.descricao || ''}
                                    rows={4}
                                    onChange={(e) => {
                                        alterarDados({...dados, descricao:  e.target.value});
                                    }}
                                />
                                {(erros.descricao) && 
                                    <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.descricao}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row hidden={leitura}>
                            <Col>
                                <DropArquivos
                                    arquivosEnviados={arquivosEnviados} 
                                    aoSelecionarArquivos={novosArquivos => {
                                        alterarArquivosEnviados([...arquivosEnviados, ...novosArquivos])
                                    }}
                                    aoDeletar={aoDeletarImagem}
                                    editavel={!leitura}
                                    preview={false}
                                />
                            </Col>
                        </Form.Row>
                        {!!arquivosEnviados.length && (
                            <>
                                <Form.Row>
                                    <Col>
                                        <ListaArquivos 
                                            arquivos={arquivosEnviados} 
                                            aoDeletar={aoDeletarImagem} 
                                            aoClicar={abrirImagem}
                                            mostrarInfo={false}
                                            mostrarProgresso={enviandoArquivos}
                                            limite={isMobile ? 6 : 8}
                                            editavel={!leitura}
                                            aoClicarVerMais={(id) => {
                                                abrirImagem(id)
                                            }}
                                            styleImagens={{
                                                height: '80px',
                                                width: '80px',
                                            }}
                                            style={{
                                                flex: true
                                            }}
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Row hidden={leitura}>
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            variant={'light'}
                                            style={{
                                                padding: '2px 5px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#4a4a4a'
                                            }}
                                            onClick={() => {
                                                alterarModalDropAberto(true)
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" style={{marginRight: '5px'}} icon={["fas", 'images']} />
                                            Ver todas
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </>
                        )}
                    </Form.Group>
                }
            </Modal.Body>
            {
                !leitura && 
                <Modal.Footer>
                    <SalvarFormulario 
                        salvando={salvando}
                        modo={modo}
                        aoFechar={() => {
                            alterarMostrar(false);
                        }}
                        onClick={() => salvar(dados)}
                    />
                </Modal.Footer>
            }
            {
                (leitura && isMobile) && 
                <Modal.Footer>
                    <Button 
                        variant="danger" 
                        onClick={() => {
                            alterarMostrar(false);
                        }}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            }
        </Modal>
        
        <PainelImagens 
            imagens={arquivosEnviados}
            imagemAberta={imagemAberta}
            alterarImagemAberta={alterarImagemAberta}
            aoDeletarImagem={aoDeletarImagem}
            abrirImagem={abrirImagem}
            editavel={!leitura}
            mostrarProgresso={enviandoArquivos}
        />
    </>
}

function SalvarFormulario({
    salvando,
    aoFechar,
    modo,
    onClick
}){
    return <Form.Group 
        className={[styles.linhaSalvar, 'd-flex justify-content-around my-2'].join(' ')}
    >
        <Button 
            variant="danger" 
            onClick={aoFechar}
        >Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}