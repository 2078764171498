import * as React from "react";

const SvgBombeiro2Icone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <style>{".bombeiro-2-icone_svg__st6{fill:#ccc}"}</style>
      <g id="bombeiro-2-icone_svg__emergencia">
        <path
          d="M89.2 5C72.1 6.5 59.4 21.6 60.9 38.7c.4 4.7 1.9 9.3 4.4 13.4l-6.2 12.7c-.5 1.1-.5 2.4.3 3.5.7 1 1.9 1.6 3.2 1.5l32-2.8c17.1-1.5 29.8-16.6 28.3-33.7S106.2 3.5 89.2 5z"
          fill="#e60000"
        />
        <path
          d="M115.6 15.9c2.1 3.7 3.5 7.9 3.9 12.5 1.5 17.1-11.2 32.2-28.3 33.7L59 64.9c-.5 1.1-.4 2.4.3 3.5.7 1 1.9 1.6 3.2 1.5L94.6 67c17.1-1.5 29.8-16.6 28.3-33.7-.6-6.7-3.3-12.7-7.3-17.4z"
          fill="#b30000"
        />
        <path
          d="M102.9 32.1H96v-6.9c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v6.9h-6.9c-2.3 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1h6.9v6.9c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1v-6.9h6.9c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1z"
          fill="#fff"
        />
        <path
          className="bombeiro-2-icone_svg__st6"
          d="M105.2 32.8c.5.7.8 1.5.8 2.4 0 2.3-1.8 4.1-4.1 4.1H95v6.9c0 2.3-1.8 4.1-4.1 4.1-.9 0-1.7-.3-2.4-.8.7 1.1 2 1.8 3.4 1.8 2.3 0 4.1-1.8 4.1-4.1v-6.9h6.9c2.3 0 4.1-1.8 4.1-4.1 0-1.4-.7-2.6-1.8-3.4z"
        />
        <path
          d="M76 100.1L62.6 87.9l-.8.5c-2.8 1.6-6.3.6-7.9-2.1l-9.8-17-9.8-17c-1.6-2.8-.6-6.3 2.1-7.9l.8-.5-5.4-16.8-11.9-13-4 2.3c-10.5 6-14.1 19.4-8 29.9l19 32.9 19 32.9c1.1 2 2.5 3.7 4.1 5.1s3.4 2.7 5.3 3.6c1.3.6 2.6 1.1 3.9 1.5 4.1 1.1 8.4 1 12.6-.4 1.4-.5 2.7-1.1 4-1.8l4-2.3-3.8-17.7z"
          fill="#f30"
        />
        <path
          d="M65.3 120.4c-1.4-.4-2.7-.9-3.9-1.5-1.9-.9-3.7-2.1-5.3-3.6s-3-3.2-4.1-5.1l-19-33-19-32.9c-5.8-10.1-2.7-22.9 6.9-29.2l-.9-1-4 2.3c-10.5 6-14.1 19.4-8 29.9l19 32.9 19 32.9c1.1 2 2.5 3.7 4.1 5.1s3.4 2.7 5.3 3.6c1.3.6 2.6 1.1 3.9 1.5 4.1 1.1 8.4 1 12.6-.4 1-.3 1.9-.7 2.8-1.2-3.2.6-6.4.5-9.4-.3z"
          fill="#cc2900"
        />
        <path
          className="bombeiro-2-icone_svg__st6"
          d="M37.2 44l6-3.5c5-2.9 6.7-9.2 3.8-14.2l-6.9-12c-2.9-5-9.2-6.7-14.2-3.8l-6 3.5 17.3 30zM85.9 114.4c5-2.9 6.7-9.2 3.8-14.2l-6.9-12c-2.9-5-9.2-6.7-14.2-3.8l-6 3.5 17.3 29.9 6-3.4z"
        />
      </g>
    </svg>
  );
};

export default SvgBombeiro2Icone;
