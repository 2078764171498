import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Row, Col, Card } from 'react-bootstrap';
import ConfiguracaoLivre from './ConfiguracaoLivre';
import ConfiguracaoFuncoes from './ConfiguracaoFuncoes';
import ConfiguracaoSistema from './ConfiguracaoSistema';

// contexto
import { ConfiguracaoContexto } from './ConfiguracaoContexto';

// componente
export default function Configuracao(){

    // estados
    const { configuracaoReducer, loginReducer } = useSelector(state => state);
    const { id } = useParams();
    const idUsuario = id || loginReducer.dadosUsuario.id;
    const [configuracoes, alterarConfiguracoes] = useState(null);
    const [carregando, alterarCarregando] = useState(true);
    const [dadosUsuario, alterarDadosUsuario] = useState(null);
    
    // carregar os dados de permissão
    useEffect(() => {
        consultar();
    }, []);

    // carrega as configurações
    async function consultar(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoUsuarios/${idUsuario}`);

            // finalizado
            alterarConfiguracoes(data);

            

        }catch({}){}

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuario/${idUsuario}`);
            alterarDadosUsuario(data);
        }catch({response}){}

        // finaliza
        alterarCarregando(false);

    }

    // usuário tentando acessar rota sem permissão
    if(id && configuracaoReducer.permitirAlterarConfiguracaoUsuario === 'N'){
        return <>Permissão negada</>
    }

    // se estiver carregando os dados
    if(carregando){
        return <div>Aguarde...</div>
    }

    return <ConfiguracaoContexto.Provider
        value={{configuracoes, idUsuario}}
    >

        <div className='centro-container'>
            <Row>
                <Col lg={3}>
                    <Card
                        className='border-0 shadow-sm'
                    >
                        <Card.Body>
                            <div
                                className={styles.perfil}
                            >
                                <div
                                    className={[styles.foto, 'bg-light rounded-circle mb-3'].join(' ')}                                
                                >
                                    <img src= {dadosUsuario.dados.foto} />        
                                </div>
                                <p className={[styles.nome, 'text-center text-secondary'].join(' ')}>
                                    {dadosUsuario.dados.nome}
                                </p>
                                
                            </div>
                        </Card.Body>
                    </Card>
                    
                </Col>
                <Col
                    lg={9}        
                >
                    {/* utilizado alterações de tema, cores entre outras opções */}
                    <ConfiguracaoLivre  />

                    {/* caso o usuário tiver permissão para alterar permissões de nível máximo */}
                    { configuracaoReducer.permitirAlterarConfiguracaoUsuario === 'S' &&
                        <ConfiguracaoFuncoes  />
                    }

                    {/* configurações de sistema */}
                    { (configuracaoReducer.habilitarConfiguracaoSistema === 'S' && idUsuario === loginReducer.dadosUsuario.id) &&
                        <ConfiguracaoSistema />
                    }
                </Col>
            </Row>
        </div>


    </ConfiguracaoContexto.Provider>

}