import React from 'react';
import styles from './index.module.scss';
import { Route } from "react-router-dom";

// componentes
import Consultar from './Consultar';
import ModalCadastroProjeto from './ModalCadastroProjeto';

// escreve o email
export default function Projetos(){
      
    return <>

        <div className={[styles.divCentro, ''].join(' ')}>
            <Consultar />

            {/* rotas */}
            <Route exact path={[`/painel/projetos/cadastrar`, `/painel/projetos/editar/:id`, `/painel/projetos/visualizar/:id`]}>
                <ModalCadastroProjeto />
            </Route>
        </div>
    </>
}
