import styled, { css } from "styled-components";
import backGroundImage from './background.svg';

export const LoginComponent = styled.div.attrs({
	className: ''
})`

	background: url(${backGroundImage});
	background-size: cover;
				
	.btn{

		&.botao-entrar{
			background: #f38225;
			border-color: #e2712a;

			&:hover{
				background: #ef7715;
				border-color: #e26a1f;
			}

			&:active, &:focus{
				background: #ec791b !important;
				border-color: #e0702a !important;
			}
		}

		&.botao-cadastrar{
			background: #e86900;
			border-color: #d4580b;

			&:hover{
				background: #d45f00;
				border-color: #dc5704;
			}

			&:active, &:focus{
				background: #ea7f28 !important;
				border-color: #dc6012 !important;
			}
		}

		&.botao-associado{
			background: #ff8e0a;
			border-color: #f98600;
			
			&:hover{
    			background: #f58503;
				box-shadow: 1px 1px 3px #b9b9b9;
			}

			&:active, &:focus{
				background: #f58707 !important;
    			border-color: #f3870a !important;
			}
		}

	}
	
`;
